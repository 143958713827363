import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import axios from 'axios';
import App from './App';
import reportWebVitals from './reportWebVitals';

const defaultURL = process.env.REACT_APP_NODE_ENV === 'PRODUCTION'
  ? process.env.REACT_APP_PRODUCTION_URL
  : process.env.REACT_APP_NODE_ENV === 'STAGING'
    ? process.env.REACT_APP_SANDBOX_URL
    : process.env.REACT_APP_LOCAL_URL;

axios.defaults.baseURL = defaultURL;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
