import React from 'react';

import { Line } from '@ant-design/plots';

export default function SurveyPie({ data }) {
  const config = {
    data,
    xField: 'month',
    yField: 'amount',
    yAxis: {
      label: {
        formatter: (v) => `RM ${v}`,
      },
    },
    seriesField: 'type',
    animation: false,
  };

  return <Line {...config} />;
}
