import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  PageHeader,
  Breadcrumb,
  Form,
  InputNumber,
  Row,
  Button,
  Input,
  Col,
  Tooltip,
  Select,
  message,
  DatePicker,
  Layout,
  Card,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';

import moment from 'moment';
import axios from 'axios';

import { getToken } from '../../utils/AuthService';

const { Option } = Select;
const { Content } = Layout;

export default function NewQuotationForm() {
  const token = getToken();
  const history = useHistory();

  const [form] = Form.useForm();

  const [loadingPSL, setLoadingPSL] = useState(false);
  const [PSLData, setPSLData] = useState([]);
  const [monthlyRate, setMonthlyRate] = useState('');

  const validateMessages = {
    required: '${label} is required!',
    types: {
      number: '${label} is not a valid number!',
      string: '${label} is not a valid string!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  useEffect(() => {
    const fetchPSLData = async () => {
      setLoadingPSL(true);
      await axios
        .get('v1/psls', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            sortBy: 'desc',
          },
        })
        .then((results) => {
          setPSLData(results.data.results);
          return results;
        })
        .catch((error) => {
          message.error(`Failed to retrive PSL data. ${error}`);
        });
    };
    fetchPSLData();
    setLoadingPSL(false);
  }, []);

  const onChangeMonthlyRate = (e) => {
    if (e === 'yes') {
      setMonthlyRate(true);
    } else if (e === 'no') {
      setMonthlyRate(false);
    }
  };

  const onFinish = (values) => {
    const inputData = values.quotation;

    const { mandayRateItem1Name, mandayRateItem1Amount } = inputData;

    const reqMandayratessArr = [];
    if (!monthlyRate) {
      if (mandayRateItem1Name) {
        reqMandayratessArr.push({ name: mandayRateItem1Name, amount: mandayRateItem1Amount });
      }
      if (inputData.manday_rates_list) {
        const mandayRatesItems = inputData.manday_rates_list;
        mandayRatesItems.map((val) => {
          reqMandayratessArr.push({ name: val.name, amount: val.amount });
        });
      }
    }

    const reqRemarksArr = [];
    if (inputData.remarks_item_1) {
      const array = inputData.remarks_item_1;
      reqRemarksArr.push(array);
    }
    if (inputData.remarks_list) {
      console.log('inputData.remarks_list: ', inputData.remarks_list[0].remarks);
      inputData.remarks_list.map((e) => {
        reqRemarksArr.push(e.remarks);
      });
    }
    console.log('reqRemarksArr: ', reqRemarksArr);

    let selectedPSLID = '';
    PSLData.map((e) => {
      if (e.psl === inputData.selectedPSL) {
        selectedPSLID = e.id;
      }
    });

    // form.resetFields();

    axios
      .post(
        'v1/quotation',
        {
          recipient: inputData.recipient,
          psl_id: selectedPSLID,
          date: moment(inputData.date).toISOString(),
          payment_terms: inputData.paymentTerms,
          consultant_name: inputData.consultantName,
          position: inputData.position,
          agency_fee: inputData.agencyFee,
          insurance_fee: inputData.insurance,
          isMonthly: monthlyRate,
          monthlyFee: inputData.monthlyFee,
          manday_rate_sets: reqMandayratessArr.length === 0 ? [] : reqMandayratessArr,
          epf: inputData.epf,
          socso: inputData.socso,
          eis: inputData.eis,
          quotation_file_url: '',
          remarks: reqRemarksArr.length === 0 ? [] : reqRemarksArr,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        },
      )
      .then(() => {
        message.success('New Quotation created successfully!');
        history.push('/quotation-history');
        return true;
      })
      .catch((error) => {
        message.error(`Please ensure all required fields are filled in. ${error}`);
      });
  };
  const onFinishFailed = (errorInfo) => {
    message.error('Failed to create new quotation.', errorInfo);
  };

  const displayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/quotation-history">Quotation History</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add New Quotation Form</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title="Add New Quotation Form" className="basic-pageheader" />
    </div>
  );

  const CreateNewQuotationForm = () => (
    <Form
      form={form}
      layout="vertical"
      name="edit-work-info"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 10,
        }}>
        <Col className="gutter-row" span={8}>
          <Form.Item
            label="Recipient"
            name={['quotation', 'recipient']}
            rules={[
              {
                required: true,
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={9}>
          <Form.Item
            label={(
              <Row>
                <Col>PSL</Col>
                <Col style={{ paddingLeft: 10 }}>
                  <Tooltip
                    placement="topLeft"
                    title="Add new psl in PSL Management page if not found"
                    arrowPointAtCenter>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Col>
              </Row>
            )}
            name={['quotation', 'selectedPSL']}
            rules={[
              {
                required: true,
              },
            ]}>
            <Select
              showSearch
              placeholder="Select PSL"
              style={{
                width: '100%',
              }}>
              {loadingPSL === false
                ? PSLData.map((val) => (
                  <Option key={val.id} value={val.psl}>
                    {val.psl}
                  </Option>
                ))
                : null}
            </Select>
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={7}>
          <Form.Item
            label="Date"
            name={['quotation', 'date']}
            rules={[
              {
                required: true,
              },
            ]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 10,
        }}>
        <Col span={3}>
          <Form.Item
            label="Payment Terms"
            name={['quotation', 'paymentTerms']}
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[0-9]+$/),
                min: 0,
                message: 'Please input a valid number!',
              },
            ]}>
            <Row>
              <InputNumber
                min={0}
                style={{
                  width: '60%',
                }}
              />
              <div style={{ paddingLeft: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>Days</div>
            </Row>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="Consultant Name"
            name={['quotation', 'consultantName']}
            rules={[
              {
                required: true,
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item
            label="Position"
            name={['quotation', 'position']}
            rules={[
              {
                required: true,
                type: 'string',
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item
            label="Agency Fee"
            name={['quotation', 'agencyFee']}
            rules={[
              {
                required: true,
                type: 'number',
              },
            ]}>
            <InputNumber
              prefix="%"
              min={0}
              maxLength={3}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Col>

        <Col span={3}>
          <Form.Item
            label="Insurance"
            name={['quotation', 'insurance']}
            rules={[
              {
                required: true,
                type: 'number',
              },
            ]}>
            <InputNumber
              prefix="RM"
              min={0}
              style={{
                width: '100%',
              }}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            label="Monthly Rate"
            name={['quotation', 'monthlyRateCheck']}
            rules={[
              {
                required: true,
              },
            ]}>
            <Select placeholder="Please select" onChange={onChangeMonthlyRate}>
              <Option key={1} value="yes">
                Yes
              </Option>
              <Option key={2} value="no">
                No
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {monthlyRate !== '' ? (
        monthlyRate === false ? (
          <Card title="Manday Rate Sets" size="small">
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 10,
              }}>
              <Col span={16}>
                <Form.Item
                  name={['quotation', 'mandayRateItem1Name']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input manday rate name',
                    },
                  ]}>
                  <Input placeholder="Manday rate item 1" maxLength={14} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={['quotation', 'mandayRateItem1Amount']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input manday rate name',
                    },
                  ]}>
                  <InputNumber prefix="RM" min={0} maxLength={6} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <Form.List name={['quotation', 'manday_rates_list']}>
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Row
                      key={key}
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 10,
                      }}>
                      <Col span={16}>
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input manday rate name',
                            },
                          ]}>
                          <Input placeholder={`Manday rate item ${index + 2}`} maxLength={14} />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          {...restField}
                          name={[name, 'amount']}
                          rules={[
                            {
                              required: true,
                              message: 'Please input amount',
                            },
                          ]}>
                          <InputNumber
                            prefix="RM"
                            min={0}
                            maxLength={6}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
                  {fields.length < 4 ? (
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      style={{
                        width: '100%',
                      }}
                      icon={<PlusOutlined />}>
                      Add New Manday Rate Item
                    </Button>
                  ) : (
                    <p>Maximum manday rate items: 5</p>
                  )}
                  <Form.ErrorList errors={errors} />
                </>
              )}
            </Form.List>
          </Card>
        ) : (
          <>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 10,
              }}>
              <Col span={7}>
                <Form.Item
                  label="Monthly Fee"
                  name={['quotation', 'monthlyFee']}
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <InputNumber prefix="RM" min={0} maxLength={6} style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col span={5}>
                <Form.Item
                  label="EPF"
                  name={['quotation', 'epf']}
                  rules={[
                    {
                      required: true,
                      type: 'number',
                    },
                  ]}>
                  <InputNumber
                    prefix="RM"
                    min={0}
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  label="Socso"
                  name={['quotation', 'socso']}
                  rules={[
                    {
                      required: true,
                      type: 'number',
                    },
                  ]}>
                  <InputNumber
                    prefix="RM"
                    min={0}
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  label="EIS"
                  name={['quotation', 'eis']}
                  rules={[
                    {
                      required: true,
                      type: 'number',
                    },
                  ]}>
                  <InputNumber
                    prefix="RM"
                    min={0}
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )
      ) : null}

      <Card title="Remarks" size="small" style={{ marginTop: monthlyRate === false ? 15 : 0 }}>
        <Form.Item name={['quotation', 'remarks_item_1']}>
          <Row>
            <Input placeholder="Remarks item 1" style={{ width: '100%' }} />
          </Row>
        </Form.Item>

        <Form.List name={['quotation', 'remarks_list']}>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Form.Item
                  key={key}
                  {...restField}
                  name={[name, 'remarks']}>
                  <Row
                    key={key}
                    gutter={{
                      xs: 8,
                      sm: 16,
                      md: 24,
                      lg: 10,
                    }}>
                    <Input
                      placeholder={`Remarks item ${index + 2}`}
                      style={{
                        width: fields.length > 0 ? '95%' : '100%',
                        marginLeft: 5,
                      }}
                    />
                    <Col>
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(name);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Item>                  
              ))}
              {fields.length < 9 ? (
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{
                    width: '100%',
                  }}
                  icon={<PlusOutlined />}>
                  Add New Remarks Item
                </Button>
              ) : (
                <p>Maximum remarks items: 10</p>
              )}
              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List>
      </Card>
      <Row style={{ justifyContent: 'end', marginTop: '50px' }}>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );

  return (
    <>
      {displayBreadCrumb()}
      <Content className="content-area">
        <CreateNewQuotationForm />
      </Content>
    </>
  );
}
