import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Table, Tag, Button, message, Popconfirm, Modal, Checkbox, Form, Spin, Row } from 'antd';
import { ExclamationCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import moment from 'moment';
import axios from 'axios';

import { getUser, getToken } from '../../utils/AuthService';

export default function TableInvoiceList(invoiceData, handleUpdateInvoices) {
  const history = useHistory();
  const [ConsultantForm] = Form.useForm();
  const [EditForm] = Form.useForm();
  const [MergeForm] = Form.useForm();

  const token = getToken();
  const user = getUser();
  const userRole = user.role;

  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [purgingInvoice, setPurgingInvoice] = useState();
  const [openSelectConsultant, setOpenSelectConsultant] = useState(false);
  const [consultants, setConsultants] = useState([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState();
  const [editInvoiceData, setEditInvoiceData] = useState([]);
  const [mergingInvoice, setMergingInvoice] = useState();
  const [openSelectInvoices, setOpenSelectInvoices] = useState(false);
  const [openEditInvoice, setOpenEditInvoice] = useState(false);
  const [childInvoices, setChildInvoices] = useState([]);
  const [editLoading, setEditLoading] = useState(false);
  const [purgeLoading, setPurgeLoading] = useState(false);
  const [mergeLoading, setMergeLoading] = useState(false);
  const [viewModalTitle, setViewModalTitle] = useState('');
  const [fileURL, setFileURL] = useState('');

  const onUpdateRFI = async (invoiceId) => {
    const jsonBody = {
      status: 'paid',
    };

    await axios
      .put(`v1/invoices/${invoiceId}/update`, jsonBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
      .then(() => {
        message.success('Successfully updated invoice!');
        history.push('/invoice-history');
      })
      .catch((error) => {
        message.error(`Failed to update status. ${error}`);
      });
  };

  const previewInvoice = (invoiceNum, companyName, invoiceFileUrl) => {
    setShowPDFViewer(true);
    setViewModalTitle(`${companyName} (Invoice No: ${invoiceNum})`);
    setFileURL(invoiceFileUrl);
  };

  const onConfirmDeleteWholeInvoice = async (invoiceId, paymentIds) => {
    console.log('invoiceId: ', invoiceId);
    console.log('paymentIds: ', paymentIds);
    const config = {
      url: `v1/invoices/${invoiceId}/removePaymentIds?type=remove`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
      data: { paymentIds },
    };
    message.info('Request being processing...');
    await axios(config).then((res) => {
      message.success(res.data.message, 5.0);
    }).catch((err) => {
      console.error(err);
      message.error(`error: ${err}`);
    });
  };

  const columns = [
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      width: '20%',
      sorter: (a, b) => a.pslName.localeCompare(b.companyName),
      render: (val) => {
        if (val) {
          return val.toUpperCase();
        }
        return '-';
      },
    },
    {
      title: 'PSL',
      dataIndex: 'pslName',
      key: 'pslName',
      width: '10%',
      sorter: (a, b) => a.pslName.localeCompare(b.pslName),
      render: (val) => {
        if (val) {
          return val.toUpperCase();
        }
        return '-';
      },
    },
    {
      title: 'Pending',
      dataIndex: 'countConsultant',
      key: 'countConsultant',
      width: '10%',
      sorter: (a, b) => a.countConsultant - b.countConsultant,
      render: (countConsultant) => {
        if (countConsultant !== 0) {
          return `${countConsultant} consultants`;
        }
        return `${countConsultant} consultant`;
      },
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      align: 'right',
      sorter: (a, b) => a.invoiceNumber.localeCompare(b.invoiceNumber),
    },
    {
      title: 'Invoice Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      render: (val) => {
        if (val !== null) {
          return moment(val).format('DD/MM/YYYY');
        }
        return '-';
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      filters: [
        {
          text: 'Waiting for submission',
          value: 'waiting for submission',
        },
        {
          text: 'Pending approval',
          value: 'pending approval',
        },
        {
          text: 'Pending payment',
          value: 'pending payment',
        },
        {
          text: 'Rejected',
          value: 'rejected',
        },
        {
          text: 'Paid',
          value: 'paid',
        },
      ],
      filterMode: 'tree',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status, data) => {
        switch (status) {
          case 'waiting for submission':
            return (
              <Popconfirm
                placement="top"
                title={<div>This invoice is waiting to be submitted.</div>}
                onCancel="Close"
                okText="Ok"
                icon={(
                  <ExclamationCircleFilled
                    style={{
                      color: '#1890FF',
                    }}
                  />
                )}>
                <Tag style={{ cursor: 'pointer' }} color="green">Waiting for submission</Tag>
              </Popconfirm>
            );
          case 'pending approval':
            return (
              <Popconfirm
                placement="top"
                title={(
                  <div>
                    This invoice is waiting to be approved by the admin.
                    <br />
                    Submitted at {moment(data.updatedAt).format('DD/MM/YYYY')}.
                  </div>
                )}
                icon={<ExclamationCircleFilled />}
                onCancel="Close">
                <Tag style={{ cursor: 'pointer' }} color="orange">Pending Approval</Tag>
              </Popconfirm>
            );
          case 'pending payment':
            return (
              <Popconfirm
                placement="top"
                title={(<div>This invoice is pending payment from {data.psls.company}.</div>)}
                icon={<ExclamationCircleFilled />}
                onCancel="Close">
                <Tag style={{ cursor: 'pointer' }} color="blue">Pending Payment</Tag>
              </Popconfirm>
            );
          case 'rejected':
            return (
              <Popconfirm
                placement="top"
                title={(
                  <div>
                    This invoice is rejected on {moment(data.updatedAt).format('DD/MM/YYYY')} due to <b>{data.reject_remarks}</b>.
                  </div>
                )}
                icon={(
                  <CloseCircleFilled
                    style={{
                      color: '#FF4D4F',
                    }}
                  />
                )}
                okCancel="Close">
                <Tag color="red">Rejected</Tag>
              </Popconfirm>
            );
          case 'paid':
            return (
              <Popconfirm
                placement="top"
                title={(<div>Received payment for this invoice.</div>)}
                icon={<ExclamationCircleFilled />}
                onCancel="Close">
                <Tag style={{ cursor: 'pointer' }} color="blue">Paid</Tag>
              </Popconfirm>
            );
          default:
            return '';
        }
      },
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      render: (data) => (data ? moment(data).format('DD/MM/YYYY') : '-'),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: '12%',
      render: (val, data) => {
        let res;

        if (userRole === 'authorizer') {
          if (val.invoiceUrl) {
            switch (val.status) {
              case 'waiting for submission':
                res = null;
                break;
              case 'pending approval':
                res = (
                  <>
                    <Button type="link">
                      <Link
                        to={{
                          pathname: `/invoice-form/approve/${val._id}`,
                          state: {
                            id: val._id,
                            invoiceUrl: val.invoiceUrl,
                          },
                        }}>
                        Review Invoice
                      </Link>
                    </Button>
                    <Button
                      type="link"
                      style={{ color: '#F7931D' }}
                      onClick={async () => {
                        const {
                          paymentIds,
                          _id,
                        } = data;
                        console.log('invoice data', data);
                        setSelectedInvoiceId(_id);
                        const dataLists = [];
                        console.log('paymentIds', paymentIds);
                        // eslint-disable-next-line no-restricted-syntax
                        for (const paymentId of paymentIds) {
                          // eslint-disable-next-line no-await-in-loop
                          await axios.get(`v1/payments/${paymentId}`, {
                            headers: {
                              'Content-Type': 'application/json',
                              Authorization: `Bearer ${token.access.token}`,
                            },
                          }).then((resData) => {
                            const { data: payment } = resData;
                            dataLists.push({
                              name: payment.consultant.name || '# INVALID CONSULTANT',
                              runPaymentDate: payment.runPaymentDate,
                              value: payment._id,
                            });
                            // resData.data
                          });
                        }
                        setEditInvoiceData(dataLists);
                        setOpenEditInvoice(true);
                      }}
                    >
                      Edit Invoice
                    </Button>
                    <Popconfirm
                      title="Are you sure to archive this invoice?"
                      onConfirm={() => onConfirmDeleteWholeInvoice(data._id, data.paymentIds)}
                      okText="Archive"
                      cancelText="Cancel"
                    >
                      <Button 
                        type="link"
                        style={{ color: '#F7931D' }}>
                        Archive Invoice
                      </Button>
                    </Popconfirm>
                  </>
                );
                break;
              case 'rejected':
                res = null;
                break;
              case 'pending payment':
                res = (
                  <>
                    <Button
                      type="link"
                      style={{ color: '#F7931D' }}
                      onClick={() => onUpdateRFI(data._id)}>
                      Receive Payment
                    </Button>
                    <Button
                      type="link"
                      style={{ color: '#F7931D' }}
                      onClick={async () => {
                        const {
                          __v,
                          createdAt,
                          updatedAt,
                          countConsultant,
                          invoiceUrl,
                          invoiceNumberSub,
                          invoiceNumberSubInt,
                          pslName,
                          psls,
                          reject_remarks: remarks,
                          archive,
                          companyName,
                          taxItems = [],
                          childInvoices: childs,
                          ...invoiceBody
                        } = data;
                        console.log(taxItems);
                        invoiceBody.taxItems = taxItems.map((value) => {
                          const tax = value;
                          delete tax._id;
                          return tax;
                        });
                        console.log(invoiceBody);
                        setPurgingInvoice(invoiceBody);

                        await axios.get(`v1/invoices/${data._id}`, {
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token.access.token}`,
                          },
                        }).then((resData) => {
                          console.log(resData.data.paymentIds);
                          const dataLists = [];
                          resData.data.paymentIds.forEach((value) => {
                            dataLists.push({
                              label: value.consultantId.name,
                              value: value.id,
                            });
                          });
                          console.log(dataLists);
                          setConsultants(dataLists);
                          setOpenSelectConsultant(true);
                        });
                      }}
                    >
                      Purge Invoice
                    </Button>
                    {val.childInvoices.length > 0 && (
                      <Button
                        type="link"
                        onClick={() => {
                          setMergingInvoice(val._id);
                          setChildInvoices(val.childInvoices);
                          setOpenSelectInvoices(true);
                        }}
                      >
                        Merge Invoice
                      </Button>
                    )}
                    <Button
                      type="link"
                      style={{ color: '#F7931D' }}
                      onClick={
                        () => previewInvoice(data.invoiceNumber, data.companyName, data.invoiceUrl)
                      }>
                      Preview Invoice
                    </Button>
                  </>
                );
                break;
              default:
                res = '';
                break;
            }
          } else {
            res = null;
          }
        } else if (userRole === 'administrator') {
          if (val.invoiceUrl) {
            switch (val.status) {
              case 'waiting for submission':
                res = (
                  <Button type="link">
                    <Link
                      to={{
                        pathname: `/invoice-form/${val._id}`,
                        state: {
                          id: val._id,
                          invoiceUrl: val.invoiceUrl,
                        },
                      }}>
                      Run Invoice
                    </Link>
                  </Button>
                );
                break;
              case 'rejected':
                res = (
                  <Button type="link">
                    <Link
                      to={{
                        pathname: `/invoice-form/edit/${val._id}`,
                        state: {
                          id: val._id,
                          invoiceUrl: val.invoiceUrl,
                        },
                      }}>
                      Edit Invoice
                    </Link>
                  </Button>
                );
                break;
              case 'pending approval':
                res = (
                  <Button type="link">
                    <Link
                      to={{
                        pathname: `/invoice-form/view/${val._id}`,
                        state: {
                          id: val._id,
                          invoiceUrl: val.invoiceUrl,
                        },
                      }}>
                      View Invoice
                    </Link>
                  </Button>
                );
                break;
              default:
                res = '';
                break;
            }
          } else {
            res = null;
          }
        }

        return res;
      },
    },
  ];

  const PDFViewer = () => (
    <Modal
      title={`${viewModalTitle}`}
      open={showPDFViewer}
      centered
      bodyStyle={{ overflowY: 'auto' }}
      width={800}
      onCancel={() => setShowPDFViewer(false)}
      footer={null}
    >
      <iframe
        id="pdf-view"
        src={`${fileURL}#view=fitW`}
        title="pdfFileViewer"
        height={550}
        width="100%"
      />
    </Modal>
  );

  const onConfirmPurge = async () => {
    setOpenSelectConsultant(false);
    setPurgeLoading(true);
    console.log('Clicked Ok');
    console.log('Okay');
    console.log(ConsultantForm.getFieldsValue().consultants);
    const {
      _id,
      ...data
    } = purgingInvoice;
    data.paymentIds = ConsultantForm.getFieldsValue().consultants;
    console.log(data);
    const config = {
      url: `v1/invoices/${_id}/purge`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
      data,
    };
    console.log(config);
    console.log(purgingInvoice);
    await axios(config).then((res) => {
      console.log(res);
      setPurgeLoading(false);
      if (res.data.status) {
        message.success('Invoice purged successfully');
        setConsultants([]);
        handleUpdateInvoices();
      } else {
        message.error(res.data.message);
        setOpenSelectConsultant(true);
      }
    }).catch((err) => {
      console.error(err);
      setPurgeLoading(false);
      setOpenSelectConsultant(true);
    });
  };

  const onConfirmMerge = async () => {
    setOpenSelectInvoices(false);
    setMergeLoading(true);
    MergeForm.validateFields().then(async (values) => {
      console.log(values);
      const config = {
        url: `v1/invoices/${mergingInvoice}/merge`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
        data: values,
      };
      await axios(config).then((res) => {
        console.log(res);
        setMergeLoading(false);
        if (res.status === 201) {
          message.success('Invoices Merged Successfully!');
          handleUpdateInvoices();
        } else {
          message.error('Failed to Merge Invoice');
          setOpenSelectInvoices(true);
        }
      }).catch((err) => {
        console.log(err);
        setMergeLoading(false);
        setOpenSelectInvoices(true);
        message.error('Failed to Merge Invoice');
      });
    });
  };

  const onConfirmEdit = async () => {
    setOpenEditInvoice(false);
    setEditLoading(true);
    console.log('selectedInvoiceId', selectedInvoiceId);
    console.log('editformvalues', EditForm.getFieldsValue().paymentDetails);
    const config = {
      url: `v1/invoices/${selectedInvoiceId}/removePaymentIds?type=revert`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
      data: { paymentIds: EditForm.getFieldsValue().paymentDetails },
    };
    await axios(config).then((res) => {
      setEditLoading(false);
      if (res.data.status) {
        message.success('Removed selected IDs from Invoice.');
        setEditInvoiceData([]);
        handleUpdateInvoices();
      } else {
        message.error(res.data.message);
        setOpenEditInvoice(true);
      }
    }).catch((err) => {
      console.error(err);
      setEditLoading(false);
      setOpenEditInvoice(true);
    });
  };

  const onConfirmDelete = async () => {
    setOpenEditInvoice(false);
    setEditLoading(true);
    console.log('selectedInvoiceId', selectedInvoiceId);
    console.log('editformvalues', EditForm.getFieldsValue().paymentDetails);
    const config = {
      url: `v1/invoices/${selectedInvoiceId}/removePaymentIds?type=remove`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
      data: { paymentIds: EditForm.getFieldsValue().paymentDetails },
    };
    await axios(config).then((res) => {
      console.log('delete invoice response', res);
      setEditLoading(false);
      if (res.data.status) {
        message.success('Removed selected IDs from Invoice.');
        setEditInvoiceData([]);
        handleUpdateInvoices();
      } else {
        message.error(res.data.message);
        setOpenEditInvoice(true);
      }
    }).catch((err) => {
      console.error(err);
      setEditLoading(false);
      setOpenEditInvoice(true);
    });
  };

  const SelectConsultantModal = () => (
    <Modal
      title="Select Consultant for New Invoice"
      centered
      open={openSelectConsultant}
      confirmLoading={purgeLoading}
      onCancel={() => setOpenSelectConsultant(false)}
      okText="Purge Invoice"
      onOk={onConfirmPurge}
    >
      <Form
        form={ConsultantForm}
        layout="vertical"
      >
        <Form.Item name="consultants">
          <Checkbox.Group>
            {consultants.map((consultant) => (
              <Row key={consultant.label} style={{ marginBottom: '2px' }}>
                <Checkbox value={consultant.value}>{consultant.label}</Checkbox>
              </Row>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );

  const EditInvoiceModal = () => (
    <Modal
      title="Select Payment to Remove From Invoice"
      centered
      open={openEditInvoice}
      confirmLoading={editLoading}
      onCancel={() => setOpenEditInvoice(false)}
      okText="Edit Invoice"
      onOk={onConfirmEdit}
      footer={[
        <Button key="cancel" onClick={() => setOpenEditInvoice(false)}>
          Cancel
        </Button>,
        <Button key="edit" type="primary" loading={editLoading} onClick={onConfirmEdit}>
          Edit
        </Button>,
        <Button
          key="delete"
          type="danger"
          loading={editLoading}
          onClick={onConfirmDelete}
        >
          Delete
        </Button>,
      ]}
    >
      <Form
        form={EditForm}
        layout="vertical"
      >
        <Form.Item name="paymentDetails">
          <Checkbox.Group>
            {editInvoiceData.map((data) => (
              <Row key={data.value} style={{ marginBottom: '2px' }}>
                <Checkbox value={data.value}>{data.name} - {moment(data.runPaymentDate).format('DD/MM/YYYY')}</Checkbox>
              </Row>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );

  const SelectInvoiceModal = () => (
    <Modal
      title="Select Invoice to Merge Back"
      centered
      open={openSelectInvoices}
      confirmLoading={mergeLoading}
      onCancel={() => setOpenSelectInvoices(false)}
      okText="Merge Invoice"
      onOk={onConfirmMerge}
    >
      <Form
        form={MergeForm}
        layout="vertical"
      >
        <Form.Item
          name="invoices"
          rules={[
            {
              required: true,
              message: 'Select at least one invoice',
            },
          ]}
        >
          <Checkbox.Group>
            {childInvoices.map((invoice) => (
              <Row key={invoice._id} style={{ marginBottom: '2px' }}>
                <Checkbox value={invoice._id}>{invoice.invoiceNumber}</Checkbox>
              </Row>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );

  return (
    <>
      <Spin tip={purgeLoading ? 'Purging Invoice...' : 'Merging Invoice...'} spinning={mergeLoading || purgeLoading}>
        <Table
          scroll={{ x: 'auto' }}
          columns={columns}
          dataSource={invoiceData.results}
          pagination={{
            total: invoiceData.totalResults,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            defaultCurrent: 1,
            showSizeChanger: true,
            defaultPageSize: 5,
            pageSizeOptions: ['5', '10'],
          }}
        />
        <PDFViewer />
        <SelectConsultantModal />
        <SelectInvoiceModal />
        <EditInvoiceModal />
      </Spin>
    </>
  );
}
