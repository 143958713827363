import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {
  PageHeader,
  Breadcrumb,
  Button,
  Input,
  Row,
  Typography,
  message,
  Col,
  Layout,
  Card,
  Skeleton,
  Form,
  Tooltip,
  Select,
  DatePicker,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import './callOutManagement.css';
import TableCallOutManagement from '../../components/callOutManagement/TableCallOutManagement';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function CallOutManagementMain() {
  const token = getToken();
  const [searchForm] = Form.useForm();

  const [callOutData, setCallOutData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showReset, setShowReset] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchPSL, setSearchPSL] = useState('');
  const [searchAppliedTax, setSearchAppliedTax] = useState('');
  const [searchAppliedTaxVal, setSearchAppliedTaxVal] = useState('');
  const [searchCompletionDateRange, setSearchCompletionDateRange] = useState('');

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get('v1/callouts', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            name: searchName,
            psl: searchPSL,
            isTax: searchAppliedTax,
            startCompletionDate: searchCompletionDateRange !== '' ? new Date(searchCompletionDateRange[0]) : '',
            endCompletionDate: searchCompletionDateRange !== '' ? new Date(searchCompletionDateRange[1]) : '',
            limit: 99999999, // todo: fix
            sortBy: 'createdAt:desc',
          },
        })
        .then((res) => {
          setLoading(false);
          setCallOutData(res.data);
        })
        .catch((error) => {
          message.error(`Failed to retrive call out data. ${error}`);
        });
    };
    getData();
  }, [searchName, searchPSL, searchAppliedTax, searchCompletionDateRange]);

  const onSearch = async () => {
    searchForm
      .validateFields()
      .then(async (res) => {
        const { searchCallOut } = res;
        const { name, psl, appliedTax, completionDateRange } = searchCallOut;
        setSearchName(name);
        setSearchPSL(psl);
        setSearchAppliedTaxVal(appliedTax);
        if (appliedTax === 'true') {
          setSearchAppliedTax(true);
        } else if (appliedTax === 'false') {
          setSearchAppliedTax(false);
        }
        setSearchCompletionDateRange(completionDateRange);
        setShowReset(true);

        searchForm.resetFields();
      })
      .catch(() => {});
  };

  const onResetSearch = () => {
    setSearchName('');
    setSearchPSL('');
    setSearchAppliedTax('');
    setSearchAppliedTaxVal('');
    setSearchCompletionDateRange('');
    setShowReset(false);
  };

  const SearchBar = () => (
    <Form layout="horizontal" form={searchForm}>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 20,
        }}>
        <Col span={12}>
          <Item name={['searchCallOut', 'name']} initialvalue={searchName}>
            <div className="header-section-search-bar-section">
              Name
              <Input defaultValue={searchName} />
            </div>
          </Item>
        </Col>
        <Col span={12}>
          <Item name={['searchCallOut', 'psl']} initialvalue={searchPSL}>
            <div className="header-section-search-bar-section">
              PSL
              <Input defaultValue={searchPSL} />
            </div>
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div className="header-section-search-bar-section">
            Applied Tax
            <Item name={['searchCallOut', 'appliedTax']} initialvalue={searchAppliedTaxVal}>
              <Select allowClear showSearch placeholder="Select Status" style={{ width: '100%' }} initialvalue={searchAppliedTaxVal}>
                <Option value="true">Yes</Option>
                <Option value="false">No</Option>
              </Select>
            </Item>
          </div>
        </Col>
        <Col span={16}>
          <div className="header-section-search-bar-section">Completion Date Range</div>
          <Item name={['searchCallOut', 'completionDateRange']} initialvalue={searchCompletionDateRange}>
            <RangePicker style={{ width: '100%' }} />
          </Item>
        </Col>
      </Row>
    </Form>
  );
  const ApplyFilterButton = () => (
    <Button type="primary" key="submit" onClick={onSearch}>
      Apply
    </Button>
  );
  const ResetButton = () => (
    <Tooltip title="Reset Filters">
      <Button type="danger" disabled={!showReset} onClick={onResetSearch}>
        Reset
      </Button>
    </Tooltip>
  );

  const AddButton = () => (
    <Link to="/call-out-management/add-form">
      <Button type="primary">
        <Row>
          <Col className="vertical-align-center-button">
            <PlusCircleOutlined style={{ fontSize: '16px', paddingRight: 10 }} />
          </Col>
          Add Call Out Form
        </Row>
      </Button>
    </Link>
  );

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Typography.Link href="/call-out-management">Call Out Management</Typography.Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row style={{ justifyContent: 'space-between' }}>
        <PageHeader title="Call Out Management List" className="basic-pageheader" />
        <Col className="vertical-align-center-button">
          <AddButton />
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <div className="header-section">
          <Card className="header-section-card">
            <SearchBar />
            <Row className="apply-filter-button">
              {showReset === true ? (
                <Col className="vertical-align-center-button">
                  <Col>
                    <ApplyFilterButton />
                  </Col>
                  <Col className="right-positioned-button">
                    <ResetButton />
                  </Col>
                </Col>
              ) : (
                <Col className="vertical-align-center-button">
                  <ApplyFilterButton />
                </Col>
              )}
            </Row>
          </Card>
        </div>
        <div className="table-content">
          {loading ? <Skeleton /> : TableCallOutManagement(callOutData)}
        </div>
      </Content>
    </>
  );
}
