import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';

import countryCodes from '../../assets/file/countryCodes.json';

const EmergencyContactPageModal = (props) => {
  const [form] = Form.useForm();
  const {
    isModalVisible,
    title,
    toggleModal,
    addCallback,
    editCallback,
    deleteCallback,
    emergencyContactToEdit,
  } = props;

  const [relationship, setRelationship] = useState('');
  const [country, setCountry] = useState('');

  useEffect(() => {
    setRelationship(
      emergencyContactToEdit !== undefined ? emergencyContactToEdit.relationship : '',
    );
    setCountry(
      emergencyContactToEdit !== undefined ? emergencyContactToEdit.country : '+60',
    );
  }, [emergencyContactToEdit]);

  const { Item } = Form;
  const { Option } = Select;

  const relationshipOptions = [
    'Parents',
    'Wife',
    'Husband',
    'Son',
    'Daughter',
    'Relatives',
    'Friends',
    'Other',
  ];

  const prefixSelector = (
    <Form.Item
      name="country"
      noStyle
      initialValue={country}
    >
      <Select
        showSearch
        showArrow={false}
        style={{
          width: 170,
        }}
        defaultValue={country}
        onChange={(value) => setCountry(value)}
      >
        {countryCodes.map((val) => (
          <Option key={val.dial_code} value={val.dial_code}>{val.name} ({val.dial_code})</Option>
        ))}
      </Select>
    </Form.Item>
  );

  const displayButton = () => {
    if (title === 'Add Emergency Contact') {
      return (
        <>
          <Button
            onClick={() => {
              form.resetFields();
              toggleModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              if (form) {
                form.validateFields().then((values) => {
                  form.resetFields();
                  toggleModal();
                  addCallback(values);
                });
              }
            }}
          >
            Add
          </Button>
        </>
      );
    }

    form.setFieldsValue({
      name: emergencyContactToEdit.name,
      relationship: emergencyContactToEdit.relationship,
      others: emergencyContactToEdit.others || null,
      country: emergencyContactToEdit.country,
      phoneNumber: emergencyContactToEdit.phoneNumber,
    });

    return (
      <>
        <Button
          onClick={() => {
            if (form) {
              form.resetFields();
              toggleModal();
              deleteCallback(emergencyContactToEdit.id);
            }
          }}
        >
          Delete
        </Button>
        <Button
          type="primary"
          onClick={() => {
            if (form) {
              form.validateFields().then((values) => {
                form.resetFields();
                toggleModal();
                editCallback(values);
              });
            }
          }}
        >
          Save Changes
        </Button>
      </>
    );
  };

  return (
    <Modal title={title} open={isModalVisible} onCancel={toggleModal} footer={[displayButton()]}>
      <Form
        form={form}
        name="family-details-or-emergency-contact"
        onFinish={addCallback}
        layout="vertical"
        validateMessages={{
          required: '${label} is required!',
        }}
      >
        <Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input 
            maxLength={80}
            onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }}
          />
        </Item>
        <Item
          name="relationship"
          label="Relationship"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select showSearch onChange={(value) => setRelationship(value)} value={relationship}>
            {relationshipOptions.map((relationshipOption) => (
              <Option value={relationshipOption} key={relationshipOption}>
                {relationshipOption}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="others"
          label="Others (please specify)"
          rules={[
            {
              required: relationship === 'Other',
            },
          ]}
        >
          <Input disabled={relationship !== 'Other'} />
        </Item>
        <Item
          name="phoneNumber"
          label="Phone Number 12321"
          rules={[
            {
              required: false,
            },
            {
              pattern: /^[0-9]{4,14}(?:x.+)?$/,
              message: 'Please enter a valid phone number',
            },
          ]}
        >
          <Input
            addonBefore={prefixSelector}
            placeholder="123456789"
          />
        </Item>
      </Form>
    </Modal>
  );
};

export default EmergencyContactPageModal;
