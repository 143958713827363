import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Row, Col, Form, Select, Input, Button, message, InputNumber, Modal } from 'antd';

import axios from 'axios';

import { getUser, getToken } from '../../utils/AuthService';

const { Option } = Select;
const { TextArea } = Input;

export default function InvoiceForm(type) {
  const token = getToken();
  const { role } = getUser();
  const location = useLocation();
  const { id } = location.state;
  const urlAction = location.pathname.split('/')[2];

  const history = useHistory();

  const [rejectForm] = Form.useForm();
  const [form] = Form.useForm();

  const [isFirstCustom, setIsFirstCustom] = useState(false);
  const [isSecCustom, setIsSecCustom] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState(location.state.invoiceUrl);
  const [appliedChanges, setAppliedChanges] = useState(true);
  const [openRejectModal, setOpenRejectModal] = useState(false);

  useEffect(() => {
    const isCustomTax = (label) => {
      switch (label) {
        case 'SST 6%':
        case 'ST5-5%':
        case 'SV-6%':
        case 'Witholding':
          return false;
        default:
          return true;
      }
    };

    const fetchInvoice = async () => {
      const formdata = {};
      await axios(`v1/invoices/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
        .then((res) => {
          formdata.insuranceRate = parseFloat(res.data.insuranceFee);
          if (res.data.taxItems) {
            res.data.taxItems.forEach((taxItem, i) => {
              if (isCustomTax(taxItem.label) && i === 0) {
                console.log(taxItem, i);
                setIsFirstCustom(true);
                formdata.tax = 'Custom';
                formdata.custom = {
                  label: taxItem.label,
                  amount: taxItem.amount,
                };
              }
              if (!isCustomTax(taxItem.label) && i === 0) {
                console.log(taxItem, i);
                setIsFirstCustom(false);
                formdata.tax = taxItem.label;
              }
              if (isCustomTax(taxItem.label) && i === 1) {
                console.log(taxItem, i);
                setIsSecCustom(true);
                formdata.secTax = 'Custom';
                formdata.secCustom = {
                  label: taxItem.label,
                  amount: taxItem.amount,
                };
              }
              if (!isCustomTax(taxItem.label) && i === 1) {
                console.log(taxItem, i);
                setIsSecCustom(false);
                formdata.secTax = taxItem.label;
              }
            });
            form.setFieldsValue({
              form: formdata,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    if (type !== 'Approve') {
      fetchInvoice();
    }
  }, []);

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onCheckFirstOption = (val) => {
    if (val === 'Custom') {
      setIsFirstCustom(true);
    } else {
      setIsFirstCustom(false);
    }
  };
  const onCheckSecOption = (e) => {
    if (e === 'Custom') {
      setIsSecCustom(true);
    } else {
      setIsSecCustom(false);
    }
  };

  const updateInvoice = async (values, status, applyChanges) => {
    const taxItems = [];

    if (values.form.tax && values.form.tax === 'Custom') {
      taxItems.push({
        label: values.form.custom.label,
        amount: parseFloat(values.form.custom.amount),
      });
    }

    if (values.form.tax && values.form.tax !== 'Custom') {
      taxItems.push({
        label: values.form.tax,
        amount: 0,
      });
    }

    if (values.form.secTax && values.form.secTax !== 'Custom') {
      taxItems.push({
        label: values.form.secTax,
        amount: 0,
      });
    }

    if (values.form.secTax && values.form.secTax === 'Custom') {
      taxItems.push({
        label: values.form.secCustom.label,
        amount: parseFloat(values.form.secCustom.amount),
      });
    }

    const data = {
      status,
    };

    if (values.form.tax) {
      data.taxItems = taxItems;
    }

    if (values.form.insuranceRate) {
      data.insuranceFee = values.form.insuranceRate;
    }

    let APIURL;
    if (status === 'pending approval') {
      APIURL = `v1/invoices/${id}/submit`;
    } else if (status === 'waiting for submission') {
      if (applyChanges === 'apply changes') {
        APIURL = `v1/invoices/${id}/update`;
      }
    } else if (status === 'rejected') {
      if (urlAction === 'edit') {
        APIURL = `v1/invoices/${id}/update`;
      } else {
        APIURL = `v1/invoices/${id}/reject`;
      }
    } else if (status === 'pending payment') {
      APIURL = `v1/invoices/${id}/approve`;
    }

    const config = {
      url: APIURL,
      method: 'put',
      data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    const result = await axios(config)
      .then((res) => {
        console.log(res);
        setInvoiceUrl(res.data.invoiceUrl);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

    return result;
  };

  const approveInvoice = async (status) => {
    const result = await updateInvoice({ form: {} }, status);
    if (result) {
      history.push('/invoice-list');
    } else {
      message.error('Failed to Update Status');
    }
  };

  const onSubmitRejectForm = async (res) => {
    setOpenRejectModal(false);
    const { rejectComments } = res.rejectFormVal;

    const data = {
      status: 'rejected',
      reject_remarks: rejectComments,
    };
    const config = {
      url: `v1/invoices/${id}/reject`,
      method: 'put',
      data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    };
    await axios(config)
      .then(() => {
        rejectForm.resetFields();
        message.success('Rejected invoice successfully.');
        history.push('/invoice-list');
        return true;
      })
      .catch((err) => {
        rejectForm.resetFields();
        message.error(`Failed to reject invoice. ${err}`);
        return false;
      });
  };

  const RemarksCommentForm = () => (
    <Form form={rejectForm} layout="vertical" name="reject-form" onFinish={onSubmitRejectForm}>
      <Form.Item name={['rejectFormVal', 'rejectComments']}>
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  );

  const RejectModal = () => (
    <Modal
      title="Are you sure you want to reject this invoice? Please give a reason."
      open={openRejectModal}
      centered
      bodyStyle={{ overflowY: 'auto' }}
      width={800}
      onCancel={() => setOpenRejectModal(false)}
      footer={[
        <Button key="back" onClick={() => setOpenRejectModal(false)}>
          No
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={rejectForm.submit}
        >
          Yes
        </Button>,
      ]}
    >
      <RemarksCommentForm />
    </Modal>
  );

  const onReject = () => {
    setOpenRejectModal(true);
  };

  const onFinish = async (values) => {
    const result = await updateInvoice(values, 'pending approval');
    if (result) {
      history.push('/invoice-list');
      message.success('Invoice submitted successfully');
    } else {
      message.error('Failed to Submit Invoice');
    }
  };

  const addButton = () => {
    console.log('type', type);
    switch (type) {
      case 'Add':
        return (
          <div>
            <Button type="primary" htmlType="submit" disabled={!appliedChanges}>
              {urlAction === 'edit' ? 'Resubmit' : 'Submit'}
            </Button>
          </div>
        );
      case 'Edit':
        return (
          <div>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        );
      case 'Approve':
        return role === 'authorizer' ? (
          <Row style={{ justifyContent: 'end', margin: '20px 0px' }} gutter={10}>
            <Col>
              <Button type="danger" onClick={() => onReject()}>
                Reject
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => approveInvoice('pending payment')}>
                Approve and generate invoice
              </Button>
            </Col>
          </Row>
        ) : null;
      default:
        return '';
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
        onValuesChange={() => setAppliedChanges(false)}>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 20,
          }}>
          <Col className="gutter-row" span={type !== 'Approve' ? 15 : 24}>
            <div className="pdf-page">
              <iframe
                id="pdf-view"
                src={`${invoiceUrl}#view=fitW`}
                title="samplePDF"
                height="450px"
                width="100%"
              />
            </div>
          </Col>

          {type !== 'Approve' ? (
            <Col className="gutter-row" span={8} style={{ overflow: 'auto', height: '450px' }}>
              <Row>
                <Form.Item
                  style={{ width: '100%' }}
                  label="Insurance Rate"
                  name={['form', 'insuranceRate']}
                  rules={[{ required: true }]}
                  initialValue="55.00">
                  <Input placeholder="0.00" />
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  style={{ width: '100%' }}
                  label="Choose Tax"
                  name={['form', 'tax']}
                  rules={[{ required: true }]}>
                  <Select placeholder="Please select" allowClear onChange={onCheckFirstOption}>
                    <Option value="SST 6%">SST 6%</Option>
                    <Option value="ST5-5%">ST5-5%</Option>
                    <Option value="Witholding">Witholding</Option>
                    <Option value="SV-6%">SV-6%</Option>
                    <Option value="Custom">Custom</Option>
                  </Select>
                </Form.Item>
              </Row>

              {isFirstCustom ? (
                <div>
                  <Row>
                    <Form.Item
                      style={{ width: '100%' }}
                      // style={style}
                      label="Enter custom label"
                      name={['form', 'custom', 'label']}
                      rules={[{ required: isFirstCustom }]}>
                      <Input placeholder="example" />
                    </Form.Item>
                  </Row>

                  <Row>
                    <Form.Item
                      style={{ width: '100%' }}
                      // style={style}
                      label="Enter custom amount"
                      name={['form', 'custom', 'amount']}
                      rules={[{ required: isFirstCustom }]}>
                      <Input placeholder="example" />
                    </Form.Item>
                  </Row>
                </div>
              ) : null}

              <Row>
                <Form.Item
                  style={{ width: '100%' }}
                  label="Choose Another Tax"
                  name={['form', 'secTax']}
                  rules={[{ required: false }]}>
                  <Select placeholder="Please select" allowClear onChange={onCheckSecOption}>
                    <Option value="SST 6%">SST 6%</Option>
                    <Option value="ST5-5%">ST5-5%</Option>
                    <Option value="Witholding">Witholding</Option>
                    <Option value="SV-6%">SV-6%</Option>
                    <Option value="Custom">Custom</Option>
                  </Select>
                </Form.Item>
              </Row>

              {isSecCustom ? (
                <div>
                  <Row>
                    <Form.Item
                      style={{ width: '100%' }}
                      // style={style}
                      label="Enter custom label"
                      name={['form', 'secCustom', 'label']}
                      rules={[{ required: isSecCustom }]}>
                      <Input placeholder="example" />
                    </Form.Item>
                  </Row>

                  <Row>
                    <Form.Item
                      style={{ width: '100%' }}
                      // style={style}
                      label="Enter custom amount"
                      name={['form', 'secCustom', 'amount']}
                      rules={[{ required: isSecCustom }]}>
                      <InputNumber
                        prefix="RM"
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                  </Row>
                </div>
              ) : null}
            </Col>
          ) : null}
        </Row>
        <Row style={{ justifyContent: 'end', marginTop: '50px' }}>
          {type !== 'Approve' ? (
            <Button
              type="primary"
              style={{ marginRight: 15 }}
              onClick={async () => {
                form
                  .validateFields()
                  .then(async (values) => {
                    console.log(values);
                    const result = await updateInvoice(
                      values,
                      'waiting for submission',
                      'apply changes',
                    );
                    console.log(result);
                    if (result) {
                      message.success('Changes Applied');
                      setAppliedChanges(true);
                    } else {
                      message.error('Failed to apply changes');
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}>
              Apply Changes
            </Button>
          ) : null}
          <Form.Item>{addButton(type)}</Form.Item>
        </Row>
      </Form>
      <RejectModal />
    </>
  );
}
