import React from 'react';

import { Row, Descriptions, Tag } from 'antd';
import 'antd/dist/antd.less';

import moment from 'moment';

import { capitalizedFirstAlphabet } from '../../utils/capitalizedFirstAlphabet';

export default function WorkInformationDetails(
  workConsultantData,
  workConsultantName,
  workConsultantBankName,
  pslData,
  contractExpiredDates,
  invoicingSchedule,
) {
  return (
    <div className="table-content">
      <Descriptions
        labelStyle={{ maxWidth: '120px', fontWeight: '600', fontSize: '14px', lineHeight: '22px' }}
        contentStyle={{ fontWeight: '400' }}
        column={1}
        span={1}
        bordered>
        <Descriptions.Item label="Name">
          <Row className="personal-details-form-data">
            <div>
              {workConsultantName !== null ? capitalizedFirstAlphabet(workConsultantName) : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="IC / Passport">
          <Row className="personal-details-form-data">
            <div>{workConsultantData.nric !== null ? workConsultantData.nric : '-'}</div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="DOB">
          <Row className="personal-details-form-data">
            <div>
              {workConsultantData.dateOfBirth !== null
                ? moment(workConsultantData.dateOfBirth).format('DD MMMM YY')
                : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="Phone No">
          <Row className="personal-details-form-data">
            <div>
              {workConsultantData.contactPhone !== null ? workConsultantData.contactPhone : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="Email">
          <Row className="personal-details-form-data">
            <div>{workConsultantData.email !== null ? workConsultantData.email : '-'}</div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="PSL">
          <Row className="personal-details-form-data" style={{ justifyContent: 'start' }}>
            {pslData.length !== 0
              ? pslData.map((val) => (
                <Tag key={val} color="default">
                  {val}
                </Tag>
              ))
              : '-'}
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="Contract Expiry Date">
          <Row className="personal-details-form-data" style={{ justifyContent: 'start' }}>
            <div>
              {contractExpiredDates.length !== 0
                ? contractExpiredDates.map((val) => (
                  <Tag key={val} color="default">
                    {moment(val).format('DD MMMM YY')}
                  </Tag>
                ))
                : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="Agreement Signed">
          <Row className="personal-details-form-data">
            <div>
              {workConsultantData.agreementSigned !== null
                ? workConsultantData.agreementSigned === true
                  ? 'Yes'
                  : 'No'
                : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="20 Rules">
          <Row className="personal-details-form-data">
            <div>
              {workConsultantData.rulesSigned !== null
                ? workConsultantData.rulesSigned === true
                  ? 'Yes'
                  : 'No'
                : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="Invoicing Schedule">
          <Row className="personal-details-form-data">
            <div>
              {invoicingSchedule.length !== 0
                ? invoicingSchedule.map((val) => (
                  <Tag key={val.id} color="default">
                    {val.data}
                  </Tag>
                ))
                : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="Bank Name">
          <Row className="personal-details-form-data">
            <div>
              {workConsultantBankName !== null
                ? capitalizedFirstAlphabet(workConsultantBankName)
                : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item
          label={(
            <div>
              Account Name
            </div>
          )}>
          <Row className="personal-details-form-data">
            <div>
              {workConsultantData.accountName !== null ? workConsultantData.accountName : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="Account No/ Switch Code">
          <Row className="personal-details-form-data">
            <div>
              {workConsultantData.accountNumber !== null ? workConsultantData.accountNumber : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="EPF No">
          <Row className="personal-details-form-data">
            {workConsultantData.epfNumber !== null ? workConsultantData.epfNumber : '-'}{' '}
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="Socso No">
          <Row className="personal-details-form-data">
            <div>
              {workConsultantData.socsoNumber !== null ? workConsultantData.socsoNumber : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="PCB No">
          <Row className="personal-details-form-data">
            <div>{workConsultantData.pcbNumber !== null ? workConsultantData.pcbNumber : '-'}</div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="Insurance Start Date">
          <Row className="personal-details-form-data">
            <div>
              {workConsultantData.insuranceStart !== null
                ? moment(workConsultantData.insuranceStart).format('DD MMMM YY')
                : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="Insurance Expiry Date">
          <Row className="personal-details-form-data">
            <div>
              {workConsultantData.insuranceEnd !== null
                ? moment(workConsultantData.insuranceEnd).format('DD MMMM YY')
                : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="Endorsement Date">
          <Row className="personal-details-form-data">
            <div>
              {workConsultantData.endorsementNote !== null
                ? workConsultantData.endorsementNote
                : '-'}
            </div>
          </Row>
        </Descriptions.Item>

        <Descriptions.Item label="Remarks">
          <Row className="personal-details-form-data">
            <div>{workConsultantData.remarks !== null ? workConsultantData.remarks : '-'}</div>
          </Row>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
