import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Row,
  Descriptions,
  Typography,
  Col,
  Button,
  Table,
  PageHeader,
  message,
  Modal,
  Upload,
  Form,
  DatePicker,
  Skeleton,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import axios from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';
import queryString from 'query-string';

import { getToken } from '../../utils/AuthService';

const { Item } = Descriptions;
const { Text } = Typography;
const { Dragger } = Upload;
const { RangePicker } = DatePicker;

export default function DonwloadsTopSection(consultantId) {
  const { search } = useLocation();
  const searchObj = queryString.parse(search);
  const { id } = searchObj;

  const token = getToken();
  const insurancePolicyHeader = useRef(null);

  const [insurancePolicy] = Form.useForm();
  const [transcript] = Form.useForm();
  const [ICPassport] = Form.useForm();
  // const [hal20rules] = Form.useForm();
  const [workPermit] = Form.useForm();
  const [paymentSlipHistory] = Form.useForm();

  const [loadingConsultantData, setLoadingConsultantData] = useState(false);

  const [resumeFilename, setResumeFilename] = useState('');
  const [resumeURL, setResumeURL] = useState('');
  const [NRICFilename, setNRICFilename] = useState('');
  const [NRICURL, setNRICURL] = useState('');
  const [TranscriptFilename, setTranscriptFilename] = useState('');
  const [TranscriptURL, setTranscriptURL] = useState('');
  const [ICPassportFilename, setICPassportFilename] = useState('');
  const [InsurancePolicyFilename, setInsurancePolicyFilename] = useState('');
  const [InsurancePolicyURL, setInsurancePolicyURL] = useState('');
  const [workPermitFilename, setWorkPermitFilename] = useState('');
  const [workPermitURL, setWorkPermitURL] = useState('');
  // const [hal20RulesFilename, setHal20RulesFilename] = useState('');
  // const [hal20RulesURL, setHal20RulesURL] = useState('');

  // const [viewUploadNewHal20RulesModal, setViewUploadNewHal20RulesModal] = useState(false);
  // const [loadingUploadNewWHal20Rules, setLoadingUploadNewHal20Rules] = useState(false);
  // const [hal20RulesFileList, setHal20RulesFileList] = useState(false);

  const [loadingServiceAgreementData, setLoadingServiceAgreementData] = useState(false);
  const [serviceAgreementFilename, setServiceAgreementFilename] = useState('');
  const [serviceAgreementURL, setServiceAgreementURL] = useState('');

  const [loadingDownloadResume, setLoadingDownloadResume] = useState(false);
  const [loadingDownloadNRIC, setLoadingDownloadNRIC] = useState(false);
  const [loadingDownloadNTranscript, setLoadingDownloadTranscript] = useState(false);
  const [loadingDownloadServiceAgreement, setLoadingDownloadServiceAgreement] = useState(false);
  const [loadingDownloadInsurancePolicy, setLoadingDownloadInsurancePolicy] = useState(false);
  const [loadingDownloadWorkPermit, setLoadingDownloadWorkPermit] = useState(false);
  const [loadingDownloadPaymentSlipHistory, setLoadingDownloadPaymentSlipHistory] = useState(false);
  const [loadingDownloadTableInsurance, setLoadingDownloadTableInsurance] = useState(false);
  // const [loadingDownloadHal20Rules, setLoadingDownloadHal20Rules] = useState(false);

  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [modalTitle, setViewModalTitle] = useState('');
  const [fileURL, setFileURL] = useState('');

  const [uploadFileType, setUploadFileType] = useState('');
  const [fileList, setFileList] = useState([]);

  const [viewUploadNewTranscriptModal, setViewUploadNewTranscriptModal] = useState(false);
  const [loadingUploadNewTranscript, setLoadingUploadNewTranscript] = useState(false);

  const [viewUploadNewNRICPassportModal, setViewUploadNewNRICPassporttModal] = useState(false);
  const [loadingUploadNewNRICPassport, setLoadingUploadNewNRICPassport] = useState(false);

  const [viewUploadNewInsuranceModal, setViewUploadNewInsuranceModal] = useState(false);
  const [loadingUploadNewInsurancePolicy, setLoadingUploadNewInsurancePolicy] = useState(false);

  const [viewUploadNewWorkPermiteModal, setViewUploadNewWorkPermiteModal] = useState(false);
  const [loadingUploadNewWorkPermit, setLoadingUploadNewWorkPermit] = useState(false);

  const [loadingPaymentSlipData, setLoadingPaymentSlipData] = useState(false);
  const [paymentSlipData, setPaymentSlipData] = useState([]);
  const [openAddNewPaymentSlip, setOpenAddNewPaymentSlip] = useState(false);
  const [loadingAddPaymentSlip, setLoadingAddPaymentSlip] = useState(false);
  const [setUploadedPaymentSlipFilename] = useState('');
  const [createdPaymentSlip, setCreatedPaymentSlip] = useState(false);

  const [loadingInsurancePolicyData, setLoadingInsurancePolicyData] = useState(false);
  const [insurancePolicyData, setInsurancePolicyData] = useState([]);

  const defaultURL = process.env.REACT_APP_NODE_ENV === 'PRODUCTION'
    ? process.env.REACT_APP_PRODUCTION_URL
    : process.env.REACT_APP_NODE_ENV === 'STAGING'
      ? process.env.REACT_APP_SANDBOX_URL
      : process.env.REACT_APP_LOCAL_URL;

  const getDimensions = (ele) => {
    const { height } = ele.getBoundingClientRect();
    const { offsetTop } = ele;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  const handleScroll = () => {
    const { height: headerHeight } = getDimensions(insurancePolicyHeader.current);
    const scrollPosition = window.scrollY + headerHeight;
    window.scrollTo(0, scrollPosition);

    const insurancePolicyTitle = document.getElementById('insurance-policy-header');
    setTimeout(() => {
      insurancePolicyTitle.style.color = insurancePolicyTitle.style.color === 'rgba(0, 0, 0, 0.85)'
        ? '#f7931d'
        : 'rgba(0, 0, 0, 0.85)';
      setTimeout(() => {
        insurancePolicyTitle.style.color = insurancePolicyTitle.style.color === 'rgba(0, 0, 0, 0.85)'
          ? '#f7931d'
          : 'rgba(0, 0, 0, 0.85)';
      }, 300);
    }, 300);
  };

  //* fetch service agreement data
  useEffect(() => {
    const fetchServiceAgreement = async () => {
      setLoadingServiceAgreementData(true);
      await axios
        .get('v1/callouts/getSingleCallOutData', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            consultantId,
          },
        })
        .then((results) => {
          //* might change to get data from array
          if (results.data.results.length !== 0) {
            setServiceAgreementURL(results.data.results[0].contractUrl);
            const fileUrl = results.data.results[0].contractUrl.substring(
              results.data.results[0].contractUrl.lastIndexOf('/') + 1,
            );
            const newFileUrl = decodeURI(fileUrl.replace(/\+/g, ' '));
            setServiceAgreementFilename(newFileUrl);
          } else {
            setServiceAgreementURL('');
            setServiceAgreementURL('');
          }
        })
        .catch((error) => {
          message.error(`Failed to retrive call out data. ${error}`);
        });
    };

    fetchServiceAgreement();
    setLoadingServiceAgreementData(false);

    const fetchConsultantData = async () => {
      setLoadingConsultantData(true);
      await axios
        .get(`v1/consultants/${consultantId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((results) => {
          if (results.data.resumeUrl !== null && results.data.resumeUrl !== undefined) {
            setResumeURL(results.data.resumeUrl);
            const resumeLink = results.data.resumeUrl.substring(
              results.data.resumeUrl.lastIndexOf('/') + 1,
            );
            const newResumeLink = decodeURI(resumeLink.replace(/\+/g, ' '));
            setResumeFilename(newResumeLink);
          } else {
            setResumeURL('');
            setResumeFilename('');
          }

          if (results.data.nricUrl !== null && results.data.nricUrl !== undefined) {
            setNRICURL(results.data.nricUrl);
            const nricLink = results.data.nricUrl.substring(
              results.data.nricUrl.lastIndexOf('/') + 1,
            );
            const newNricLink = decodeURI(nricLink.replace(/\+/g, ' '));
            setNRICFilename(newNricLink);
          } else {
            setNRICURL('');
            setNRICFilename('');
          }

          if (
            results.data.transcriptUrl[results.data.transcriptUrl.length - 1] !== null
            && results.data.transcriptUrl[results.data.transcriptUrl.length - 1] !== undefined
          ) {
            setTranscriptURL(results.data.transcriptUrl[results.data.transcriptUrl.length - 1]);
            const transcriptLink = results.data.transcriptUrl[
              results.data.transcriptUrl.length - 1
            ].substring(
              results.data.transcriptUrl[results.data.transcriptUrl.length - 1].lastIndexOf('/')
                + 1,
            );
            const newTranscriptLink = decodeURI(transcriptLink.replace(/\+/g, ' '));
            setTranscriptFilename(newTranscriptLink);
          } else {
            setTranscriptURL('');
            setTranscriptFilename('');
          }

          if (
            results.data.insurancePolicyUrl !== null
            && results.data.insurancePolicyUrl !== undefined
          ) {
            setInsurancePolicyURL(results.data.insurancePolicyUrl);
            const insurancePolicyLink = results.data.insurancePolicyUrl.substring(
              results.data.insurancePolicyUrl.lastIndexOf('/') + 1,
            );
            const newInsurancePolicyLink = decodeURI(insurancePolicyLink.replace(/\+/g, ' '));
            setInsurancePolicyFilename(newInsurancePolicyLink);
          } else {
            setInsurancePolicyURL('');
            setInsurancePolicyFilename('');
          }

          if (results.data.workPermitUrl !== null && results.data.workPermitUrl !== undefined) {
            setWorkPermitURL(results.data.workPermitUrl);
            const workPermitLink = results.data.workPermitUrl.substring(
              results.data.workPermitUrl.lastIndexOf('/') + 1,
            );
            const newWorkPermitLink = decodeURI(workPermitLink.replace(/\+/g, ' '));
            setWorkPermitFilename(newWorkPermitLink);
          } else {
            setWorkPermitURL('');
            setWorkPermitFilename('');
          }

          // if (results.data.rulesUrl !== null && results.data.rulesUrl !== undefined) {
          //   setHal20RulesURL(results.data.rulesUrl);
          //   const rulesLink = results.data.rulesUrl.substring(
          //     results.data.rulesUrl.lastIndexOf('/') + 1,
          //   );
          //   const newHal20RulesLink = decodeURI(rulesLink.replace(/\+/g, ' '));
          //   setHal20RulesFilename(newHal20RulesLink);
          // } else {
          //   setHal20RulesURL('');
          //   setHal20RulesFilename('');
          // }
          setLoadingConsultantData(false);
        })
        .catch((error) => {
          message.error(`Failed to retrive consultant data. ${error}`);
          setLoadingConsultantData(false);
        });
    };

    fetchConsultantData();
  }, [TranscriptFilename, ICPassportFilename]);

  //* fetch payment slip history data
  useEffect(() => {
    const fetchPaymentSlipHistory = async () => {
      setLoadingPaymentSlipData(true);
      await axios
        .get('v1/paymentslips', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit: 99999,
            consultantId,
            sortBy: 'createdAt:desc',
          },
        })
        .then((results) => {
          setPaymentSlipData(results.data.results);
        })
        .catch((error) => {
          message.error(`Failed to retrive payment slip history data. ${error}`);
        });
    };

    fetchPaymentSlipHistory();
    setLoadingPaymentSlipData(false);
  }, [createdPaymentSlip]);

  //* Fetch insurance policy data
  useEffect(() => {
    const fetchInsurancePolicy = async () => {
      setLoadingInsurancePolicyData(true);
      await axios
        .get('v1/insurances', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit: 99999,
            consultantId,
            sortBy: 'createdAt:desc',
          },
        })
        .then((results) => {
          setInsurancePolicyData(results.data.results);
        })
        .catch((error) => {
          message.error(`Failed to retrive insurance policy history data. ${error}`);
        });
    };

    fetchInsurancePolicy();
    setLoadingInsurancePolicyData(false);
  }, [InsurancePolicyFilename]);

  const validateMessages = {
    required: '${label} is required!',
    types: {
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const uploadFileProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);

      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (sizeInMB > 5) {
        message.error(`${file.name} exceeds 5MB`);
      }

      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        message.error(`${file.name} is not a excel file or PDF file`);
      }

      return sizeInMB || isPDF || Upload.LIST_IGNORE;
    },
    fileList,
  };

  const onFinishUploadTranscript = async (values) => {
    const { newTranscript } = values;

    setLoadingUploadNewTranscript(true);
    const data = new FormData();
    data.append('docs', newTranscript.transcriptFile.fileList[0].originFileObj);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    await axios
      .post(`${defaultURL}v1/consultants/${consultantId}/uploadDocs?type=transcript`, data, config)
      .then((res) => {
        setTranscriptURL(res.data.location[0]);
        const transcriptLink = res.data.location[0].substring(
          res.data.location[0].lastIndexOf('/') + 1,
        );
        setTranscriptFilename(transcriptLink);

        setLoadingUploadNewTranscript(false);
        setViewUploadNewTranscriptModal(false);
        message.success('New transcript file uploaded successfully.');
        handleScroll();
      })
      .catch((err) => {
        setLoadingUploadNewTranscript(false);
        setViewUploadNewTranscriptModal(false);
        message.error(`New transcript file upload failed. ${err}`);
      });
  };

  const onFinishUploadICPassport = async (values) => {
    const { newICPassport } = values;

    setLoadingUploadNewNRICPassport(true);
    const data = new FormData();
    data.append('docs', newICPassport.transcriptFile.fileList[0].originFileObj);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token.access.token}`,
      },
    };
    await axios
      .post(`${defaultURL}v1/consultants/${consultantId}/uploadDocs?type=nric`, data, config)
      .then((res) => {
        setNRICURL(res.data.location[0]);
        const ICPassportLink = res.data.location[0].substring(
          res.data.location[0].lastIndexOf('/') + 1,
        );
        setICPassportFilename(ICPassportLink);

        setLoadingUploadNewNRICPassport(false);
        setViewUploadNewNRICPassporttModal(false);
        message.success('New IC/ passport file uploaded successfully.');
        handleScroll();
      })
      .catch((err) => {
        setLoadingUploadNewNRICPassport(false);
        setViewUploadNewNRICPassporttModal(false);
        message.error(`New IC/ passport file upload failed. ${err}`);
      });
  };

  const onFinishUploadInsurance = async (values) => {
    const { newInsurance } = values;

    const newInsuranceDate = newInsurance.insuranceExpiredDate;

    setLoadingUploadNewInsurancePolicy(true);
    const data = new FormData();
    data.append('insurance', newInsurance.insuranceFile.fileList[0].originFileObj);
    data.append('insuranceStart', newInsuranceDate ? newInsurance.insuranceExpiredDate[0] : null);
    data.append('insuranceEnd', newInsuranceDate ? newInsurance.insuranceExpiredDate[1] : null);
    const config = {
      headers: {
        'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    await axios
      .post(`${defaultURL}v1/consultants/${consultantId}/uploadInsurance`, data, config)
      .then((res) => {
        setInsurancePolicyURL(res.data.body.insurancePolicyUrl);
        const insurancePolicyLink = res.data.body.insurancePolicyUrl.substring(
          res.data.body.insurancePolicyUrl.lastIndexOf('/') + 1,
        );
        setInsurancePolicyFilename(insurancePolicyLink);

        setLoadingUploadNewInsurancePolicy(false);
        setViewUploadNewInsuranceModal(false);
        message.success('New Insurance Policy file uploaded successfully.');
        handleScroll();
      })
      .catch((err) => {
        setLoadingUploadNewInsurancePolicy(false);
        setViewUploadNewInsuranceModal(false);
        message.error(`New Insurance Policy file upload failed. ${err}`);
      });
  };

  // const uploadHal20RulesProps = {
  //   onRemove: (file) => {
  //     const index = fileList.indexOf(file);
  //     const newFileList = fileList.slice();
  //     newFileList.splice(index, 1);
  //     setHal20RulesFileList(newFileList);
  //   },
  //   beforeUpload: (file) => {
  //     setHal20RulesFileList([file]);

  //     const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
  //     if (sizeInMB > 5) {
  //       message.error(`${file.name} exceeds 5MB`);
  //     }

  //     const isPDF = file.type === 'application/pdf';
  //     if (!isPDF) {
  //       message.error(`${file.name} is not a excel file or PDF file`);
  //     }

  //     return sizeInMB || isPDF || Upload.LISI_IGNORE;
  //   },
  //   fileList,
  // };

  // const onFinishUploadHal20Rules = async (values) => {
  //   const { newHal20Rules } = values;

  //   setLoadingUploadNewHal20Rules(true);
  //   const data = new FormData();
  //   data.append('docs', newHal20Rules.newHal20RulesFile.fileList[0].originFileObj);
  //   const config = {
  //     headers: {
  //       'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
  //     },
  //   };

  //   await axios
  //     .post(`${defaultURL}v1/consultants/${consultantId}/uploadDocs?type=20rules`, data, config)
  //     .then((res) => {
  //       setHal20RulesURL(res.data.location[0]);
  //       const rulesLink = res.data.location[0].substring(
  //         res.data.location[0].lastIndexOf('/') + 1,
  //       );
  //       setHal20RulesFilename(rulesLink);

  //       setLoadingUploadNewHal20Rules(false);
  //       setViewUploadNewHal20RulesModal(false);
  //       message.success('New Hal 20 rules file uploaded successfully.');
  //     })
  //     .catch((err) => {
  //       setLoadingUploadNewHal20Rules(false);
  //       setViewUploadNewHal20RulesModal(false);
  //       message.error(`New Hal 20 rules file upload failed. ${err}`);
  //     });
  // };

  const uploadWorkPermitProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);

      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (sizeInMB > 5) {
        message.error(`${file.name} exceeds 5MB`);
      }

      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        message.error(`${file.name} is not a excel file or PDF file`);
      }

      return sizeInMB || isPDF || Upload.LISI_IGNORE;
    },
    fileList,
  };

  const onFinishUploadWorkPermit = async (values) => {
    const { newWorkPermit } = values;

    const newWorkPermitDate = newWorkPermit.workPermitExpiredDate;

    setLoadingUploadNewWorkPermit(true);
    const data = new FormData();
    data.append('workPermit', newWorkPermit.newWorkPermitFile.fileList[0].originFileObj);
    data.append('workPermitStart', newWorkPermitDate ? newWorkPermit.workPermitExpiredDate[0] : null);
    data.append('workPermitEnd', newWorkPermitDate ? newWorkPermit.workPermitExpiredDate[1] : null);
    const config = {
      headers: {
        'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    await axios
      .post(`${defaultURL}v1/consultants/${consultantId}/uploadWorkPermit`, data, config)
      .then((res) => {
        setWorkPermitURL(res.data.body.workPermitUrl);
        const workPermitLink = res.data.body.workPermitUrl.substring(
          res.data.body.workPermitUrl.lastIndexOf('/') + 1,
        );
        setWorkPermitFilename(workPermitLink);

        setLoadingUploadNewWorkPermit(false);
        setViewUploadNewWorkPermiteModal(false);
        message.success('New work permit file uploaded successfully.');
      })
      .catch((err) => {
        setLoadingUploadNewWorkPermit(false);
        setViewUploadNewWorkPermiteModal(false);
        message.error(`New work permit file upload failed. ${err}`);
      });
  };

  const uploadPaymentSlip = {
    maxCount: 1,
    beforeUpload(file) {
      setUploadedPaymentSlipFilename(file.name);
      // todo: check again
      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (sizeInMB > 5) {
        message.error(`${file.name} exceeds 5MB`);
      }

      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        message.error(`${file.name} is not a excel file or PDF file`);
      }

      return sizeInMB || isPDF || Upload.LISI_IGNORE;
    },
    // todo: need check onchg again
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log('not uploading');
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove: (file) => {
      console.log('file: ', file);
    },
  };

  const createNewPaymentSlipData = async (fileUrl) => {
    const jsonBody = {
      consultantId: id,
      paymentSlipUrl: fileUrl,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    await axios
      .post(`${defaultURL}v1/paymentslips`, jsonBody, config)
      .then(() => {
        setLoadingAddPaymentSlip(false);
        setOpenAddNewPaymentSlip(false);
        setCreatedPaymentSlip(true);
        message.success('Created new payment slip data successfully.');
      })
      .catch((err) => {
        setLoadingAddPaymentSlip(false);
        setOpenAddNewPaymentSlip(false);
        message.error(`Failed to create new payment slip. ${err}`);
      });
  };

  const onFinishPaymentSlip = async (values) => {
    const { newPaymentSlip } = values;

    const data = new FormData();
    data.append('paymentSlip', newPaymentSlip.paymentSlipFile.fileList[0].originFileObj);

    const uploadFileConfig = {
      headers: {
        'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    await axios
      .post(`${defaultURL}v1/paymentSlips/uploadPaymentSlip`, data, uploadFileConfig)
      .then((res) => {
        createNewPaymentSlipData(res.data.Location);
      })
      .catch((err) => {
        message.error(`New Payment file upload failed. ${err}`);
      });
    setLoadingAddPaymentSlip(true);
  };

  //* download helper functions
  const getDownloadResume = async (filetype, fileLink) => axios
    .get(`v1/download?fileUrl=${fileLink}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => {
      setLoadingDownloadResume(false);
      return response.data;
    })
    .catch((e) => {
      setLoadingDownloadResume(false);
      message.error(`Failed to download ${filetype}. ${e}`);
    });
  const downloadResume = async (filetype, fileLink) => {
    setLoadingDownloadResume(true);
    getDownloadResume(filetype, fileLink).then((blob) => {
      saveAs(blob, `${consultantId}_${filetype}`);
    });
  };

  const getDownloadNRIC = async (filetype, fileLink) => axios
    .get(`v1/download?fileUrl=${fileLink}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => {
      setLoadingDownloadNRIC(false);
      return response.data;
    })
    .catch((e) => {
      setLoadingDownloadNRIC(false);
      message.error(`Failed to download ${filetype}. ${e}`);
    });
  const downloadNRIC = async (filetype, fileLink) => {
    setLoadingDownloadNRIC(true);
    getDownloadNRIC(filetype, fileLink).then((blob) => {
      saveAs(blob, `${consultantId}_${filetype}`);
    });
  };

  const getDownloadTranscript = async (filetype, fileLink) => axios
    .get(`v1/download?fileUrl=${fileLink}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => {
      setLoadingDownloadTranscript(false);
      return response.data;
    })
    .catch((e) => {
      setLoadingDownloadTranscript(false);
      message.error(`Failed t download ${filetype}. ${e}`);
    });
  const downloadTranscript = async (filetype, fileLink) => {
    setLoadingDownloadTranscript(true);
    getDownloadTranscript(filetype, fileLink).then((blob) => {
      saveAs(blob, `${consultantId}_${filetype}`);
    });
  };

  const getDownloadServiceAgreement = async (filetype, fileLink) => axios
    .get(`v1/download?fileUrl=${fileLink}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => {
      setLoadingDownloadServiceAgreement(false);
      return response.data;
    })
    .catch((e) => {
      setLoadingDownloadServiceAgreement(false);
      message.error(`Failed to download ${filetype}. ${e}`);
    });
  const downloadServiceAgreement = async (filetype, fileLink) => {
    setLoadingDownloadServiceAgreement(true);
    getDownloadServiceAgreement(filetype, fileLink).then((blob) => {
      saveAs(blob, `${consultantId}_${filetype}`);
    });
  };

  const getDownloadInsurancePolicy = async (filetype, fileLink) => axios
    .get(`v1/download?fileUrl=${fileLink}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => {
      setLoadingDownloadInsurancePolicy(false);
      return response.data;
    })
    .catch((e) => {
      setLoadingDownloadInsurancePolicy(false);
      message.error(`Failed to download ${filetype}. ${e}`);
    });
  const downloadInsurancePolicy = async (filetype, fileLink) => {
    setLoadingDownloadInsurancePolicy(true);
    getDownloadInsurancePolicy(filetype, fileLink).then((blob) => {
      saveAs(blob, `${consultantId}_${filetype}`);
    });
  };

  const getDownloadWorkPermit = async (filetype, fileLink) => axios
    .get(`v1/download?fileUrl=${fileLink}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => {
      setLoadingDownloadWorkPermit(false);
      return response.data;
    })
    .catch((e) => {
      setLoadingDownloadWorkPermit(false);
      message.error(`Failed to download ${filetype}. ${e}`);
    });
  const downloadWorkPermit = async (filetype, fileLink) => {
    setLoadingDownloadWorkPermit(true);
    getDownloadWorkPermit(filetype, fileLink).then((blob) => {
      saveAs(blob, `${consultantId}_${filetype}`);
    });
  };

  // const getDownloadRules = async (filetype, fileLink) => axios
  //   .get(`v1/download?fileUrl=${fileLink}`, {
  //     responseType: 'blob',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${token.access.token}`,
  //     },
  //   })
  //   .then((response) => {
  //     setLoadingDownloadHal20Rules(false);
  //     return response.data;
  //   })
  //   .catch((e) => {
  //     setLoadingDownloadHal20Rules(false);
  //     message.error(`Failed to download ${filetype}. ${e}`);
  //   });
  // const downloadRules = async (filetype, fileLink) => {
  //   setLoadingDownloadHal20Rules(true);
  //   getDownloadRules(filetype, fileLink).then((blob) => {
  //     saveAs(blob, `${consultantId}_${filetype}`);
  //   });
  // };

  const getDownloadPaymentSlipHistory = async (filetype, fileLink) => axios
    .get(`v1/download?fileUrl=${fileLink}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => {
      setLoadingDownloadPaymentSlipHistory(false);
      return response.data;
    })
    .catch((e) => {
      setLoadingDownloadPaymentSlipHistory(false);
      message.error(`Failed to download ${filetype}. ${e}`);
    });
  const downloadPaymentSlipHistory = async (filetype, fileLink) => {
    setLoadingDownloadPaymentSlipHistory(true);
    getDownloadPaymentSlipHistory(filetype, fileLink).then((blob) => {
      saveAs(blob, `${consultantId}_${filetype}`);
    });
  };

  const getDownloadTableInsurancePolicy = async (filetype, fileLink) => axios
    .get(`v1/download?fileUrl=${fileLink}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => {
      setLoadingDownloadTableInsurance(false);
      return response.data;
    })
    .catch((e) => {
      setLoadingDownloadTableInsurance(false);
      message.error(`Failed to download ${filetype}. ${e}`);
    });
  const downloadTableInsurancePolicy = async (filetype, fileLink) => {
    setLoadingDownloadTableInsurance(true);
    getDownloadTableInsurancePolicy(filetype, fileLink).then((blob) => {
      saveAs(blob, `${consultantId}_${filetype}`);
    });
  };

  //* View PDF helper functions
  const viewPDF = (title, fileLink) => {
    setShowPDFViewer(true);
    setViewModalTitle(title);
    setFileURL(fileLink);
  };

  //* Uplaod new doc
  const uploadNewTranscript = () => {
    setViewUploadNewTranscriptModal(true);
  };

  const handleCloseUploadNewTranscript = () => {
    setFileList([]);
    setViewUploadNewTranscriptModal(false);
  };

  //* Uplaod new IC/ Passport
  const uploadNewICPassport = () => {
    setViewUploadNewNRICPassporttModal(true);
  };

  const handleCloseUploadNewICPassport = () => {
    setFileList([]);
    setViewUploadNewNRICPassporttModal(false);
  };

  const uploadNewInsurance = () => {
    setViewUploadNewInsuranceModal(true);
  };

  const handleCloseUploadNewInsurance = () => {
    setFileList([]);
    setViewUploadNewInsuranceModal(false);
  };

  const uploadNewWorkPermit = () => {
    setViewUploadNewWorkPermiteModal(true);
  };

  const handleCloseUploadNewWorkPermit = () => {
    setFileList([]);
    setViewUploadNewWorkPermiteModal(false);
  };

  // const uploadNewHal20Rules = () => {
  //   setViewUploadNewHal20RulesModal(true);
  // };

  // const handleCloseUploadNewHal20Rules = () => {
  //   setHal20RulesFileList([]);
  //   setViewUploadNewHal20RulesModal(false);
  // };
  const paymentSlipHistoryColumns = [
    {
      title: 'Payment Slip',
      dataIndex: 'paymentSlipUrl',
      key: 'paymentSlipUrl',
      render: (_, data) => {
        const fileUrl = data.paymentSlipUrl.substring(data.paymentSlipUrl.lastIndexOf('/') + 1);
        const newfileUrl = decodeURI(fileUrl.replace(/\+/g, ' '));

        if (data.paymentSlipUrl) {
          return (
            <Typography.Link onClick={() => viewPDF('Payment Slip', data.paymentSlipUrl)}>
              <Typography.Text>{newfileUrl}</Typography.Text>
            </Typography.Link>
          );
        }
        return null;
      },
    },
    {
      title: 'Date Uploaded',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '18%',
      align: 'right',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (data) => (data ? moment(data).format('DD MMM YY') : '-'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '12%',
      align: 'center',
      fixed: 'right',
      render: (_, data) => {
        if (data.paymentSlipUrl) {
          return (
            <Button
              type="link"
              loading={loadingDownloadPaymentSlipHistory}
              style={{ color: '#f7931d' }}
              onClick={() => downloadPaymentSlipHistory('Payment Slip History', data.paymentSlipUrl)}>
              Download
            </Button>
          );
        }
        return null;
      },
    },
  ];

  const insurancePolicyHistoryColumns = [
    {
      title: 'Insurance Policy',
      dataIndex: 'insuranceUrl',
      key: 'insuranceUrl',
      render: (_, data) => {
        // todo: modify sunstring further
        const fileUrl = data.insuranceUrl.substring(data.insuranceUrl.lastIndexOf('/') + 1);
        const newfileUrl = decodeURI(fileUrl.replace(/\+/g, ' '));

        if (data.insuranceUrl) {
          return (
            <Typography.Link onClick={() => viewPDF('Payment Slip', data.insuranceUrl)}>
              <Typography.Text>{newfileUrl}</Typography.Text>
            </Typography.Link>
          );
        }
        return null;
      },
    },
    {
      title: 'Date Uploaded',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '18%',
      align: 'right',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (data) => (data ? moment(data).format('DD MMM YY') : '-'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '12%',
      align: 'center',
      fixed: 'right',
      render: (_, data) => {
        if (data.insuranceUrl) {
          return (
            <Button
              type="link"
              loading={loadingDownloadTableInsurance}
              style={{ color: '#f7931d' }}
              onClick={() => downloadTableInsurancePolicy('Insurance Policy', data.insuranceUrl)}>
              Download
            </Button>
          );
        }
        return null;
      },
    },
  ];

  // const UploadNewHal20RulesForm = () => (
  //   <Form
  //     form={hal20rules}
  //     layout="vertical"
  //     name="nest-messages"
  //     onFinish={onFinishUploadHal20Rules}
  //     validateMessages={validateMessages}
  //   >
  //     <Col>
  //       <Form.Item
  //         label="Upload New Documentation"
  //         name={['newHal20Rules', 'newHal20RulesFile']}
  //         rules={[{ required: true }]}
  //       >
  //         <Dragger
  //           showUploadList
  //           maxCount={1}
  //           accept=".pdf"
  //           {...uploadHal20RulesProps}
  //         >
  //           <p className="ant-upload-drag-icon">
  //             <InboxOutlined />
  //           </p>
  //           <p className="ant-upload-text">
  //             Click or drag file to this area to upload Hal 20 Rules (PDF only)
  //           </p>
  //           <p className="ant-upload-text" style={{ fontWeight: 700 }}>
  //             Please check to make sure correct file is uploaded
  //           </p>
  //           <p className="ant-upload-hint">Maximum file size is 5MB</p>
  //         </Dragger>
  //       </Form.Item>
  //     </Col>
  //   </Form>
  // );

  const UploadNewWorkPermitForm = () => (
    <Form
      form={workPermit}
      layout="vertical"
      name="nest-messages"
      onFinish={onFinishUploadWorkPermit}
      validateMessages={validateMessages}>
      <Col>
        <Form.Item
          label="Upload New Documentation"
          name={['newWorkPermit', 'newWorkPermitFile']}
          rules={[{ required: true }]}>
          <Dragger showUploadList maxCount={1} accept=".pdf" {...uploadWorkPermitProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload Work Permit (PDF only)
            </p>
            <p className="ant-upload-text" style={{ fontWeight: 700 }}>
              Please check to make sure correct file is uploaded
            </p>
            <p className="ant-upload-hint">Maximum file size is 5MB</p>
          </Dragger>
        </Form.Item>
      </Col>

      <Col className="gutter-row" span={8}>
        <Form.Item
          label="Work Permit Expiry Date"
          name={['newWorkPermit', 'workPermitExpiredDate']}
          rules={[{ required: true }]}>
          <RangePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>
    </Form>
  );

  // // todo: api integration
  // const UploadNewHal20Rules = () => (
  //   <Modal
  //     title="Upload New Hal 20 Rules"
  //     open={viewUploadNewHal20RulesModal}
  //     centered
  //     bodyStyle={{ overflowY: 'auto' }}
  //     width={800}
  //     onCancel={handleCloseUploadNewHal20Rules}
  //     footer={[
  //       <Button key="back" onClick={handleCloseUploadNewHal20Rules}>
  //         Cancel
  //       </Button>,
  //       <Button
  //         key="submit"
  //         type="primary"
  //         loading={loadingUploadNewWHal20Rules}
  //         disabled={hal20RulesFileList.length === 0}
  //         onClick={hal20rules.submit}
  //       >
  //         Save Changes
  //       </Button>,
  //     ]}
  //   >
  //     <UploadNewHal20RulesForm />
  //   </Modal>
  // );

  const UploadNewPopUp = () => (
    <Modal
      title={`Upload New ${uploadFileType}`}
      open={viewUploadNewWorkPermiteModal}
      centered
      bodyStyle={{ overflowY: 'auto' }}
      width={800}
      onCancel={handleCloseUploadNewWorkPermit}
      footer={[
        <Button key="back" onClick={handleCloseUploadNewWorkPermit}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loadingUploadNewWorkPermit}
          disabled={fileList.length === 0}
          onClick={workPermit.submit}>
          Save Changes
        </Button>,
      ]}>
      <UploadNewWorkPermitForm />
    </Modal>
  );

  const UploadNewTranscriptForm = () => (
    <Form
      form={transcript}
      layout="vertical"
      name="nest-messages"
      onFinish={onFinishUploadTranscript}
      validateMessages={validateMessages}>
      <Col>
        <Form.Item
          label="Upload New Documentation"
          name={['newTranscript', 'transcriptFile']}
          rules={[{ required: true }]}>
          <Dragger maxCount={1} accept=".pdf" {...uploadFileProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload Academic Transcript (PDF only)
            </p>
            <p className="ant-upload-text" style={{ fontWeight: 700 }}>
              Please check to make sure correct file is uploaded
            </p>
            <p className="ant-upload-hint">Maximum file size is 5MB</p>
          </Dragger>
        </Form.Item>
      </Col>
    </Form>
  );

  const UploadNewICPassportForm = () => (
    <Form
      form={ICPassport}
      layout="vertical"
      name="nest-messages"
      onFinish={onFinishUploadICPassport}
      validateMessages={validateMessages}>
      <Col>
        <Form.Item
          label="Upload New Documentation"
          name={['newICPassport', 'transcriptFile']}
          rules={[{ required: true }]}>
          <Dragger maxCount={1} accept=".pdf" {...uploadFileProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload IC/ passport image file (PDF only)
            </p>
            <p className="ant-upload-text" style={{ fontWeight: 700 }}>
              Please check to make sure correct file is uploaded
            </p>
            <p className="ant-upload-hint">Maximum file size is 5MB</p>
          </Dragger>
        </Form.Item>
      </Col>
    </Form>
  );

  const UploadNewInsurancePolicyForm = () => (
    <Form
      form={insurancePolicy}
      layout="vertical"
      name="nest-messages"
      onFinish={onFinishUploadInsurance}
      validateMessages={validateMessages}>
      <Col>
        <Form.Item
          label="Upload New Documentation"
          name={['newInsurance', 'insuranceFile']}
          rules={[{ required: true }]}>
          <Dragger maxCount={1} accept=".pdf" {...uploadFileProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload Insurance Policy (PDF only)
            </p>
            <p className="ant-upload-text" style={{ fontWeight: 700 }}>
              Please check to make sure correct file is uploaded
            </p>
            <p className="ant-upload-hint">Maximum file size is 5MB</p>
          </Dragger>
        </Form.Item>
      </Col>

      <Col className="gutter-row" span={8}>
        <Form.Item
          label="Insurance Expiry Date"
          name={['newInsurance', 'insuranceExpiredDate']}
          rules={[{ required: true }]}>
          <RangePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>
    </Form>
  );

  const UploadNewTranscript = () => (
    <Modal
      title={`Upload New ${uploadFileType}`}
      open={viewUploadNewTranscriptModal}
      centered
      bodyStyle={{ overflowY: 'auto', height: '45vh' }}
      width={800}
      onCancel={handleCloseUploadNewTranscript}
      footer={[
        <Button key="back" onClick={handleCloseUploadNewTranscript}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loadingUploadNewTranscript}
          disabled={fileList.length === 0}
          onClick={transcript.submit}>
          Save Changes
        </Button>,
      ]}>
      <UploadNewTranscriptForm />
    </Modal>
  );

  const UploadNewICPassport = () => (
    <Modal
      title={`Upload New ${uploadFileType}`}
      open={viewUploadNewNRICPassportModal}
      centered
      bodyStyle={{ overflowY: 'auto', height: '45vh' }}
      width={800}
      onCancel={handleCloseUploadNewICPassport}
      footer={[
        <Button key="back" onClick={handleCloseUploadNewICPassport}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loadingUploadNewNRICPassport}
          disabled={fileList.length === 0}
          onClick={ICPassport.submit}>
          Save Changes
        </Button>,
      ]}>
      <UploadNewICPassportForm />
    </Modal>
  );

  const UploadNewInsurancePolicy = () => (
    <Modal
      title={`Upload New ${uploadFileType}`}
      open={viewUploadNewInsuranceModal}
      centered
      bodyStyle={{ overflowY: 'auto', height: '60vh' }}
      width={800}
      onCancel={handleCloseUploadNewInsurance}
      footer={[
        <Button key="back" onClick={handleCloseUploadNewInsurance}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loadingUploadNewInsurancePolicy}
          disabled={fileList.length === 0}
          onClick={insurancePolicy.submit}>
          Save Changes
        </Button>,
      ]}>
      <UploadNewInsurancePolicyForm />
    </Modal>
  );

  const PDFViewer = () => (
    <Modal
      title={`${modalTitle}`}
      open={showPDFViewer}
      centered
      bodyStyle={{ overflowY: 'auto', height: '80vh' }}
      width={800}
      onCancel={() => setShowPDFViewer(false)}
      footer={null}>
      <iframe
        id="pdf-view"
        src={`${fileURL}#view=fitW`}
        title="pdfFileViewer"
        height={550}
        width="100%"
      />
    </Modal>
  );

  const InsurancePolicyHeader = () => (
    <div
      id="insurance-policy-header"
      style={{
        marginRight: '12px',
        marginBottom: '0',
        color: 'rgba(0, 0, 0, 0.85)',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '32px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        padding: '16px 0',
      }}>
      Insurance Policy History
    </div>
  );

  const InsurancePolicySection = () => (
    <div id="insurance-policy-section" ref={insurancePolicyHeader}>
      <InsurancePolicyHeader />
      <Table
        loading={loadingInsurancePolicyData}
        scroll={{ x: 'auto' }}
        columns={insurancePolicyHistoryColumns}
        dataSource={insurancePolicyData}
        pagination={{
          position: ['bottomRight'],
          total: insurancePolicyData.length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          showSizeChanger: true,
          defaultPageSize: 3,
          pageSizeOptions: ['3', '5', '10'],
        }}
      />
    </div>
  );

  const PaymentSlipHistoryHeader = () => (
    <PageHeader title="Payment Slip History" className="basic-pageheader" />
  );

  const onCloseAddNewPaymentSlip = () => {
    paymentSlipHistory.resetFields();
    setOpenAddNewPaymentSlip(false);
  };

  const AddNewButton = () => (
    <Button
      className="payslip-history-add-new"
      type="primary"
      onClick={() => setOpenAddNewPaymentSlip(true)}>
      Add New Payment Slip
    </Button>
  );

  const AddNewPaymentSlipForm = () => (
    <Form
      form={paymentSlipHistory}
      layout="vertical"
      onFinish={onFinishPaymentSlip}
      validateMessages={validateMessages}>
      <Form.Item
        label="Upload Payment Slip"
        name={['newPaymentSlip', 'paymentSlipFile']}
        rules={[{ required: true }]}>
        <Dragger maxCount={1} accept=".pdf" {...uploadPaymentSlip}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload Payment Slip History (PDF only)
          </p>
          <p className="ant-upload-text" style={{ fontWeight: 700 }}>
            Please check to make sure correct file is uploaded
          </p>
          <p className="ant-upload-hint">Maximum file size is 5MB</p>
        </Dragger>
        {/* <div style={{ paddingTop: 5 }}>{upploadedPaymentSlipFilename}</div> */}
      </Form.Item>
    </Form>
  );

  const AddNewPaymentSlipModal = () => (
    <Modal
      title="Add Payment Slip"
      centered
      open={openAddNewPaymentSlip}
      onCancel={onCloseAddNewPaymentSlip}
      width={800}
      footer={[
        <Button key="back" onClick={onCloseAddNewPaymentSlip}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={paymentSlipHistory.submit}
          loading={loadingAddPaymentSlip}>
          Save Changes
        </Button>,
      ]}>
      <AddNewPaymentSlipForm />
    </Modal>
  );

  const PaymentSlipHistorySection = () => (
    <>
      <Row className="payslip-history-header">
        <PaymentSlipHistoryHeader />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <AddNewButton />
        </div>
      </Row>
      <Table
        loading={loadingPaymentSlipData}
        scroll={{ x: 'auto' }}
        columns={paymentSlipHistoryColumns}
        dataSource={paymentSlipData}
        pagination={{
          position: ['bottomRight'],
          total: paymentSlipData.length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          showSizeChanger: true,
          defaultPageSize: 3,
          pageSizeOptions: ['3', '5', '10'],
        }}
      />
    </>
  );

  const TopSection = () => (
    <Descriptions
      labelStyle={{ maxWidth: '110px', fontWeight: '600', fontSize: '14px', lineHeight: '22px' }}
      contentStyle={{ fontWeight: '400' }}
      column={1}
      span={1}
      bordered>
      <Item label="Resume">
        <Row className="personal-details-form-data">
          <Typography.Link onClick={() => viewPDF('Resume', resumeURL)}>
            <Text>{resumeFilename || ''}</Text>
          </Typography.Link>
          {resumeFilename ? (
            <Button
              type="link"
              loading={loadingDownloadResume}
              style={{ color: '#f7931d' }}
              onClick={() => downloadResume('Resume', resumeURL)}>
              Download
            </Button>
          ) : null}
        </Row>
      </Item>

      <Item label="IC / Passport">
        <Row className="personal-details-form-data">
          <Typography.Link onClick={() => viewPDF('NRIC/ Passport', NRICURL)}>
            <Text>{NRICFilename || ''}</Text>
          </Typography.Link>
          <Col>
            <Typography.Link
              style={{ padding: '0 10px' }}
              onClick={() => {
                setUploadFileType('IC/ Passport');
                uploadNewICPassport();
              }}>
              Upload New
            </Typography.Link>
            {NRICFilename ? (
              <Button
                type="link"
                loading={loadingDownloadNRIC}
                style={{ color: '#f7931d' }}
                onClick={() => downloadNRIC('IC_Passport', NRICURL)}>
                Download
              </Button>
            ) : null}
          </Col>
        </Row>
      </Item>

      <Item label="Academic Transcript">
        <Row className="personal-details-form-data">
          <Typography.Link onClick={() => viewPDF('Transcript', TranscriptURL)}>
            <Text>{TranscriptFilename || ''}</Text>
          </Typography.Link>
          <Col>
            <Typography.Link
              style={{ padding: '0 10px' }}
              onClick={() => {
                setUploadFileType('Transcript');
                uploadNewTranscript();
              }}>
              Upload New
            </Typography.Link>
            {TranscriptFilename ? (
              <Button
                type="link"
                loading={loadingDownloadNTranscript}
                style={{ color: '#f7931d' }}
                onClick={() => downloadTranscript('Transcript', TranscriptURL)}>
                Download
              </Button>
            ) : null}
          </Col>
        </Row>
      </Item>

      {/* <Item label="Hal 20 Rules">
        <Row style={{ justifyContent: 'space-between' }}>
          <Typography.Link onClick={() => viewPDF('Hal 20 Rules', hal20RulesURL)}>
            {loadingConsultantData === false ? (
              <Text>{hal20RulesFilename || ''}</Text>
            ) : (
              <Text>Loading...</Text>
            )}
          </Typography.Link>
          <Col>
            <Typography.Link
              style={{ padding: '0 10px' }}
              onClick={() => {
                uploadNewHal20Rules();
              }}
            >
              Upload New
            </Typography.Link>
            {hal20RulesFilename ? (
              <Button
                type="link"
                style={{ color: '#f7931d' }}
                loading={loadingDownloadHal20Rules}
                onClick={() => downloadRules('Hal_20_Rules', hal20RulesURL)}
              >
                Download
              </Button>
            ) : null}
          </Col>
        </Row>
      </Item> */}

      <Item label="Service Agreement">
        <Row style={{ justifyContent: 'space-between' }}>
          <Typography.Link onClick={() => viewPDF('Service Agreement', serviceAgreementURL)}>
            <Text>{serviceAgreementFilename || ''}</Text>
          </Typography.Link>
          {loadingConsultantData === false && serviceAgreementFilename ? (
            <Button
              type="link"
              style={{ color: '#f7931d' }}
              loading={loadingDownloadServiceAgreement}
              onClick={() => downloadServiceAgreement('Service_Agreement', serviceAgreementURL)}>
              Download
            </Button>
          ) : null}
        </Row>
      </Item>

      <Item label="Insurance Policy">
        <Row className="personal-details-form-data">
          <Typography.Link onClick={() => viewPDF('Insurance Policy', InsurancePolicyURL)}>
            <Text>{InsurancePolicyFilename || ''}</Text>
          </Typography.Link>
          <Col>
            {InsurancePolicyFilename ? (
              <Typography.Link
                onClick={() => {
                  handleScroll();
                }}>
                View History
              </Typography.Link>
            ) : null}
            <Typography.Link
              style={{ padding: '0 10px' }}
              onClick={() => {
                setUploadFileType('Insurance');
                uploadNewInsurance();
              }}>
              Upload New
            </Typography.Link>
            {InsurancePolicyFilename ? (
              <Button
                type="link"
                loading={loadingDownloadInsurancePolicy}
                style={{ color: '#f7931d' }}
                onClick={() => downloadInsurancePolicy('Insurance_Policy', InsurancePolicyURL)}>
                Download
              </Button>
            ) : null}
          </Col>
        </Row>
      </Item>

      <Item label="Work Permit">
        <Row className="personal-details-form-data">
          <Typography.Link onClick={() => viewPDF('Work Permit', workPermitURL)}>
            <Text>{workPermitFilename || ''}</Text>
          </Typography.Link>
          <Col>
            <Typography.Link
              style={{ padding: '0 10px' }}
              onClick={() => {
                setUploadFileType('Work Permit');
                uploadNewWorkPermit();
              }}>
              Upload New
            </Typography.Link>
            {workPermitFilename ? (
              <Button
                type="link"
                loading={loadingDownloadWorkPermit}
                style={{ color: '#f7931d' }}
                onClick={() => downloadWorkPermit('Work_Permit', workPermitURL)}>
                Download
              </Button>
            ) : null}
          </Col>
        </Row>
      </Item>
    </Descriptions>
  );

  return (
    <>
      {loadingServiceAgreementData === false && loadingConsultantData === false ? (
        <TopSection />
      ) : (
        <Skeleton />
      )}
      <PaymentSlipHistorySection />
      <InsurancePolicySection />
      <PDFViewer />
      <UploadNewInsurancePolicy />
      <UploadNewTranscript />
      <UploadNewICPassport />
      {/* <UploadNewHal20Rules /> */}
      <UploadNewPopUp />
      <AddNewPaymentSlipModal />
    </>
  );
}
