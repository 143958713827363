import React, { useState, useEffect } from 'react';
import { Radio, Row, Col, message } from 'antd';

import axios from 'axios';

import '../../pages/dashboard/Dashboard.css';
import { getToken } from '../../utils/AuthService';

export default function ARStatistics() {
  const token = getToken();

  const [selected, setSelected] = useState('1');
  const [statisticsData, setStatisticsData] = useState({
    total_less_than_30: 0,
    total_more_than_30_less_than_90: 0,
    total_more_than_90: 0,
  });
  const [loadingDashboardARData, setLoadingDashboardARData] = useState(false);

  useEffect(() => {
    const fetchARData = async () => {
      setLoadingDashboardARData(true);
      await axios
        .get('v1/dashboard/ar-value', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((results) => {
          setStatisticsData(results.data);
        })
        .catch((error) => {
          message.error(`Failed to retrive dashboard AR data. ${error}`);
        });
    };

    fetchARData();
    setLoadingDashboardARData(false);
  }, []);

  const handleChangeData = (e) => {
    setSelected(e.target.value);
  };

  return (
    <div className="AR-statistics-contents">
      <Row className="AR-top-header">
        <Col className="dashboard-section-title">AR</Col>
        <Col>
          <Radio.Group
            onChange={handleChangeData}
            value={selected}
            style={{
              marginBottom: 8,
            }}
          >
            <Radio.Button value="1">1 - 30 Days</Radio.Button>
            <Radio.Button value="2">31 - 60 Days</Radio.Button>
            <Radio.Button value="3">{'>60 Days'}</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Row className="AR-contents">
        {loadingDashboardARData === false ? (
          selected === '1' ? (
            statisticsData.total_less_than_30 === 0 ? (
              0
            ) : statisticsData.total_less_than_30 >= 1000 ? (
              `${(statisticsData.total_less_than_30 / 1000).toFixed(3)} K`
            ) : statisticsData.total_less_than_30 >= 1000000 ? (
              `${(statisticsData.total_less_than_30 / 1000000).toFixed(3)} M`
            ) : (
              statisticsData.total_less_than_30.toFixed(2)
            )
          ) : selected === '2' ? (
            statisticsData.total_more_than_30_less_than_90 === 0 ? (
              0
            ) : statisticsData.total_more_than_30_less_than_90 >= 1000 ? (
              `${(statisticsData.total_more_than_30_less_than_90 / 1000).toFixed(3)} K`
            ) : statisticsData.total_more_than_30_less_than_90 >= 1000000 ? (
              `${(statisticsData.total_more_than_30_less_than_90 / 1000000).toFixed(3)} M`
            ) : (
              statisticsData.total_more_than_30_less_than_90.toFixed(2)
            )
          ) : selected === '3' ? (
            statisticsData.total_more_than_90 === 0 ? (
              0
            ) : statisticsData.total_more_than_90 >= 1000 ? (
              `${(statisticsData.total_more_than_90 / 1000).toFixed(3)} K`
            ) : statisticsData.total_more_than_90 >= 1000000 ? (
              `${(statisticsData.total_more_than_90 / 1000000).toFixed(3)} M`
            ) : (
              statisticsData.total_more_than_90.toFixed(2)
            )
          ) : (
            ''
          )
        ) : (
          <p>Loading...</p>
        )}
      </Row>
    </div>
  );
}
