import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import axios from 'axios';
import queryString from 'query-string';

import {
  PageHeader,
  Breadcrumb,
  Input,
  Row,
  Skeleton,
  Typography,
  message,
  Card,
  Col,
  Layout,
  Button,
  DatePicker,
  Form,
  Tooltip,
} from 'antd';

import TableInvoiceList from '../../components/invoiceList/TableInvoiceList';
import { getToken } from '../../utils/AuthService';

const { Link } = Typography;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Item } = Form;

export default function InvoiceList() {
  const token = getToken();
  const [searchForm] = Form.useForm();

  const { search } = useLocation();
  const queryObj = queryString.parse(search);
  const { invoiceNumber } = queryObj;

  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceUpdated, setInvoiceUpdated] = useState(false);
  const [loading, setLoading] = useState(true);

  const [searchCompany, setSearchCompany] = useState('');
  const [searchPSL, setSearchPSL] = useState('');
  const [searchInvoiceDate, setSearchInvoiceDate] = useState('');
  const [searchStartInvoiceNumber, setSearchStartInvoiceNumber] = useState('');
  const [searchEndInvoiceNumber, setSearchEndInvoiceNumber] = useState('');
  const [showReset, setShowReset] = useState(false);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get('v1/invoices', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            isHistory: false,
            invoiceNumber,
            company: searchCompany,
            psl: searchPSL,
            startDate: searchInvoiceDate !== '' ? new Date(searchInvoiceDate[0]) : '',
            endDate: searchInvoiceDate !== '' ? new Date(searchInvoiceDate[1]) : '',
            startInvoiceNumber: searchStartInvoiceNumber,
            endInvoiceNumber: searchEndInvoiceNumber,
            limit: 999999, // todo: fix
            sortBy: 'updatedAt:desc',
            // page: 1,
          },
        })
        .then((res) => {
          setInvoiceData(res.data);
          setLoading(false);

          searchForm.resetFields();
        })
        .catch((error) => {
          message.error(`Failed to retrive invoice data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, [
    invoiceUpdated,
    invoiceNumber,
    searchCompany,
    searchInvoiceDate,
    searchStartInvoiceNumber,
    searchEndInvoiceNumber,
    searchPSL,
  ]);

  const handleUpdateInvoices = () => {
    setInvoiceUpdated((prevValue) => !prevValue);
  };

  const onSearch = () => {
    searchForm
      .validateFields()
      .then((res) => {
        const { searchInvoice } = res;
        const {
          company, 
          psl, 
          invoiceDate, 
          startInvoiceNumber, 
          endInvoiceNumber, 
        } = searchInvoice;

        setSearchCompany(company);
        setSearchPSL(psl);
        setSearchInvoiceDate(invoiceDate);
        setSearchStartInvoiceNumber(startInvoiceNumber);
        setSearchEndInvoiceNumber(endInvoiceNumber);
        setShowReset(true);
        searchForm.resetFields();
      })
      .catch(() => {});
  };

  const onResetSearch = () => {
    setShowReset(false);
    setSearchCompany('');
    setSearchPSL('');
    setSearchInvoiceDate('');
    setSearchStartInvoiceNumber('');
    setSearchEndInvoiceNumber('');
    searchForm.resetFields();
  };

  const SearchBar = () => (
    <Form layout="horizontal" form={searchForm}>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 20,
        }}>
        <Col span={12}>
          <Item name={['searchInvoice', 'company']} initialValue={searchCompany}>
            <div className="header-section-search-bar-section">
              Company
              <Input defaultValue={searchCompany} />
            </div>
          </Item>
        </Col>
        <Col span={12}>
          <div className="header-section-search-bar-section">Invoice Number</div>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 20,
            }}>
            <Col span={11}>
              <Item
                name={['searchInvoice', 'startInvoiceNumber']}
                initialValue={searchStartInvoiceNumber}>
                <Input placeholder="eg. 003" />
              </Item>
            </Col>
            <p style={{ paddingLeft: 10, paddingRight: 10 }}> - </p>
            <Col span={11}>
              <Item
                name={['searchInvoice', 'endInvoiceNumber']}
                initialValue={searchEndInvoiceNumber}>
                <Input placeholder="eg. 005" />
              </Item>
            </Col>
          </Row>
        </Col>        
      </Row>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 20,
        }}>
        <Col span={10}>
          <Item name={['searchInvoice', 'psl']} initialValue={searchPSL}>
            <div className="header-section-search-bar-section">
              PSL
              <Input defaultValue={searchPSL} />
            </div>
          </Item>
        </Col>
        <Col span={14}>
          <div className="header-section-search-bar-section">Invoice Date</div>
          <Item name={['searchInvoice', 'invoiceDate']} initialValue={searchInvoiceDate}>
            <RangePicker style={{ width: '100%' }} />
          </Item>
        </Col>
      </Row>
    </Form>
  );
  const ApplyFilterButton = () => (
    <Button type="primary" key="submit" onClick={onSearch}>
      Apply
    </Button>
  );
  const ResetButton = () => (
    <Tooltip title="Reset Filters">
      <Button type="danger" disabled={!showReset} onClick={onResetSearch}>
        Reset
      </Button>
    </Tooltip>
  );

  const TableInvoice = () => (
    <div className="table-content">
      {loading === false ? TableInvoiceList(invoiceData, handleUpdateInvoices) : <Skeleton />}
    </div>
  );

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/invoice-list">Invoice List </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row style={{ justifyContent: 'space-between' }}>
        <PageHeader title="Invoice List" className="basic-pageheader" />
      </Row>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <div className="header-section">
          <Card className="header-section-card">
            <SearchBar />
            <Row className="apply-filter-button">
              {showReset === true ? (
                <Col className="vertical-align-center-button">
                  <Col>
                    <ApplyFilterButton />
                  </Col>
                  <Col className="right-positioned-button">
                    <ResetButton />
                  </Col>
                </Col>
              ) : (
                <Col className="vertical-align-center-button">
                  <ApplyFilterButton />
                </Col>
              )}
            </Row>
          </Card>
        </div>
        <TableInvoice />
      </Content>
    </>
  );
}
