import React from 'react';
import { Link } from 'react-router-dom';

import { Space, Divider, Button, Table, message } from 'antd';

import moment from 'moment';
import axios from 'axios';
import { saveAs } from 'file-saver';

import { getToken } from '../../utils/AuthService';

export default function TableCallOutManagement(callOutData) {
  const token = getToken();

  const getDownloadCalloutFile = async (fileURL) => axios
    .get(`v1/download?fileUrl=${fileURL}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => response.data)
    .catch((e) => {
      message.error(`Failed to download call out form. ${e}`);
    });

  const downloadForm = async (consultantId, fileURL) => {
    getDownloadCalloutFile(fileURL).then((blob) => {
      saveAs(blob, `${consultantId}_Call_Out_Form`);
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'consutltantName',
      key: 'consutltantName',
      width: '18%',
      sorter: (a, b) => a.consutltantName.localeCompare(b.consutltantName),
      render: (val) => {
        if (val) {
          return val.toUpperCase();
        }
        return '-';
      },
    },
    {
      title: 'Effective Date',
      dataIndex: 'effectiveDate',
      key: 'effective-date',
      width: '15%',
      align: 'right',
      sorter: (a, b) => moment(a.effectiveDate).unix() - moment(b.effectiveDate).unix(),
      render: (data) => (data ? moment(data).format('DD/MM/YYYY') : '-'),
    },
    {
      title: 'Completion Date',
      dataIndex: 'completionDate',
      key: 'completion-date',
      width: '16%',
      align: 'right',
      sorter: (a, b) => moment(a.completionDate).unix() - moment(b.completionDate).unix(),
      render: (data) => (data ? moment(data).format('DD/MM/YYYY') : '-'),
    },
    {
      title: 'PSL',
      key: 'psl',
      dataIndex: 'psl',
      width: '15%',
      align: 'justify',
      sorter: (a, b) => a.pslId.psl.localeCompare(b.pslId.psl),
      render: (data) => {
        if (data.psl) {
          return data.psl.toUpperCase();
        }
        return '-';
      },
    },
    {
      title: 'Monthly',
      key: 'position',
      dataIndex: 'position',
      width: '10%',
      render: (data) => {
        let val;

        switch (data[0].isMonthly) {
          case true:
            val = 'YES';
            break;
          case false:
            val = 'NO';
            break;
          default:
            val = '-';
            break;
        }

        return val;
      },
    },
    {
      title: 'Applied Tax',
      key: 'tax',
      dataIndex: 'isTax',
      width: '10%',
      render: (data) => {
        let val;

        switch (data) {
          case true:
            val = 'YES';
            break;
          case false:
            val = 'NO';
            break;
          default:
            val = '-';
            break;
        }
        return val;
      },
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
      align: 'right',
      render: (data) => (data ? moment(data).format('DD/MM/YYYY') : '-'),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      fixed: 'right',
      render: (_, data) => {
        const { _id, calloutFormUrl, consultant, contractUrl } = data;

        if (calloutFormUrl) {
          if (consultant.name.includes('/')) {
            const name = consultant.name.replace('/', 'a');
            return (
              <Space split={<Divider type="vertical" />}>
                <Link
                  to={{
                    pathname: `/call-out-management/detail/${name}`,
                    state: {
                      id: _id,
                      calloutFormURL: calloutFormUrl,
                      serviceAgreementURL: contractUrl,
                    },
                  }}>
                  Details
                </Link>
                <Button
                  type="link"
                  style={{ color: '#f7931d' }}
                  onClick={() => downloadForm(_id, calloutFormUrl)}>
                  Download Call Out Form
                </Button>
              </Space>
            );
          }
          return (
            <Space split={<Divider type="vertical" />}>
              <Link
                to={{
                  pathname: `/call-out-management/detail/${consultant.name}`,
                  state: {
                    id: _id,
                    calloutFormURL: calloutFormUrl,
                    serviceAgreementURL: contractUrl,
                  },
                }}>
                Details
              </Link>
              <Button
                type="link"
                style={{ color: '#f7931d' }}
                onClick={() => downloadForm(_id, calloutFormUrl)}>
                Download Call Out Form
              </Button>
            </Space>
          );
        }
        if (consultant.name.includes('/')) {
          const name = consultant.name.replaces('/', 'a');
          return (
            <Space split={<Divider type="vertical" />}>
              <Link
                to={{
                  pathname: `/call-out-management/detail/${name}`,
                  state: {
                    id: _id,
                    calloutFormURL: calloutFormUrl,
                    serviceAgreementURL: contractUrl,
                  },
                }}>
                Details
              </Link>
            </Space>
          );
        }
        return (
          <Space split={<Divider type="vertical" />}>
            <Link
              to={{
                pathname: `/call-out-management/detail/${consultant.name}`,
                state: {
                  id: _id,
                  calloutFormURL: calloutFormUrl,
                  serviceAgreementURL: contractUrl,
                },
              }}>
              Details
            </Link>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      scroll={{ x: 'auto' }}
      columns={columns}
      dataSource={callOutData.results}
      pagination={{
        position: ['bottomRight'],
        total: callOutData.totalResults,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        defaultCurrent: 1,
        showSizeChanger: true,
        defaultPageSize: 5,
        pageSizeOptions: ['5', '10'],
      }}
    />
  );
}
