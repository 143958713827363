import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, PageHeader } from 'antd';

import InvoiceForm from '../../components/invoiceList/InvoiceForm';

export default function RunInvoice() {
  const BreadCrumbDiv = () => (
    <>
      <div className="breadcrumb-layout">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/invoice-list">Invoice List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Run Invoice</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title="Run Invoice" className="basic-pageheader" />
      </div>
    </>
  );

  return (
    <>
      <BreadCrumbDiv />
      <div className="content-area">{InvoiceForm('Add')}</div>
    </>
  );
}
