import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.less';
import { Form, Input, Button, Col, Row, Select, Radio, DatePicker, message } from 'antd';
import axios from 'axios';

import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import countriesList from '../../assets/file/countriesList.json';
import countryCodes from '../../assets/file/countryCodes.json';

const PersonalDetailsForm = (props) => {
  const [form] = Form.useForm();

  const { stepChangeHandler } = props;
  const { Option } = Select;
  const { Item } = Form;

  const [country, setCountry] = useState('+60');
  const [nationality, setNationality] = useState('');

  useEffect(() => {
    if (localStorage.getItem('personalDetails')) {
      const personalDetails = JSON.parse(localStorage.getItem('personalDetails'));
      setCountry(personalDetails.country || '+60');
      setNationality(personalDetails.nationality);
      form.setFieldsValue({
        employeeName: personalDetails.employeeName,
        nationality: personalDetails.nationality,
        nricOrPassport: personalDetails.nricOrPassport,
        gender: personalDetails.gender,
        dateOfBirth: moment(personalDetails.dateOfBirth),
        email: personalDetails.email,
        maritalStatus: personalDetails.maritalStatus,
        address: personalDetails.address,
        contactNumberHome: personalDetails.contactNumberHome,
        country: personalDetails.country || '+60',
        contactNumberPhone: personalDetails.contactNumberPhone,
      });
    }
  }, []);

  const saveToLocalStorage = () => {
    localStorage.setItem('personalDetails', JSON.stringify(form.getFieldsValue()));
    message.success('Personal Details saved successfully');
  };

  const prefixSelector = (
    <Form.Item name="country" noStyle initialValue={country}>
      <Select
        style={{
          width: 170,
        }}
        defaultValue={country}
        onChange={(value) => setCountry(value)}
        showArrow={false}
      >
        {countryCodes.map((val) => (
          <Option key={val.dial_code} value={val.dial_code}>
            {val.name} ({val.dial_code})
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  return (
    <Form
      form={form}
      name="register"
      layout="vertical"
      validateMessages={{
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      }}>
      <div
        style={{
          background: '#EEEEEE',
          padding: '30px',
        }}>
        <h2>Personal Details</h2>
        <Row>
          <Col span={24}>
            <Item
              name="employeeName"
              label="Employee Name"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input placeholder="Employee Name" onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }} />
            </Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={8}>
            <Row>
              <Col span={24}>
                <Item
                  name="nationality"
                  label="Nationality"
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <Select
                    style={{
                      width: '100%',
                    }}
                    showSearch
                    placeholder="Please select"
                    optionFilterProp="children"
                    onChange={(val) => {
                      // console.log(val);
                      setNationality(val);
                    }}
                    filterOption={countriesList}>
                    {countriesList.map((val) => (
                      <Option key={val} value={val}>
                        {val}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            </Row>
          </Col>
          <Col flex="auto">
            <Item
              name="nricOrPassport"
              label={nationality ? nationality === 'Malaysia' ? 'NRIC' : 'Passport' : 'NRIC/Passport'}
              rules={[
                {
                  required: true,
                },
                {
                  pattern: nationality === 'Malaysia' ? /^(\d){6}(\d){2}(\d){4}$/ : /^([A-Z0-9]){7,14}$/,
                  message: 'Please enter a valid NRIC/Passport',
                },
              ]}>
              <Input
                // formatter={(value) => {
                //   console.log(value);
                //   // if (value.length === 12) {
                //   //   return `${value}`.replace(/(\d{6})\-?(\d{2})\-?(\d{4})/, '$1-$2-$3');
                //   // }
                //   return `${value}`.replace(/\D/g, '');
                // }}
                // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                // onKeyPress={(e) => {
                //   console.log(e);
                //   return e.charCode >= 48 && e.charCode <= 57;
                // }}
                placeholder="NRIC/Passport"
                style={{ width: '100%' }}
              />
            </Item>
          </Col>
          <Col>
            <Item
              name="gender"
              label="Gender"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Radio.Group>
                <Row>
                  <Col span={12}>
                    <Radio.Button
                      value="Male"
                      style={{
                        textAlign: 'center',
                        width: '100%',
                      }}>
                      Male
                    </Radio.Button>
                  </Col>
                  <Col span={12}>
                    <Radio.Button
                      value="Female"
                      style={{
                        textAlign: 'center',
                      }}>
                      Female
                    </Radio.Button>
                  </Col>
                </Row>
              </Radio.Group>
            </Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={8}>
            <Item label="Date of Birth" name="dateOfBirth" rules={[{ required: true }]} hasFeedback>
              <DatePicker
                disabledDate={(current) => current && current > Date.now()}
                style={{
                  width: '100%',
                }}
              />
            </Item>
          </Col>
          <Col xs={24} sm={8}>
            <Item
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                },
                {
                  required: true,
                },
              ]}>
              <Input placeholder="Email" />
            </Item>
          </Col>
          <Col xs={24} sm={8}>
            <Item label="Marital Status" name="maritalStatus" rules={[{ required: true }]}>
              <Select placeholder="Please select">
                <Option value="Single">Single</Option>
                <Option value="Married">Married</Option>
                <Option value="Divorced">Divorced</Option>
                <Option value="Widowed">Widowed</Option>
              </Select>
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Item label="Address" name="address" rules={[{ required: true }]}>
              <TextArea
                autoSize={{
                  minRows: 3,
                }}
                maxLength={1000}
                showCount
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={12}>
            <Item
              label="Contact Number (Home)"
              name="contactNumberHome"
              // validate international phone number
              rules={[
                {
                  pattern: /^[0-9]+$/,
                  message: 'Please enter a valid phone number',
                },
              ]}>
              <Input />
            </Item>
          </Col>
          <Col xs={24} sm={12}>
            <Item
              label="Contact Number (Phone)"
              name="contactNumberPhone"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[0-9]{4,14}(?:x.+)?$/,
                  message: 'Please enter a valid phone number',
                },
              ]}>
              <Input addonBefore={prefixSelector} placeholder="123456789" />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Item>
              <Button type="secondary" onClick={saveToLocalStorage}>
                Save changes
              </Button>
            </Item>
          </Col>
          <Col flex="auto" />
          <Col>
            <Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  form.validateFields().then(async (values) => {
                    // console.log(values);
                    const config = {
                      url: 'v1/consultants/checkConsultantExist',
                      params: {
                        nric: values.nricOrPassport,
                      },
                    };

                    await axios(config).then((res) => {
                      if (res.data) {
                        message.error(`Consultant with NRIC: ${values.nricOrPassport} already exist!`);
                      } else {
                        saveToLocalStorage();
                        stepChangeHandler(1);
                      }
                    }).catch((err) => {
                      console.log(err);
                    });
                  });
                }}>
                Next
              </Button>
            </Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default PersonalDetailsForm;
