import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import {
  PageHeader,
  Breadcrumb,
  Col,
  Tabs,
  Row,
  Button,
  Layout,
  Table,
  Input,
  Modal,
  Form,
  DatePicker,
  Select,
  message,
  Card,
  Typography,
  Tooltip,
  Upload,
  Spin,
} from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';

import axios from 'axios';
import moment from 'moment';
import Cookie from 'js-cookie';
import queryString from 'query-string';

import PersonalDetailConsultantDetails from '../../components/consultantManagement/PersonalDetailConsultantDetails';
import WorkInformationDetails from '../../components/consultantManagement/WorkInformationDetails';
import DonwloadsSection from '../../components/consultantManagement/DownloadsSection';
import NationalitiesList from '../../assets/file/nationalitiesList.json';
import './ConsultantDetails.css';
import '../../styles/general.css';
import RFPHistoryTable from '../../components/consultantManagement/RFPHistoryTable';
import { getToken } from '../../utils/AuthService';
import { capitalizedFirstAlphabet } from '../../utils/capitalizedFirstAlphabet';

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Item } = Form;
const { Text } = Typography;

const ConsultantDetails = () => {
  const { search } = useLocation();
  const history = useHistory();

  const searchObj = queryString.parse(search);
  const { id, name, tab } = searchObj;

  const currTab = Cookie.get('selectedTab') || tab;

  const token = getToken();
  const [editWorkForm] = Form.useForm();
  const [editPersonalForm] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [selectedTab, setSelectedTab] = useState(currTab);
  const [loadingUpdateWorkData, setLoadingUpdateWorkData] = useState(false);
  const [openEditWork, setOpenEditWork] = useState(false);
  const [openEditPersonal, setOpenEditPersonal] = useState(false);
  const [loadingEditPersonal, setLoadingEditPersonal] = useState(false);

  const [loadingWorkInformationData, setLoadingWorkInformationData] = useState(false);
  const [workContractData, setWorkContractData] = useState([]);
  const [workConsultantData, setWorkConsultantData] = useState([]);
  const [workConsultantName, setWorkConsultantName] = useState([]);
  const [workConsultantBankName, setWorkConsultantBankName] = useState([]);
  const [workConsultantStatus, setWorkConsultantStatus] = useState([]);

  const [workContractPositionData, setWorkContractPositionData] = useState([]);
  const [workContractPSLData, setWorkContractPSLData] = useState([]);
  const [workContractContractExpiryDates, setWorkContractContractExpiryDates] = useState([]);
  const [workInvoicingSchedule, setWorkInvoicingSchedule] = useState([]);
  const [pagination, setPagination] = useState({
    position: ['bottomRight'],
    total: 0,
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '50', '100'],
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });

  const [workHistoryAPIURL, setWorkHistoryAPIURL] = useState(
    `v1/consultants/${id}/getConsultantWorkHistory`,
  );
  const [loadingWorkHistoryData, setLoadingWorkHistoryData] = useState([]);
  const [workHistoryData, setWorkHistoryData] = useState([]);
  const [workHistoryPagination, setWorkHistoryPagination] = useState({
    position: ['bottomRight'],
    total: 0,
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '50', '100'],
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
  });
  const [showReset, setShowReset] = useState(false);
  const [searchAPOAContractNo, setSearchAPOAContractNo] = useState('');

  const [updatedConsultantWork, setUpdatedConsultantWork] = useState([]);
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [modalTitle, setViewModalTitle] = useState([]);
  const [fileURL, setFileURL] = useState('');

  const [consultantData, setConsultantData] = useState([]);
  const [
    ,
    // loadingPersonalInformation
    setLoadingPersonalInformation,
  ] = useState(false);
  const [updatedConsultantPersonal, setUpdatedConsultantPersonal] = useState([]);
  const [uploadedNRICURL, setUploadedNRICURL] = useState('');

  const defaultURL = process.env.REACT_APP_NODE_ENV === 'PRODUCTION'
    ? process.env.REACT_APP_PRODUCTION_URL : process.env.REACT_APP_NODE_ENV === 'STAGING'
      ? process.env.REACT_APP_SANDBOX_URL : process.env.REACT_APP_LOCAL_URL;

  useEffect(() => {
    const fetchSpecificConsultantData = async () => {
      setLoadingWorkInformationData(true);
      await axios
        .get(`v1/consultants/${id}/getConsultantWorkInformation`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((results) => {
          setPagination({
            ...pagination,
            total: results.totalResults,
          });

          if (results.data.consultant) {
            setWorkConsultantData(results.data.consultant);
            setWorkConsultantName(results.data.consultant.name);
            setWorkConsultantBankName(results.data.consultant.bankName);
            setWorkConsultantStatus(results.data.consultant.status);
          }

          let positionArr = [];
          let pslArr = [];
          let contractExpiredDates = [];
          const invoicingScheduleArr = [];

          if (results.data.contracts) {
            setWorkContractData(results.data.contracts);

            results.data.contracts.map((e) => {
              positionArr.push(e.position[0].mandayRateSet);
            });
            positionArr = positionArr.slice(-2);
            const positions = positionArr.map((value) => value).join(', ');
            setWorkContractPositionData(positions);

            results.data.contracts.map((e) => {
              pslArr.push(e.pslId.psl);
            });
            pslArr = pslArr.slice(-2);
            setWorkContractPSLData(pslArr);

            results.data.contracts.map((e) => {
              contractExpiredDates.push(e.completionDate);
            });
            contractExpiredDates = contractExpiredDates.slice(-2);
            setWorkContractContractExpiryDates(contractExpiredDates);

            results.data.contracts.map((e) => {
              if (e.invoicingSchedule !== null) {
                invoicingScheduleArr.push({ id: e.id, data: e.invoicingSchedule });
              }
            });
            setWorkInvoicingSchedule(invoicingScheduleArr);
          }
        })
        .catch((error) => {
          message.error(`Failed to retrive work information data. ${error}`);
        });
    };

    fetchSpecificConsultantData();
    setLoadingWorkInformationData(false);
  }, [updatedConsultantWork, updatedConsultantPersonal]);

  useEffect(() => {
    const fetchWorkHistoryData = async () => {
      setLoadingWorkHistoryData(true);
      await axios
        .get(workHistoryAPIURL, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((results) => {
          setWorkHistoryPagination({
            ...workHistoryPagination,
            total: results.totalResults,
          });
          setWorkHistoryData(results.data);
        })
        .catch((error) => {
          message.error(`Failed to retrive work history data. ${error}`);
        });
    };

    fetchWorkHistoryData();
    setLoadingWorkHistoryData(false);
  }, [workHistoryAPIURL]);

  useEffect(() => {
    const fetchPersonalInformationData = async () => {
      setLoadingPersonalInformation(true);
      await axios
        .get(`v1/consultants/${id}/getPersonalInformation`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((results) => {
          setConsultantData(results.data.consultant);
        })
        .catch((error) => {
          message.error(`Failed to retrive personal information data. ${error}`);
        });
    };

    fetchPersonalInformationData();
    setLoadingPersonalInformation(false);
  }, []);

  const validateMessages = {
    required: '${label} is required!',
  };

  const uploadICProps = {
    name: 'file',
    action: `${defaultURL}v1/consultants/${id}/uploadDocs?type=nric`,
    maxCount: 1,
    beforeUpload(file) {
      // todo: check again
      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);

      if (sizeInMB > 5) {
        message.error(`${file.name} exceeds 5MB`);
      }
      return sizeInMB || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove: (file) => {
      console.log('file: ', file);
    },
    customRequest: (options) => {
      const data = new FormData();
      data.append('docs', options.file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        },
      };
      axios
        .post(options.action, data, config)
        .then((res) => {
          setUploadedNRICURL(res.data.location[0]);
          message.success(`${options.file.name} file uploaded successfully.`);
        })
        .catch((err) => {
          message.error(`${options.file.name} file upload failed. ${err}`);
        });
    },
  };

  const onApproveConsultant = async () => {
    const jsonBody = {
      status: 'inactive',
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    await axios
      .put(`v1/consultants/${id}/update`, jsonBody, config)
      .then((results) => {
        setUpdatedConsultantWork(results.data);
        message.success('Successfully approved consultant!');
      })
      .catch((error) => {
        message.error(`Failed to update consultant status. ${error}`);
      });
  };

  // work information edit
  const onShowEditWorkInfo = () => {
    setOpenEditWork(true);
  };
  const handleCancelEditWork = () => {
    setOpenEditWork(false);
  };
  const onFinishEditWorkInfo = async (values) => {
    const updatedWorkData = values.work;
    const newAgreementSigned = updatedWorkData.agreementSigned === 'yes';
    const newRulesSigned = updatedWorkData.rulesSigned === 'yes';

    setOpenEditWork(false);
    setLoadingUpdateWorkData(true);
    const jsonBody = {
      name: updatedWorkData.name,
      nric: updatedWorkData.ICPassport,
      dateOfBirth: updatedWorkData.DOB,
      contactPhone: updatedWorkData.personalContactNo,
      email: updatedWorkData.email,
      agreementSigned: newAgreementSigned,
      rulesSigned: newRulesSigned,
      bankName: updatedWorkData.bankName,
      accountName: updatedWorkData.accountName,
      accountNumber: updatedWorkData.accountNo,
      epfNumber: updatedWorkData.EPFNo,
      socsoNumber: updatedWorkData.socsoNo,
      pcbNumber: updatedWorkData.pcbNo,
      insuranceStart: updatedWorkData.insuranceStartDate === null ? '' : updatedWorkData.insuranceStartDate,
      insuranceEnd: updatedWorkData.insuranceEndDate === null ? '' : updatedWorkData.insuranceEndDate,
      endorsementNote: updatedWorkData.endorsementNote,
      remarks: updatedWorkData.remarks,
      nricUrl: uploadedNRICURL,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    await axios
      .put(`v1/consultants/${id}/update`, jsonBody, config)
      .then((results) => {
        setUpdatedConsultantWork(results.data);
        message.success('Successfully updated consultant work data!');
      })
      .catch((error) => {
        message.error(`Failed to update consultant work data. ${error}`);
      });
    setLoadingUpdateWorkData(false);
  };

  const onShowEditPersonal = () => {
    setOpenEditPersonal(true);
  };
  const handleCancelEditPersonal = () => {
    setOpenEditPersonal(false);
  };
  // personal info edit
  const onFinishEditPersonalInfo = async (values) => {
    const updatedPersonalData = values.personal;

    setOpenEditPersonal(false);
    setLoadingEditPersonal(true);

    const jsonBody = {
      name: updatedPersonalData.name,
      nric: updatedPersonalData.ICPassportNo,
      email: updatedPersonalData.email,
      agreementSigned: false,
      rulesSigned: false,
      nationality: updatedPersonalData.nationality,
      gender: updatedPersonalData.gender,
      maritalStatus: updatedPersonalData.maritalStatus,
      address: updatedPersonalData.address,
      contactHome: updatedPersonalData.homeContactNo,
      contactPhone: updatedPersonalData.phoneContactNo,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    await axios
      .put(`v1/consultants/${id}/update`, jsonBody, config)
      .then((results) => {
        setUpdatedConsultantPersonal(results.data);
        message.success('Successfully updated consultant personal data!');
      })
      .catch((error) => {
        message.error(`Failed to update consultant personal data. ${error}`);
      });
    setLoadingEditPersonal(false);
  };

  const viewWorkPermit = (workPermitURL) => {
    setShowPDFViewer(true);
    setViewModalTitle('Work Permit');
    setFileURL(workPermitURL);
  };

  const viewInsurance = (insuranceURL) => {
    setShowPDFViewer(true);
    setViewModalTitle('Insurance');
    setFileURL(insuranceURL);
  };

  const viewCallOutForm = (project, contractNum, callOutFormURL) => {
    setShowPDFViewer(true);
    setViewModalTitle(`${project} project (Contract No: ${contractNum})`);
    setFileURL(callOutFormURL);
  };

  const WorkHistoryColumns = [
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      width: '13%',
      render: (_, data) => {
        let positions;

        if (data.position !== 0) {
          positions = data.position.map((value) => value.mandayRateSet.toUpperCase()).join(', ');
        } else {
          positions = '';
        }

        return positions;
      },
    },
    {
      title: 'From',
      dataIndex: 'effectiveDate',
      key: 'effectiveDate',
      width: '12%',
      align: 'right',
      sorter: (a, b) => moment(a.effectiveDate).unix() - moment(b.effectiveDate).unix(),
      render: (data) => (data ? moment(data).format('DD/MM/YYYY') : '-'),
    },
    {
      title: 'To',
      dataIndex: 'completionDate',
      key: 'completionDate',
      width: '12%',
      align: 'right',
      sorter: (a, b) => moment(a.completionDate).unix() - moment(b.completionDate).unix(),
      render: (data) => (data ? moment(data).format('DD/MM/YYYY') : '-'),
    },
    {
      title: 'PSL',
      dataIndex: 'pslId',
      key: 'psl',
      width: '13%',
      render: (val) => {
        if (val.psl) {
          return val.psl.toUpperCase();
        }
        return '-';
      },
    },
    {
      title: 'Contract No',
      dataIndex: 'contractNumber',
      key: 'contractNumber',
      width: '12%',
      align: 'right',
      sorter: (a, b) => a.contractNumber.localeCompare(b.contractNumber),
      render: (data) => data || '-',
    },
    {
      title: 'APOA No',
      dataIndex: 'apoaNumber',
      key: 'apoaNumber',
      width: '12%',
      align: 'right',
      render: (data) => data || '-',
    },
    {
      title: 'Cost Center',
      dataIndex: 'costCenter',
      key: 'costCenter',
      width: '13%',
      align: 'right',
      render: (data) => data || '-',
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      width: '12%',
      render: (data) => (data ? moment(data).format('DD/MM/YYYY') : '-'),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '15%',
      align: 'center',
      fixed: 'right',
      render: (_, data) => {
        const { project, contractNumber, calloutFormUrl } = data;

        if (data !== null) {
          return (
            <Button type="link">
              <Typography.Link
                onClick={() => viewCallOutForm(project, contractNumber, calloutFormUrl)}
              >
                View Call Out Form
              </Typography.Link>
            </Button>
          );
        }
        return 'No Call Out Form Attached';
      },
    },
  ];

  const BreadCrumbDiv = () => (
    <Breadcrumb>
      <Breadcrumb.Item>
        <a href="/consultant-management">Consultant Management</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <a href="/consultant-management/consultant-management/details/1">Consultant Details</a>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const PageHeaderDiv = () => (
    <PageHeader title="Consultant Details" className="basic-pageheader" />
  );

  const ContentsDiv = (currentTab) => {
    switch (currentTab) {
      case '1':
        return <WorkInformation />;
      case '2':
        return <RFPHistory />;
      case '3':
        return (
          <>
            <div className="header-section">
              <Card className="header-section-card">
                <Row className="header-section-card-row">
                  <Col>
                    <SearchBar />
                  </Col>
                  {showReset === true ? (
                    <Col className="vertical-align-center-button">
                      <Col>
                        <ApplyFilterButton />
                      </Col>
                      <Col className="right-positioned-button">
                        <ResetButton />
                      </Col>
                    </Col>
                  ) : (
                    <Col className="vertical-align-center-button">
                      <ApplyFilterButton />
                    </Col>
                  )}
                </Row>
              </Card>
            </div>
            <WorkHistory />
          </>
        );
      case '4':
        return <PersonalInformation />;
      case '5':
        return <DownloadsSection />;
      default:
        return '';
    }
  };

  const handleContentChange = (activeTab) => {
    setSelectedTab(activeTab);
    Cookie.set('selectedTab', activeTab);
    history.push({ pathname: '/consultant-management/details', search: `?id=${id}&name=${name}&tab=${activeTab}` });
  };

  const tabItems = [
    { label: 'Work Information', key: '1' },
    { label: 'RFP History', key: '2' },
    { label: 'Work History', key: '3' },
    { label: 'Personal Information', key: '4' },
    { label: 'Downloads', key: '5' },
  ];

  const TabsDiv = () => (
    <Row className="space-between-header">
      <Col xs={15} sm={17}>
        <Tabs defaultActiveKey="1" items={tabItems} activeKey={selectedTab} onChange={handleContentChange} />
      </Col>
    </Row>
  );

  const WorkInformationStatistic = () => (loadingWorkInformationData === false ? (
    <div className="site-card-wrapper">
      <Row gutter={[16, 16]}>
        <Col xs={12} md={6}>
          <Card bordered={false} className="work-details-statistics-card">
            <Row className="work-details-statistics-card-title">Total Contract</Row>
            <Row className="work-details-statistics-card-contents">{workContractData.length}</Row>
            <Row className="work-details-statistics-card-footer">
              <Text style={{ width: 220, color: '#8c8c8c' }} ellipsis>
                <Tooltip title={`${workContractPositionData}`}>
                  {workContractData.length === 0 ? '-' : workContractPositionData}
                </Tooltip>
              </Text>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card bordered={false} className="work-details-statistics-card">
            <Row className="work-details-statistics-card-title">Work Permit Expiry Date</Row>
            <Row className="work-details-statistics-card-contents">
              {workConsultantData.workPermitEnd
                ? moment(workConsultantData.workPermitEnd).format('DD MMMM YY')
                : '-'}
            </Row>
            <Row className="work-details-statistics-card-redirects-text">
              {workConsultantData.workPermitEnd ? (
                <Typography.Link onClick={() => viewWorkPermit(workConsultantData.workPermitUrl)}>
                  View Work Permit
                </Typography.Link>
              ) : null}
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card bordered={false} className="work-details-statistics-card">
            <Row className="work-details-statistics-card-title">Insurance Expiry Date</Row>
            <Row className="work-details-statistics-card-contents">
              {workConsultantData.insuranceEnd
                ? moment(workConsultantData.insuranceEnd).format('DD MMMM YY')
                : '-'}
            </Row>
            <Row className="work-details-statistics-card-redirects-text">
              {workConsultantData.insurancePolicyUrl ? (
                <Typography.Link
                  onClick={() => viewInsurance(workConsultantData.insurancePolicyUrl)}
                >
                  View Insurance
                </Typography.Link>
              ) : null}
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card bordered={false} className="work-details-statistics-card">
            <Row className="work-details-statistics-card-title">Status</Row>
            <Row className="work-details-statistics-card-contents">
              {workConsultantStatus !== null
                ? capitalizedFirstAlphabet(workConsultantStatus)
                : '-'}
            </Row>
            {workConsultantStatus != null && workConsultantStatus === 'pending approval' ? (
              <Row className="work-details-statistics-card-redirects-text">
                <Typography.Link onClick={() => onApproveConsultant()}>Approve</Typography.Link>
              </Row>
            ) : null}
          </Card>
        </Col>
      </Row>
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '15px' }}>
      <Spin
        indicator={(
          <LoadingOutlined
            style={{
              fontSize: 24,
              color: '#f7931d',
            }}
            spin
          />
          )}
      />
    </div>
  ));

  const WorkInformation = () => (
    <>
      <WorkInformationStatistic />
      <div className="consultant-details-information-white-area">
        {loadingWorkInformationData === false
          ? WorkInformationDetails(
            workConsultantData,
            workConsultantName,
            workConsultantBankName,
            workContractPSLData,
            workContractContractExpiryDates,
            workInvoicingSchedule,
          )
          : null}
      </div>
      <Button type="primary" className="floating-button" onClick={onShowEditWorkInfo}>
        Edit
      </Button>
    </>
  );

  const EditWorkInformation = () => (
    <Modal
      centered
      title="Edit Work Information"
      open={openEditWork}
      onCancel={handleCancelEditWork}
      confirmLoading={loadingUpdateWorkData}
      footer={[
        <Button key="back" onClick={handleCancelEditWork}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loadingUpdateWorkData}
          onClick={editWorkForm.submit}
        >
          Save Changes
        </Button>,
      ]}
      bodyStyle={{ overflowY: 'auto', height: '60vh' }}
    >
      <EditWorkForm />
    </Modal>
  );

  const RFPHistory = () => <div className="table-content">{RFPHistoryTable(id)}</div>;

  const WorkHistory = () => (
    <div className="table-content">
      <Table
        scroll={{ x: 1100 }}
        loading={loadingWorkHistoryData}
        columns={WorkHistoryColumns}
        dataSource={workHistoryData}
        pagination={{
          position: ['bottomRight'],
          total: workHistoryData.length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          showSizeChanger: true,
          defaultPageSize: 10,
          pageSizeOptions: ['10', '50', '100'],
        }}
      />
    </div>
  );

  const PersonalInformation = () => (
    <>
      <div className="consultant-details-information-white-area">
        {PersonalDetailConsultantDetails(id)}
      </div>
      <Button type="primary" className="floating-button" onClick={onShowEditPersonal}>
        Edit
      </Button>
    </>
  );

  const EditPersonalInformation = () => (
    <Modal
      centered
      open={openEditPersonal}
      title="Edit Personal Information"
      onCancel={handleCancelEditPersonal}
      confirmLoading={loadingEditPersonal}
      footer={[
        <Button key="back" onClick={handleCancelEditPersonal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loadingUpdateWorkData={loadingEditPersonal}
          onClick={editPersonalForm.submit}
        >
          Save Changes
        </Button>,
      ]}
      bodyStyle={{ overflowY: 'auto', height: '400px' }}
    >
      <EditPersonalForm />
    </Modal>
  );

  const DownloadsSection = () => (
    <div className="consultant-details-download-contents">{DonwloadsSection(id)}</div>
  );

  const EditWorkForm = () => (
    <Form
      form={editWorkForm}
      layout="vertical"
      name="edit-work-info"
      onFinish={onFinishEditWorkInfo}
      validateMessages={validateMessages}
    >
      <Item label="Name" name={['work', 'name']} initialValue={workConsultantData.name}>
        <Input />
      </Item>
      <Item
        label="NRIC/ Passport No"
        name={['work', 'ICPassport']}
        initialValue={workConsultantData.nric}
        rules={[
          {
            pattern: new RegExp('^[A-Za-z0-9]*$'),
            message: 'Special characters not allowed',
          },
        ]}
      >
        <Input />
      </Item>
      <Item label="Uplaod new NRIC/ Passport" name={['work', 'ICPassportFile']} valuePropName="fileList">
        <Upload
          style={{ width: '100%' }}
          maxCount={1}
          accept=".jpg, .jpeg, .png, .pdf"
          {...uploadICProps}
        >
          <Tooltip title="Maximum file size 5MB" placement="right">
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Tooltip>
        </Upload>
      </Item>
      <Item
        label="Date of birth"
        name={['work', 'DOB']}
        initialValue={moment(workConsultantData.dateOfBirth)}
      >
        <DatePicker style={{ width: '100%' }} />
      </Item>
      <Item
        label="Contact Number (Phone)"
        name={['work', 'personalContactNo']}
        initialValue={workConsultantData.contactPhone}
        rules={[
          {
            pattern: /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/,
            message: 'Please enter a valid phone number',
          },
        ]}
      >
        <Input placeholder="+60182736472" />
      </Item>
      <Item
        label="Email"
        rules={[
          {
            type: 'email',
          },
        ]}
        name={['work', 'email']}
        initialValue={workConsultantData.email}
      >
        <Input />
      </Item>
      <Item
        label="Agreement Signed"
        name={['work', 'agreementSigned']}
        initialValue={workConsultantData.agreementSigned === true ? 'yes' : 'no'}
      >
        <Select
          style={{
            width: '100%',
          }}
          defaultValue={workConsultantData.agreementSigned === true ? 'yes' : 'no'}
          allowClear
        >
          <Option value="yes">Yes</Option>
          <Option value="no">No</Option>
        </Select>
      </Item>
      <Item
        label="20 Rules"
        name={['work', 'rulesSigned']}
        initialValue={workConsultantData.rulesSigned === true ? 'yes' : 'no'}
      >
        <Select
          style={{
            width: '100%',
          }}
          defaultValue={workConsultantData.rulesSigned === true ? 'yes' : 'no'}
          allowClear
        >
          <Option value="yes">Yes</Option>
          <Option value="no">No</Option>
        </Select>
      </Item>
      <Item
        label="Bank Name"
        name={['work', 'bankName']}
        initialValue={workConsultantData.bankName}
      >
        <Input />
      </Item>
      <Item
        label="Account Name"
        name={['work', 'accountName']}
        initialValue={workConsultantData.accountName}
      >
        <Input />
      </Item>
      <Item
        label="Account No"
        name={['work', 'accountNo']}
        initialValue={workConsultantData.accountNumber}
      >
        <Input />
      </Item>
      <Item label="EPF No" name={['work', 'EPFNo']} initialValue={workConsultantData.epfNumber === null ? '' : workConsultantData.epfNumber}>
        <Input />
      </Item>
      <Item
        label="Socso No"
        name={['work', 'socsoNo']}
        initialValue={
          workConsultantData.socsoNumber === null ? '' : workConsultantData.socsoNumber
        }
      >
        <Input />
      </Item>
      <Item label="PCB No" name={['work', 'pcbNo']} initialValue={workConsultantData.pcbNumber === null ? '' : workConsultantData.pcbNumber}>
        <Input />
      </Item>
      <Row style={{ fontWeight: '700' }}>INSURANCE (GROUP HOSPITALIZATION & SURGICAL)</Row>
      <Item
        label="Start Date"
        name={['work', 'insuranceStartDate']}
        initialValue={workConsultantData.insuranceStart != null 
          ? moment(workConsultantData.insuranceStart) 
          : moment()}
      >
        <DatePicker style={{ width: '100%' }} />
      </Item>
      <Item
        label="End Date"
        name={['work', 'insuranceEndDate']}
        initialValue={workConsultantData.insuranceEnd != null 
          ? moment(workConsultantData.insuranceEnd) 
          : moment()}
      >
        <DatePicker style={{ width: '100%' }} />
      </Item>
      <Row style={{ fontWeight: '700' }}>INSURANCE (GROUP PERSONAL ACCIDENT)</Row>
      <Item
        label="Start Date"
        name={['work', 'insuranceStartDate']}
        initialValue={workConsultantData.insuranceStart != null 
          ? moment(workConsultantData.insuranceStart) 
          : moment()}
      >
        <DatePicker style={{ width: '100%' }} />
      </Item>
      <Item
        label="End Date"
        name={['work', 'insuranceEndDate']}
        initialValue={workConsultantData.insuranceEnd != null 
          ? moment(workConsultantData.insuranceEnd) 
          : moment()}
      >
        <DatePicker style={{ width: '100%' }} />
      </Item>
      <Item
        label="Endorsement Note"
        name={['work', 'endorsementNote']}
        initialValue={
          workConsultantData.endorsementNote === null ? '' : workConsultantData.endorsementNote
        }
      >
        <Input />
      </Item>
      <Item
        label="Remarks"
        name={['work', 'remarks']}
        initialValue={workConsultantData.remarks === null ? '' : workConsultantData.remarks}
      >
        <TextArea rows={4} />
      </Item>
    </Form>
  );

  const EditPersonalForm = () => (
    <Form
      form={editPersonalForm}
      layout="vertical"
      name="edit-work-info"
      onFinish={onFinishEditPersonalInfo}
      validateMessages={validateMessages}
    >
      <Item label="Name" name={['personal', 'name']} initialValue={consultantData.name}>
        <Input />
      </Item>
      <Item
        label="NRIC/ Passport No"
        name={['personal', 'ICPassportNo']}
        initialValue={consultantData.nric}
        rules={[
          {
            pattern: new RegExp('^[A-Za-z0-9]*$'),
            message: 'Special characters not allowed',
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label="Nationality"
        name={['personal', 'nationality']}
        initialValue={consultantData.nationality}
      >
        <Select
          placeholder="Please select"
          style={{
            width: '100%',
          }}
          showSearch
          defaultValue={consultantData.nationality}
          allowClear
        >
          {NationalitiesList.map((val) => (
            <Option key={val.toLowerCase()} value={val}>
              {val}
            </Option>
          ))}
        </Select>
      </Item>
      <Item label="Gender" name={['personal', 'gender']} initialValue={consultantData.gender}>
        <Select
          style={{
            width: '100%',
          }}
          placeholder="Please select"
          defaultValue={consultantData.gender}
          allowClear
        >
          <Option value="male">Male</Option>
          <Option value="female">Female</Option>
        </Select>
      </Item>
      <Item label="Email" name={['personal', 'email']} initialValue={consultantData.email}>
        <Input />
      </Item>
      <Item
        label="Maritial Status"
        name={['personal', 'maritalStatus']}
        initialValue={consultantData.maritalStatus}
      >
        <Select
          style={{
            width: '100%',
          }}
          placeholder="Please select"
          defaultValue={consultantData.maritalStatus}
          allowClear
        >
          <Option value="single">Single</Option>
          <Option value="married">Married</Option>
          <Option value="divorced">Divorced</Option>
          <Option value="widow">Widow</Option>
        </Select>
      </Item>
      <Item label="Address" name={['personal', 'address']} initialValue={consultantData.address}>
        <Input />
      </Item>
      <Item
        label="Contact No (Phone)"
        name={['personal', 'phoneContactNo']}
        initialValue={consultantData.contactPhone}
      >
        <Input placeholder="+60192876251" />
      </Item>
      <Item
        label="Contact No (Home)"
        name={['personal', 'homeContactNo']}
        initialValue={consultantData.contactPhone}
      >
        <Input />
      </Item>
    </Form>
  );

  const onSearch = () => {
    searchForm
      .validateFields()
      .then(async (res) => {
        const { searchWorkHistory } = res;
        const { apoaContract } = searchWorkHistory;
        setSearchAPOAContractNo(apoaContract);
        setShowReset(true);

        if (apoaContract) {
          setWorkHistoryAPIURL(
            `v1/consultants/${id}/getConsultantWorkHistory?search=${apoaContract.toString()}`,
          );
        }
        searchForm.resetFields();
      })
      .catch(() => {});
  };

  const onResetSearch = () => {
    setSearchAPOAContractNo('');
    setWorkHistoryAPIURL(`v1/consultants/${id}/getConsultantWorkHistory`);
    setShowReset(false);
  };

  const SearchBar = () => (
    <Row>
      <Form layout="inline" form={searchForm}>
        <Item name={['searchWorkHistory', 'apoaContract']} initialValue={searchAPOAContractNo}>
          <div className="header-section-search-bar-section">
            APOA No/ Contract No
            <Input allowClear />
          </div>
        </Item>
      </Form>
    </Row>
  );
  const ApplyFilterButton = () => <Button type="primary" key="submit" onClick={onSearch}>Apply</Button>;
  const ResetButton = () => (
    <Tooltip title="Reset Filters">
      <Button type="primary" onClick={onResetSearch}>
        Reset
      </Button>
    </Tooltip>
  );

  const PDFViewer = () => (
    <Modal
      title={`${modalTitle}`}
      open={showPDFViewer}
      centered
      bodyStyle={{ overflowY: 'auto', height: '80vh' }}
      width={800}
      onCancel={() => setShowPDFViewer(false)}
      footer={null}
    >
      <iframe
        id="pdf-view"
        src={`${fileURL}#view=fitW`}
        title="pdfFileViewer"
        height={550}
        width="100%"
      />
    </Modal>
  );

  return (
    <>
      <div className="with-tabs-breadcrumb-layout">
        <BreadCrumbDiv />
        <PageHeaderDiv />
        <TabsDiv />
      </div>
      <Content className="layout-background">
        {ContentsDiv(selectedTab)}
        <EditWorkInformation />
        <EditPersonalInformation />
        <PDFViewer />
      </Content>
    </>
  );
};

export default ConsultantDetails;
