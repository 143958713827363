import React from 'react';

import { Row, Col } from 'antd';

import TopMostStatistics from '../../components/dashboard/TopMostStatistics';
import FinancialPerformanceOverviewChart from '../../components/dashboard/FinancialPerformanceOverview';
import ARStatistics from '../../components/dashboard/AR';
import PSLDistributionChart from '../../components/dashboard/PSLDistribution';
import PerformanceHistory from '../../components/dashboard/PerformanceHistory';
import ToDoNotificationList from '../../components/dashboard/ToDoListNotification';

export default function Dashboard() {
  return (
    <div className="dashboard-content-layout">
      {TopMostStatistics()}
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <Col style={{ paddingTop: '0px', paddingRight: '0px', paddingBottom: '0px', paddingLeft: '0px' }} flex="auto">
            {FinancialPerformanceOverviewChart()}
          </Col>
          <Row className="dashboard-left-col-bottom-row" gutter={[16, 16]}>
            <Col span={13}>{PerformanceHistory()}</Col>
            <Col span={11}>{PSLDistributionChart()}</Col>
          </Row>
        </Col>
        <Col span={8} flex="auto">
          {ARStatistics()}
          {ToDoNotificationList()}
        </Col>
      </Row>
    </div>
  );
}
