import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';

import {
  Breadcrumb,
  PageHeader,
  Steps,
  Button,
  message,
  Layout,
  Upload,
  Row,
  Col,
  Modal,
  Input,
  Form,
  Table,
  InputNumber,
  Checkbox,
  Tag,
  Select,
} from 'antd';
import {
  InboxOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';

// import timesheetExample from '../../assets/file/timesheetSample.pdf';

import './RunPayment.css';
import extractTimesheet from './extractTImesheet';
import extractClaimForm from './extractClaimForm';
import { getUser, getToken } from '../../utils/AuthService';
// import moment from 'moment';

const { Step } = Steps;
const { Option } = Select;
const { Content } = Layout;
const { Dragger } = Upload;
const { confirm } = Modal;
const { TextArea } = Input;

const defaultURL = process.env.REACT_APP_NODE_ENV === 'PRODUCTION'
  ? process.env.REACT_APP_PRODUCTION_URL
  : process.env.REACT_APP_NODE_ENV === 'STAGING'
    ? process.env.REACT_APP_SANDBOX_URL
    : process.env.REACT_APP_LOCAL_URL;

function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export default function RunPayment() {
  const { role } = getUser();
  const token = getToken();
  const [form] = Form.useForm();
  const [reviewclaimFormForm] = Form.useForm();
  const [timesheetTotalDaysForm] = Form.useForm();
  const [rejectForm] = Form.useForm();

  // todo: get user type
  const userType = role;
  const bearer = token.access.token;
  const history = useHistory();
  const location = useLocation();
  const currencyFormatter = new Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' });

  const [payment, setPayment] = useState();
  const [current, setCurrent] = useState(userType === 'authorizer' ? 5 : 0);
  const [percent, setCurrentPercent] = useState(userType === 'authorizer' ? 100 : 0);
  const [manDayRates, setManDayRates] = useState();
  const [timesheeturl, setTimesheetURL] = useState('');
  const [uploadingTimesheet, setUploadingTimesheet] = useState(true);
  const [uploadingClaimForm, setUploadingClaimForm] = useState(true);
  // const [uploadingReceipt, setUploadingReceipt] = useState(true);
  // const [selectedReceipt, setSelectedReceipt] = useState();
  const [skippedUploadTimeSheet, setSkippedUploadTimeSheet] = useState(false);
  const [skippedUploadClaimForm, setSkippedUploadClaimForm] = useState(false);
  const [timesheetTotalDays, setTimesheetTotalDays] = useState();
  const [timesheetData, setTimesheetData] = useState([]);
  const [paymentRate, setPaymentRate] = useState([]);
  const [claimForm, setClaimForm] = useState('');
  const [claimFormItems, setClaimFormItems] = useState([]);
  const [claimFormTableData, setClaimFormTableData] = useState([]);
  const [editingClaim, setEditingClaim] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(true);
  const [rfp, setRfp] = useState('');
  const [customTax, setCustomTax] = useState();
  // const [
  //   // confirmLoadingEditTaxes,
  //   setConfirmLoadingEditTaxes,
  // ] = useState(true);

  const [comments, setComments] = useState([]);
  const [value, setValue] = useState('');

  const [disabledNextButton, setDisableNextButton] = useState(false);

  // todo: modify here
  useEffect(() => {
    // const fetchPosition = async (positionId) => {
    //   await axios(`v1/positions/${positionId}`, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${token.access.token}`,
    //     },
    //   })
    //     .then((res) => {
    //       setManDayRates(res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       message.error('Failed to Fetch Position');
    //     });
    // };

    // const fetchCalloutPosition = async (calloutId) => {
    //   await axios(`v1/callouts/${calloutId}`, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${token.access.token}`,
    //     },
    //   })
    //     .then((res) => {
    //       setManDayRates(res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       message.error('Failed to fetch Callout');
    //     });
    // };

    const fetchCallOut = async (calloutId, positionId, mandayRateSet) => {
      await axios(`v1/callouts/${calloutId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
        .then((res) => {
          // eslint-disable-next-line max-len
          const posData = res.data.position.filter((item) => item.mandayRateSet === mandayRateSet);

          setManDayRates(posData[0]);
        })
        .catch((err) => {
          message.error('Failed to Fetch Callout', err);
        });
    };
    const fetchPayment = async () => {
      const paymentId = location.pathname.split('/')[3];

      const url = `v1/payments/${paymentId}`;
      await axios(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
        .then((res) => {
          if (location.pathname.split('/')[2] === 'review') {
            setPayment(res.data);
          }
          setTimesheetURL(res.data.timesheetUrl || undefined);
          setClaimForm(res.data.claimFormUrl || undefined);
          setRfp(res.data.rfpUrl || undefined);
          setCustomTax(
            res.data.employerCustomLabel
              ? 'employer'
              : res.data.employeeCustomLabel
                ? 'employee'
                : undefined,
          );
          // fetchPosition(res.data.positionId);
          fetchCallOut(res.data.calloutId, res.data.positionId, res.data.mandayRateSet);
        })
        .catch((err) => {
          message.error('Failed to fetch payment data', err);
        });
    };

    if (location.state && current === 0) {
      if (location.pathname.split('/')[2] === 'edit') {
        setPayment(location.state.payment);
        fetchPayment();
      } else if (location.pathname.split('/')[2] === 'add-new') {
        setPayment(location.state.payment);
      }
      // eslint-disable-next-line max-len
      fetchCallOut(
        location.state.payment.calloutId,
        location.state.payment.positionId,
        location.state.payment.mandayRateSet,
      );
    }

    if (!location.state) {
      fetchPayment();
      if (userType === 'authorizer') {
        if (location.pathname.split('/')[2] === 'review') {
          setCurrent(5);
          setCurrentPercent(100);
        }
      }
    }
  }, []);

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (val, data) => {
        let convertedVal;
        let calculatedData;
        let finalData;
        if (val) {
          if (val !== '0.00') {
            convertedVal = parseFloat(val.replace(',', ''), 10);

            if (data.description === 'Monthly Rate') {
              calculatedData = Math.round(convertedVal).toFixed(2);
            } else {
              calculatedData = convertedVal.toFixed(2);
            }

            finalData = calculatedData.toString();
          } else {
            finalData = val;
          }
        }
        return finalData;
      },
    },
  ];

  const ClaimSummaryColumns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (val) => (
        <>
          <p>{val.toFixed(2)}</p>
        </>
      ),
    },
  ];

  const validateMessages = {
    required: '${label} is required!',
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const props = (index) => {
    // name: 'file',
    const propObj = {
      action: `${defaultURL}v1/payments/${payment ? payment.id : undefined}/uploadReceipt`,
      beforeUpload: (file) => {
        // note: allow excel & pdf file only
        const isPNG = file.type === 'image/png';
        const isJPEG = file.type === 'image/jpeg';
        const isPDF = file.type === 'application/pdf';
        if (!isPNG && !isJPEG && !isPDF) {
          message.error(`${file.name} is not a png or jpg file`);
        }
        return isPNG || isJPEG || isPDF || Upload.LIST_IGNORE;
      },
      // async onChange(fileInfo) {
      // },
      customRequest: (options) => {
        const fileData = new FormData();
        fileData.append('receipt', options.file);

        const config = {
          url: options.action,
          method: 'post',
          headers: {
            'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
            Authorization: `Bearer ${token.access.token}`,
          },
          data: fileData,
        };

        // setUploadingReceipt(true);
        axios(config)
          .then((res) => {
            // setUploadingReceipt(false);
            if (res.status === 201) {
              const claimValue = reviewclaimFormForm.getFieldsValue().receipts_item;
              const changed = claimValue.map((claim, i) => {
                if (i === index) {
                  // eslint-disable-next-line no-param-reassign
                  claim.receiptUrl = res.data.Location;
                }
                return claim;
              });
              reviewclaimFormForm.setFieldsValue({
                receipts_item: changed,
              });
              setClaimFormItems(changed);
              message.success('Receipt uploaded successfully');
            } else {
              message.error('Failed to upload receipt');
            }
          })
          .catch((err) => {
            // setUploadingReceipt(false);
            message.error('Failed to upload receipt', err);
          });
      },
    };
    return propObj;
  };

  const timesheetProps = {
    name: 'file',
    multiple: true,
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76', // todo: change API url
    beforeUpload: () => false,
    async onChange(fileInfo) {
      // note: allow excel & pdf file only
      const isXls = fileInfo.file.type === 'application/vnd.ms-excel'
        || fileInfo.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const isPDF = fileInfo.file.type === 'application/pdf';
      if (!isXls && !isPDF) {
        message.error(`${fileInfo.file.name} is not a excel file or PDF file`);
        return;
      }
      // console.log(fileInfo);
      const fileData = new FormData();
      fileData.append('timeSheet', fileInfo.file);

      const config = {
        url: `/v1/payments/${payment.id}/uploadTimesheet`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
        data: fileData,
      };

      setUploadingTimesheet(true);
      await axios(config)
        .then((res) => {
          if (res.data.status === 'success') {
            setSkippedUploadTimeSheet(false);
            setTimesheetURL(res.data.body.timesheetUrl);
            const splitted = res.data.body.timesheetUrl.toString().split('/');
            console.log(splitted[4]);
            message.success(res.data.message);
          } else {
            message.error('Failed to upload timesheet');
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const claimFormProps = {
    name: 'file',
    multiple: true,
    beforeUpload: () => false,
    async onChange(fileInfo) {
      // note: allow excel & pdf file only
      const isXls = fileInfo.file.type === 'application/vnd.ms-excel'
        || fileInfo.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const isPDF = fileInfo.file.type === 'application/pdf';
      if (!isXls && !isPDF) {
        message.error(`${fileInfo.file.name} is not a excel file or PDF file`);
        return;
      }
      const fileData = new FormData();
      fileData.append('claimForm', fileInfo.file);

      const config = {
        url: `/v1/payments/${payment.id}/uploadClaimForm`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
        data: fileData,
      };

      setUploadingClaimForm(true);
      console.log(uploadingClaimForm);
      await axios(config)
        .then((res) => {
          setUploadingClaimForm(false);
          if (res.data.status === 'success') {
            setClaimForm(res.data.body.claimFormUrl);
            setUploadingTimesheet(false);
            const splitted = res.data.body.claimFormUrl.toString().split('/');
            console.log(splitted[4]);
            message.success(res.data.message);
          } else {
            message.error('Failed to upload Claim Form');
          }
        })
        .catch((err) => {
          setUploadingClaimForm(false);
          console.log(err.response);
          message.error('Failed to upload Claim Form');
        });
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const handleGetGeneratedRfp = async () => {
    const results = await axios(`/v1/payments/${payment.id}/getGeneratedRfp`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        message.error('Failed to generate RFP');
      });
    setPayment(results);
    setRfp(results.rfpUrl);
    if (results.isTax) {
      const taxItems = {};
      results.taxItems.forEach((val) => {
        switch (val.label) {
          case 'Socso (Employer)':
            taxItems.socsoEmployer = val.amount;
            break;
          case 'Socso (Employee)':
            taxItems.socsoEmployee = val.amount;
            break;
          case 'EPF (Employer)':
            taxItems.epfEmployer = val.amount;
            break;
          case 'EPF (Employee)':
            taxItems.epfEmployee = val.amount;
            break;
          case 'EIS (Employer)':
            taxItems.eisEmployer = val.amount;
            break;
          case 'EIS (Employee)':
            taxItems.eisEmployee = val.amount;
            break;
          case 'Witholding Tax (Employee)':
            taxItems.withholdingTax = val.amount;
            break;
          case 'PCB (Employee)':
            taxItems.pcb = val.amount;
            break;
          default:
            break;
        }
      });
      if (customTax === 'employer') {
        taxItems.custom = {
          label: results.employerCustomLabel.label,
          amount: results.employerCustomLabel.amount,
        };
      } else if (customTax === 'employee') {
        taxItems.custom = {
          label: results.employeeCustomLabel.label,
          amount: results.employeeCustomLabel.amount,
        };
      }
      form.setFieldsValue(taxItems);
    }
  };

  const warningUploadTimesheet = () => {
    confirm({
      title: 'Missing Timesheet',
      icon: <ExclamationCircleOutlined />,
      content: 'You havent upload the Timesheet, upload TImesheet?',
      okText: 'Upload Timesheet',
      centered: true,

      onOk() {
        setCurrent(0);
        setSkippedUploadTimeSheet(false);
      },

      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const warningClearClaimForm = () => {
    confirm({
      title: 'Reupload Claim Form?',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you want to reupload claim form or go back to previous step?',
      okText: 'Reupload Claim Form',
      cancelText: 'Go back',
      centered: true,

      async onOk() {
        const config = {
          url: `/v1/payments/${payment.id}/clearClaimItems`,
          method: 'put',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        };

        await axios(config)
          .then(() => {
            setClaimForm('');
          })
          .catch((err) => {
            console.log(err.response);
            message.error('Failed to save change');
          });
      },

      onCancel() {
        setCurrent(current - 1);
        setCurrentPercent(percent - 10);
      },
    });
  };

  const onSkipUploadTimesheet = async () => {
    setCurrent(current + 2);
    setSkippedUploadTimeSheet(true);
  };

  const onSkipUploadClaimForm = async () => {
    if (!skippedUploadTimeSheet) {
      const config = {
        url: `/v1/payments/${payment.id}/clearClaimItems`,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      };

      await axios(config)
        .then(() => {
          setCurrent(current + 2);
          setSkippedUploadClaimForm(true);
          setCurrentPercent(percent + 40);
          handleGetGeneratedRfp();
        })
        .catch((err) => {
          console.log(err.response);
          message.error('Failed to save change');
        });
    } else {
      warningUploadTimesheet();
    }
  };

  const calculateRateTotal = (extractedDays) => {
    const newPaymentRate = [];
    if (manDayRates && manDayRates.isMonthly) {
      const extractedDaysArr = Object.keys(extractedDays).map((key) => extractedDays[key]);
      let totalDays = extractedDaysArr.reduce((total, val) => total + (val || 0), 0);
      const rfpDate = new Date(payment.runPaymentDate);
      const year = rfpDate.getFullYear();
      const month = rfpDate.getMonth() + 1;
      const daysThisMonth = getDaysInMonth(year, month);
      const ratePerDay = totalDays >= daysThisMonth
        ? manDayRates.monthlyRate
        : (manDayRates.monthlyRate / 26).toFixed(2);
      totalDays = totalDays >= daysThisMonth ? 1 : totalDays;
      const amount = Math.round(ratePerDay * totalDays).toFixed(2);
      newPaymentRate.push({
        description: 'Monthly Rate',
        quantity: totalDays,
        rate: ratePerDay,
        amount,
      });
      setTimesheetData([
        {
          no: 1,
          description: 'Monthly Rate',
          quantity: totalDays,
          rate: ratePerDay,
          amount,
        },
      ]);
    } else {
      const extracted = extractedDays;
      delete extracted.Monthly;
      const extractedEntries = Object.entries(extracted);
      const newTimesheetData = extractedEntries.map((val, i) => {
        const columnData = val;
        const formattedRate = currencyFormatter
          .format(manDayRates[`${columnData[0].toLowerCase()}Rate`] || 0)
          .toString()
          .replace(/[a-zA-Z\s]/g, '');
        const totalAmount = parseFloat(manDayRates[`${columnData[0].toLowerCase()}Rate`]) * columnData[1];
        // console.log('parseInt(columnData[1], 10): ', parseInt(columnData[1], 10));
        const formattedAmount = currencyFormatter
          .format(totalAmount || 0)
          .toString()
          .replace(/[a-zA-Z\s]/g, '');

        if (columnData[1] > 0) {
          const paymentRateData = {
            description: columnData[0],
            quantity: columnData[1],
            rate: manDayRates[`${columnData[0].toLowerCase()}Rate`] || 0,
            amount: totalAmount,
          };
          newPaymentRate.push(paymentRateData);
        }

        const rateData = {
          no: i + 1,
          key: columnData[0].toLowerCase(),
          description: columnData[0].charAt(0).toUpperCase() + columnData[0].slice(1).toLowerCase(),
          quantity: columnData[1],
          rate: formattedRate,
          amount: formattedAmount,
        };

        return rateData;
      });
      setTimesheetData(newTimesheetData);
    }

    setPaymentRate(newPaymentRate);
  };

  const readTimesheet = async () => {
    if (!timesheetTotalDays) {
      const extractedDays = await extractTimesheet(payment.id, token);
      const extractedDaysArr = Object.keys(extractedDays).map((key) => extractedDays[key]);
      const totalDays = extractedDaysArr.reduce((total, val) => total + val, 0);
      if (manDayRates.isMonthly) {
        timesheetTotalDaysForm.setFieldsValue({
          monthly: totalDays,
        });
      } else {
        timesheetTotalDaysForm.setFieldsValue({
          dayrate: extractedDays.day,
          onshore: extractedDays.Onshore,
          offshore: extractedDays.Offshore,
          travel: extractedDays.Travel,
          quarantine: extractedDays.Quarantine,
          standby: extractedDays.Standby,
        });
      }
      setTimesheetTotalDays(extractedDays);
      calculateRateTotal(extractedDays);
    }
  };

  const warningNoTimeSheet = () => {
    confirm({
      title: 'Missing Timesheet',
      icon: <ExclamationCircleOutlined />,
      content: 'You havent upload the Time Sheet, skip to upload Claim Form?',
      okText: 'Skip',
      centered: true,

      onOk() {
        onSkipUploadTimesheet();
      },

      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const warningNoClaimForm = () => {
    confirm({
      title: 'Missing Claim Form',
      icon: <ExclamationCircleOutlined />,
      content: 'You havent upload the Claim Form, skip to upload Choose Taxes?',
      okText: 'Skip',
      centered: true,

      onOk() {
        onSkipUploadClaimForm();
      },

      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onChangeTimesheet = (values) => {
    const extractedDays = {
      day: values.dayrate,
      Onshore: values.onshore,
      Offshore: values.offshore,
      Travel: values.travel,
      Quarantine: values.quarantine,
      Standby: values.standby,
      Monthly: values.monthly,
    };
    setTimesheetTotalDays(extractedDays);
    calculateRateTotal(extractedDays);
  };

  const onEditRunPayment = () => {
    setCurrent(0);
    setSkippedUploadTimeSheet(false);
    setCurrentPercent(0);
  };

  const onSubmitRFP = () => {
    confirm({
      title: `${payment.status === 'incomplete' ? 'Submit' : 'Resubmit'} RFP?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      confirmLoading: true,
      visible: showSubmitModal,

      async onOk() {
        const config = {
          url: `/v1/payments/${payment.id}/submit`,
          method: 'put',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
          },
          data: {
            consultantId: payment.consultantId,
            runPaymentDate: payment.runPaymentDate,
          },
        };
        await axios(config)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              setShowSubmitModal(false);
              history.push(`/consultant-management/details?id=${payment.consultantId}&tab=2`);
              message.success('RFP Submitted');
            }
          })
          .catch((err) => {
            console.log(err.response);
            message.error('Failed to Submit RFP');
          });
      },

      onCancel() {},
    });
  };

  const handleSubmitRejectComments = async () => {
    const result = rejectForm.submit();
    return result;
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const saveReviewClaimForm = async () => {
    const reviewFormValue = reviewclaimFormForm.getFieldsValue().receipts_item;
    const validateForm = reviewFormValue.map((formValue) => {
      if (formValue.needReceipt && !formValue.receiptUrl) {
        return false;
      }
      return true;
    });
    if (validateForm.includes(false)) {
      return false;
    }
    setClaimFormItems(reviewFormValue);
    const claimItems = reviewFormValue.map((data) => {
      const body = {
        item: data.description,
        amount: data.amount,
      };

      if (data.receiptUrl) {
        body.receiptUrl = data.receiptUrl;
      }

      return body;
    });
    const config = {
      url: `/v1/payments/${payment.id}/update`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
      data: {
        runPaymentDate: payment.runPaymentDate,
        consultantId: payment.consultantId,
        claimFormItems: claimItems,
        claimingTotal: parseFloat(claimFormTableData[0].amount),
      },
    };
    const result = await axios(config)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
        }
        return true;
      })
      .catch((e) => {
        console.log(e.response);
        message.error('Failed to Save Draft');
        return false;
      });

    return result;
  };

  const onSaveDraft = async () => {
    if (current === 3) {
      const result = await saveReviewClaimForm();
      if (result) {
        // todo: save val into cookies
        message.success('Saved as Draft');
      } else {
        message.error('Please upload the missing receipt!');
      }
    }
  };

  const onReject = () => {
    confirm({
      title: 'Are you sure you want to reject this form? Please give a reason.',
      content: <RemarksCommentBox onChange={handleChange} commentVal={value} />,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      centered: true,

      async onOk() {
        await handleSubmitRejectComments();
      },

      onCancel() {},
    });
  };

  const onApprove = () => {
    confirm({
      title: 'Approve RFP?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      centered: true,

      async onOk() {
        const config = {
          url: `v1/payments/${payment.id}/approve`,
          method: 'put',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        };

        await axios(config)
          .then((res) => {
            console.log(res);
            history.push(`/consultant-management/details?id=${payment.consultantId}&tab=1`);
            message.success('RFP Approved');
          })
          .catch((err) => {
            console.error(err);
          });
      },

      onCancel() {},
    });
  };

  // stepper
  const next = async () => {
    if (current === 0) {
      if (timesheeturl !== '') {
        const config = {
          url: `/v1/payments/${payment.id}/update`,
          method: 'put',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          data: {
            timesheetUrl: timesheeturl,
            consultantId: payment.consultantId,
            runPaymentDate: payment.runPaymentDate,
          },
        };
        await axios(config)
          .then(async (res) => {
            setPayment(res.data);
            setCurrent(current + 1);
            setCurrentPercent(percent + 20);
            try {
              if (payment.paymentRate.length > 0) {
                // console.log(payment, payment.paymentRate);
                const totalDays = {
                  day: 0,
                  Onshore: 0,
                  Offshore: 0,
                  Quarantine: 0,
                  Travel: 0,
                  Standby: 0,
                  Monthly: 0,
                };

                payment.paymentRate.forEach((val) => {
                  if (val.description === 'Monthly Rate') totalDays.Monthly = val.quantity;
                  else totalDays[val.description] = val.quantity;
                });
                const extractedDaysArr = Object.keys(totalDays).map((key) => totalDays[key]);
                const totalDay = extractedDaysArr.reduce((total, val) => total + val, 0);
                timesheetTotalDaysForm.setFieldsValue({
                  dayrate: totalDays.day,
                  onshore: totalDays.Onshore,
                  offshore: totalDays.Offshore,
                  travel: totalDays.Travel,
                  quarantine: totalDays.Quarantine,
                  standby: totalDays.Standby,
                  monthly: totalDays.Monthly,
                });
                setTimesheetTotalDays(totalDay);
                calculateRateTotal(totalDays);
              } else {
                await readTimesheet();
              }
            } catch (error) {
              console.log(error);
            }
          })
          .catch((err) => {
            console.log(err.response);
            message.error('Failed to save change');
          });
      } else {
        warningNoTimeSheet();
      }
    } else if (current === 1) {
      const paymentTotal = paymentRate.reduce((total, rate) => total + rate.amount, 0);

      const config = {
        url: `/v1/payments/${payment.id}/update`,
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
        data: {
          consultantId: payment.consultantId,
          runPaymentDate: payment.runPaymentDate,
          paymentRate,
          paymentTotal,
        },
      };

      await axios(config)
        .then((res) => {
          setPayment(res.data);
          setCurrent(current + 1);
          setCurrentPercent(percent + 20);
        })
        .catch((err) => {
          console.log(err.response);
          message.error('Failed to save change');
        });
    } else if (current === 2) {
      if (claimForm) {
        if (payment.claimFormItems.length > 0) {
          let amount = 0;
          const amounts = payment.claimFormItems.map((data) => {
            amount += data.amount;
            const body = {
              description: data.item,
              amount: data.amount,
              needReceipt: false,
              receiptUrl: data.receiptUrl,
              receiptName: '',
            };
            if (data.receiptUrl) {
              body.needReceipt = true;
            }
            return body;
          });
          reviewclaimFormForm.setFieldsValue({
            receipts_item: amounts,
          });
          setClaimFormTableData([
            {
              no: '1',
              description: 'Expenses Claim',
              quantity: amounts.length || 0,
              amount,
            },
          ]);
          setClaimFormItems(amounts);
        } else if (claimFormItems.length > 0) {
          reviewclaimFormForm.setFieldsValue({
            receipts_item: claimFormItems,
          });
        } else {
          const extractedClaimForm = await extractClaimForm(payment.id, token);
          let amount = 0;
          const amounts = extractedClaimForm.map((data) => {
            amount += data.amount;
            const body = {
              description: data.description,
              amount: data.amount,
              needReceipt: false,
              receiptUrl: '',
              receiptName: '',
            };
            return body;
          });
          reviewclaimFormForm.setFieldsValue({
            receipts_item: amounts,
          });
          setClaimFormTableData([
            {
              no: '1',
              description: 'Expenses Claim',
              quantity: amounts.length || 0,
              amount,
            },
          ]);
          setClaimFormItems(amounts);
        }
        setCurrent(current + 1);
        setCurrentPercent(percent + 20);
      } else {
        warningNoClaimForm();
      }
    } else if (current === 3) {
      const savedClaimItem = await saveReviewClaimForm();
      if (!skippedUploadTimeSheet) {
        if (savedClaimItem) {
          handleGetGeneratedRfp();
          setCurrent(current + 1);
          setCurrentPercent(percent + 20);
        } else {
          message.error('Please upload the missing receipt!');
        }
      } else {
        warningUploadTimesheet();
      }
    } else if (current === 4) {
      setDisableNextButton(true);
      message.info('Generating RFP... Please wait patiently..', 5);
      await axios(`v1/payments/${payment.id}/getMergedRfp`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
        .then((res) => {
          setPayment(res.data);
          setCurrent(current + 1);
          setCurrentPercent(percent + 20);
          setDisableNextButton(false);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      setCurrent(current + 1);
      setCurrentPercent(percent + 20);
    }
  };

  const prev = async () => {
    if (current === 0 && timesheeturl !== '') {
      setTimesheetURL('');
    } else if (current === 2 && claimForm) {
      warningClearClaimForm();
    } else if (current === 2 && timesheeturl === '') {
      setCurrent(0);
      setSkippedUploadTimeSheet(false);
      setCurrentPercent(0);
    } else {
      if (current === 2 && timesheeturl) {
        await readTimesheet();
      }

      if (current === 4 && claimForm) {
        setDisableNextButton(false);
        if (claimFormItems.length > 0) {
          reviewclaimFormForm.setFieldsValue({
            receipts_item: claimFormItems,
          });
        } else {
          const extractedClaimForm = await extractClaimForm(payment.id, token);
          let amount = 0;
          const amounts = extractedClaimForm.map((data) => {
            amount += data.amount;
            const body = {
              description: data.description,
              amount: data.amount,
              needReceipt: false,
              receiptUrl: '',
              receiptName: '',
            };
            return body;
          });
          reviewclaimFormForm.setFieldsValue({
            receipts_item: amounts,
          });
          setClaimFormTableData([
            {
              no: '1',
              description: 'Expenses Claim',
              quantity: amounts.length || 0,
              amount,
            },
          ]);
          setClaimFormItems(amounts);
        }
        setCurrent(current - 1);
        setCurrentPercent(percent - 20);
      } else if (skippedUploadClaimForm) {
        setCurrent(current - 2);
        setSkippedUploadClaimForm(false);
        setCurrentPercent(percent - 40);
      } else {
        setCurrent(current - 1);
        setCurrentPercent(percent - 20);
      }
    }
  };

  const onFinishForm = (val) => {
    const newClaimTotal = val.receipts_item.reduce((total, amount) => total + amount.amount, 0);
    setClaimFormTableData([
      {
        ...claimFormTableData[0],
        amount: newClaimTotal,
      },
    ]);
    setEditingClaim(false);
    message.success('Applied Changes');
  };

  const onApplyChanges = async (values) => {
    const { taxItems } = payment;
    const newTaxItems = taxItems.map((val) => {
      const taxItem = val;
      switch (taxItem.label) {
        case 'Socso (Employer)':
          taxItem.amount = values.socsoEmployer;
          break;
        case 'Socso (Employee)':
          taxItem.amount = values.socsoEmployee;
          break;
        case 'EPF (Employer)':
          taxItem.amount = values.epfEmployer;
          break;
        case 'EPF (Employee)':
          taxItem.amount = values.epfEmployee;
          break;
        case 'EIS (Employer)':
          taxItem.amount = values.eisEmployer;
          break;
        case 'EIS (Employee)':
          taxItem.amount = values.eisEmployee;
          break;
        case 'Witholding Tax (Employee)':
          taxItem.amount = values.withholdingTax;
          break;
        case 'PCB (Employee)':
          taxItem.amount = values.pcb;
          break;
        default:
          break;
      }
      return taxItem;
    });

    const config = {
      url: `/v1/payments/${payment.id}/getGeneratedRfp`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
      data: {
        consultantId: payment.consultantId,
        runPaymentDate: payment.runPaymentDate,
        taxItems: newTaxItems,
      },
    };

    if (customTax && customTax === 'employer') {
      config.data.employerCustomLabel = values.custom;
      config.data.employeeCustomLabel = {};
    }
    if (customTax && customTax === 'employee') {
      config.data.employeeCustomLabel = values.custom;
      config.data.employerCustomLabel = {};
    }
    if (!customTax) {
      config.data.employeeCustomLabel = {};
      config.data.employerCustomLabel = {};
    }

    await axios(config)
      .then((res) => {
        setPayment(res.data);
        setRfp(res.data.rfpUrl);
        message.success('Applied Changes to RFP');
      })
      .catch((err) => {
        console.log(err.response);
        message.error('Failed to save change');
      });
  };

  const UploadTimesheet = () => (
    <>{timesheeturl ? <ShowUploadedTimesheet /> : <UploadTimesheetDiv height="850px" />}</>
  );

  const UploadTimesheetDiv = () => (
    <Dragger {...timesheetProps}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag Excel / PDF file to this area to upload Timesheet
      </p>
      <p className="ant-upload-hint">Maximum file size is 5MB</p>
    </Dragger>
  );

  const ShowUploadedTimesheet = ({ height }) => (
    <>
      <iframe
        title="Timesheet"
        id="pdf-view"
        src={`${
          timesheeturl
            ? timesheeturl.split('.')[timesheeturl.split('.').length - 1] === 'pdf'
              ? timesheeturl
              : `https://view.officeapps.live.com/op/view.aspx?src=${timesheeturl}`
            : null
        }#view=fitH`}
        height={height || '850px'}
        width="100%"
      />
    </>
  );

  const TimesheetSummaryTable = () => (
    <Table columns={columns} dataSource={timesheetData} pagination={false} />
  );

  const ReviewTimesheet = () => (
    <>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 10,
        }}
        className="run-payment-upload-timesheet-header">
        <Col span={20}>
          <ShowUploadedTimesheet height="570px" />
        </Col>
        <Col span={4}>
          <Form
            form={timesheetTotalDaysForm}
            layout="vertical"
            style={{
              padding: '6px',
            }}
            onFinish={onChangeTimesheet}>
            {manDayRates.isMonthly ? (
              <Form.Item
                label="Total days"
                name="monthly"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <InputNumber
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
            ) : (
              <>
                <Form.Item
                  label="Total days Day Rate"
                  name="dayrate"
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Total days Onshore"
                  name="onshore"
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Total days Offshore"
                  name="offshore"
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Total days Quarantine"
                  name="quarantine"
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Total days Travel"
                  name="travel"
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Total days Standby"
                  name="standby"
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Apply Changes
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 10,
        }}
        className="run-payment-upload-timesheet-header">
        <Col span={16}>
          <TimesheetSummaryTable />
        </Col>
      </Row>
    </>
  );

  const UploadClaimFormDiv = () => (
    <Dragger {...claimFormProps} showUploadList>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag Excel / PDF file to this area to upload Claim Form
      </p>
      <p className="ant-upload-hint">Maximum file size is 5MB</p>
    </Dragger>
  );

  const ShowUploadedClaimForm = ({ height }) => (
    <>
      <iframe
        title="Claim Form"
        id="claim-form-pdf-view"
        src={`${
          claimForm
            ? claimForm.split('.')[claimForm.split('.').length - 1] === 'pdf'
              ? claimForm
              : `https://view.officeapps.live.com/op/view.aspx?src=${claimForm}`
            : null
        }#view=fitH`}
        height={height || '850px'}
        width="100%"
      />
    </>
  );

  const EditClaimForm = () => (
    <div style={{ overflow: 'auto', height: '550px' }}>
      <Form
        form={reviewclaimFormForm}
        onFinish={onFinishForm}
        layout="vertical"
        onValuesChange={() => {
          setEditingClaim(true);
          // reviewclaimFormForm.setFieldsValue(allFields);
          // const newClaimTotal = allFields
          //   .receipts_item.reduce((total, amount) => total + amount.amount, 0);
          // setClaimFormTableData([{
          //   ...claimFormTableData[0],
          //   amount: newClaimTotal,
          // }]);
        }}>
        <Form.List
          name="receipts_item"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          {(fields, { remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <>
                  <Row align="bottom">
                    <Col span={20}>
                      <Form.Item
                        label={(
                          <Row>
                            {`${claimFormItems[index].description}`}
                            <div style={{ alignItems: 'center', paddingLeft: 10 }}>
                              <DeleteOutlined onClick={() => remove(field.name)} />
                            </div>
                          </Row>
                        )}
                        {...field}
                        name={[field.name, 'amount']}
                        style={{ marginBottom: 5 }}
                        required={false}
                        key={field.key}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            // whitespace: false,
                            message: 'Please input amount',
                          },
                        ]}>
                        <InputNumber
                          key={field.key}
                          placeholder="Amount to claim"
                          style={{
                            width: '100%',
                          }}
                          step="0.01"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      {claimFormItems[index].needReceipt ? (
                        // <Upload {...props}>
                        <Upload name={`file-${index}`} {...props(index)}>
                          <Button
                            icon={<UploadOutlined />}
                            style={{ backgroundColor: '#f7931d', marginBottom: 5 }}
                            // onClick={() => {
                            //   setSelectedReceipt(index);
                            // }}
                          />
                        </Upload>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Checkbox
                      style={{ marginBottom: 5 }}
                      checked={claimFormItems[index].needReceipt}
                      onChange={() => {
                        const claimValue = reviewclaimFormForm.getFieldsValue().receipts_item;
                        const changed = claimValue.map((claim, i) => {
                          const val = claim;
                          // if unchecked, remove receipt url
                          if (i === index) {
                            const needReceipt = !val.needReceipt;
                            // eslint-disable-next-line no-param-reassign
                            val.needReceipt = needReceipt;
                            val.receiptUrl = null;
                          }
                          return val;
                        });
                        reviewclaimFormForm.setFieldsValue({
                          receipts_item: changed,
                        });
                        setClaimFormItems(changed);
                      }}>
                      Receipt required
                    </Checkbox>
                  </Row>
                  <Row>
                    {claimFormItems[index].receiptUrl ? (
                      <Tag
                        style={{ marginBottom: 5 }}
                        closable
                        icon={<PaperClipOutlined />}
                        closeIcon={<DeleteOutlined />}
                        onClose={() => {
                          const claimValue = reviewclaimFormForm.getFieldsValue().receipts_item;
                          const changed = claimValue.map((claim, i) => {
                            const val = claim;
                            // remove receipt item if onclicked delete icon
                            if (i === index) {
                              // const needReceipt = !val.needReceipt;
                              // eslint-disable-next-line no-param-reassign
                              // val.needReceipt = needReceipt;
                              val.receiptUrl = null;
                            }
                            return val;
                          });
                          reviewclaimFormForm.setFieldsValue({
                            receipts_item: changed,
                          });
                          setClaimFormItems(changed);
                        }}>
                        {`Receipt ${index + 1}`}
                      </Tag>
                    ) : null}
                  </Row>
                </>
              ))}
              <Form.Item>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );

  const ClaimSummaryTable = () => (
    <Table
      columns={ClaimSummaryColumns}
      dataSource={claimFormTableData}
      pagination={false}
      // scroll={{
      //   overflow: 'auto',
      //   y: 240,
      // }}
    />
  );

  const ReviewClaimForm = () => (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={19}>{ShowUploadedClaimForm({ height: '600px' })}</Col>
        <Col span={5}>
          {EditClaimForm()}
          <Button
            type="dashed"
            onClick={() => {
              const { amount } = claimFormTableData[0];
              const values = reviewclaimFormForm.getFieldsValue().receipts_item;
              values.push({
                description: `Item ${values.length + 1}`,
                amount: 0,
                needReceipt: false,
                receiptUrl: '',
                receiptName: '',
              });
              reviewclaimFormForm.setFieldsValue({
                receipts_item: values,
              });
              setClaimFormTableData([
                {
                  no: '1',
                  description: 'Expenses Claim',
                  quantity: values.length || 0,
                  amount,
                },
              ]);
              setClaimFormItems(values);
            }}
            style={{
              width: '100%',
              marginTop: '5px',
            }}
            icon={<PlusOutlined />}>
            Add Item
          </Button>
          <Button
            type="primary"
            style={{ marginTop: '5px' }}
            onClick={() => {
              reviewclaimFormForm.submit();
            }}
            disabled={!editingClaim}>
            Apply Amount Changes
          </Button>
        </Col>
      </Row>
      <Row style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
        {ClaimSummaryTable()}
      </Row>
    </div>
  );

  const ShowRFP = ({ height }) => (
    <>
      <iframe
        title="RFP"
        id="rfp-form-pdf-view"
        src={`${rfp || null}#view=fitH`}
        height={height || '850px'}
        width="100%"
      />
    </>
  );

  const onAddCustom = (val) => {
    setCustomTax(val);
  };

  const EditTaxes = () => (
    <Form
      form={form}
      layout="vertical"
      name="edit-taxes-info"
      onFinish={onApplyChanges}
      validateMessages={validateMessages}>
      <Form.Item label="PCB (Employee)" name="pcb">
        <InputNumber
          prefix="RM"
          style={{
            width: '100%',
          }}
          precision={2}
        />
      </Form.Item>
      <Form.Item
        label="Withholding Tax (Employee)"
        name="withholdingTax"
        rules={[
          {
            required: true,
          },
        ]}
        defaultValue={0}>
        <InputNumber
          prefix="RM"
          style={{
            width: '100%',
          }}
          precision={2}
          defaultValue={0}
        />
      </Form.Item>
      <Form.Item
        label="Socso (Employer)"
        name="socsoEmployer"
        rules={[
          {
            required: true,
          },
        ]}>
        <InputNumber
          prefix="RM"
          style={{
            width: '100%',
          }}
          precision={2}
        />
      </Form.Item>
      <Form.Item
        label="Socso (Employee)"
        name="socsoEmployee"
        rules={[
          {
            required: true,
          },
        ]}>
        <InputNumber
          prefix="RM"
          style={{
            width: '100%',
          }}
          precision={2}
        />
      </Form.Item>
      <Form.Item
        label="EPF (Employer)"
        name="epfEmployer"
        rules={[
          {
            required: true,
          },
        ]}>
        <InputNumber
          prefix="RM"
          style={{
            width: '100%',
          }}
          precision={2}
        />
      </Form.Item>
      <Form.Item
        label="EPF (Employee)"
        name="epfEmployee"
        rules={[
          {
            required: true,
          },
        ]}>
        <InputNumber
          prefix="RM"
          style={{
            width: '100%',
          }}
          precision={2}
        />
      </Form.Item>
      <Form.Item
        label="EIS (Employer)"
        name="eisEmployer"
        rules={[
          {
            required: true,
          },
        ]}>
        <InputNumber
          prefix="RM"
          style={{
            width: '100%',
          }}
          precision={2}
        />
      </Form.Item>
      <Form.Item
        label="EIS (Employee)"
        name="eisEmployee"
        rules={[
          {
            required: true,
          },
        ]}>
        <InputNumber
          prefix="RM"
          style={{
            width: '100%',
          }}
          precision={2}
        />
      </Form.Item>
      <Form.Item
        style={{ width: '100%', textAlign: 'justify' }}
        label="Add Custom"
        name="customType"
        rules={[{ required: false }]}
        defaultValue={customTax}>
        <Select
          placeholder="Please select"
          allowClear
          onChange={onAddCustom}
          defaultValue={customTax}>
          <Option value="employer">Employer</Option>
          <Option value="employee">Employee</Option>
        </Select>
      </Form.Item>
      {customTax && (
        <>
          <Form.Item
            label="Enter Custom Label"
            name={['custom', 'label']}
            rules={[
              {
                required: customTax,
              },
            ]}
            defaultValue={
              payment.employerCustomLabel
                ? payment.employerCustomLabel.label
                : payment.employeeCustomLabel
                  ? payment.employeeCustomLabel.label
                  : ''
            }>
            <Input
              maxLength={20}
              defaultValue={
                payment.employerCustomLabel
                  ? payment.employerCustomLabel.label
                  : payment.employeeCustomLabel
                    ? payment.employeeCustomLabel.label
                    : ''
              }
            />
          </Form.Item>
          <Form.Item
            label="Enter Custom Amount"
            name={['custom', 'amount']}
            rules={[
              {
                required: customTax,
              },
            ]}
            defaultValue={
              payment.employerCustomLabel
                ? payment.employerCustomLabel.amount
                : payment.employeeCustomLabel
                  ? payment.employeeCustomLabel.amount
                  : 0
            }>
            <InputNumber
              prefix="RM"
              style={{
                width: '100%',
              }}
              precision={2}
              defaultValue={
                payment.employerCustomLabel
                  ? payment.employerCustomLabel.amount
                  : payment.employeeCustomLabel
                    ? payment.employeeCustomLabel.amount
                    : 0
              }
            />
          </Form.Item>
        </>
      )}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Apply Changes
        </Button>
      </Form.Item>
    </Form>
  );

  const ChooseTaxes = () => (
    <Row gutter={[16, 16]}>
      <Col span={18}>{rfp ? ShowRFP(500) : null}</Col>
      <Col span={6}>{payment.isTax ? EditTaxes() : null}</Col>
    </Row>
  );

  const PreviewRFP = () => (
    <>
      {payment ? (
        <Row>
          <Col span={12}>
            {payment.rfpUrl ? (
              <iframe
                title="Full RFP"
                id="pdf-view-full-rfp"
                src={`${payment.rfpUrl || null}#view=fitH`}
                height="550px"
                width="100%"
              />
            ) : null}
          </Col>
          <Col span={12}>
            {payment.supportingUrl ? (
              <iframe
                title="Full RFP"
                id="pdf-view-full-rfp"
                src={`${payment.supportingUrl || null}#view=fitH`}
                height="550px"
                width="100%"
              />
            ) : null}
          </Col>
        </Row>
      ) : null}
    </>
  );

  const onShowStepContent = (currentStep) => {
    switch (currentStep) {
      case 0:
        return <UploadTimesheet />;
      case 1:
        return <ReviewTimesheet />;
      case 2:
        return claimForm ? ShowUploadedClaimForm(500) : <UploadClaimFormDiv />;
      case 3:
        return <ReviewClaimForm />;
      case 4:
        return <ChooseTaxes />;
      case 5:
        return <PreviewRFP />;
      default:
        return '';
    }
  };

  const onSubmitReject = async (values) => {
    const config = {
      url: `v1/payments/${payment.id}/update`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
      data: {
        runPaymentDate: payment.runPaymentDate,
        consultantId: payment.consultantId,
        remarks: values.remarks,
      },
    };

    await axios(config)
      .then(async (res) => {
        if (res.status === 200) {
          setPayment(res.data);
          setTimeout(() => {
            // setSubmitting(false);
            setValue('');
            setComments([...comments]);
          }, 1000);
          config.url = `v1/payments/${payment.id}/reject`;
          delete config.data;
          await axios(config)
            .then(() => {
              history.push(`/consultant-management/details?id=${payment.consultantId}&tab=1`);
              message.success('RFP Rejected');
            })
            .catch((err) => {
              console.error(err);
              message.error('Failed to Reject RFP!');
            });
        } else {
          message.error('Failed to Reject RFP!');
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('Failed to Reject RFP!');
      });
  };

  const RemarksCommentBox = () => (
    <Form form={rejectForm} onFinish={onSubmitReject}>
      <Form.Item
        name="remarks"
        rules={[
          {
            required: true,
          },
        ]}>
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  );

  const BreadCrumbDiv = () => (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/run-payment">Run Payment</a>
        </Breadcrumb.Item>
      </Breadcrumb>
    </>
  );

  const PageHeaderDiv = () => <PageHeader title="Run Payment" className="basic-pageheader" />;

  return (
    <>
      <div className="breadcrumb-layout">
        <BreadCrumbDiv />
        <PageHeaderDiv />
      </div>

      <Content className="layout-background">
        <div className="run-payment-stepper-container">
          <div className="run-payment-steps-selections">
            <Steps current={current} percent={percent}>
              <Step
                key={0}
                title="Upload Timesheet"
                status={
                  // userType !== 'authorizer'
                  timesheeturl === ''
                    ? skippedUploadTimeSheet === true
                      ? 'error'
                      : current === 0
                        ? 'process'
                        : 'pending'
                    : current === 0
                      ? 'process'
                      : 'finish'
                  // : 'finish'
                }
              />
              <Step
                key={1}
                title="Review Timesheet"
                status={
                  // userType !== 'authorizer'
                  timesheeturl === ''
                    ? skippedUploadTimeSheet === true
                      ? 'error'
                      : current === 1
                        ? 'process'
                        : 'pending'
                    : current < 1 && payment.paymentRate.length === 0
                      ? 'pending'
                      : current === 1
                        ? 'process'
                        : 'finish'
                  // : 'finish'
                }
              />
              <Step
                key={2}
                title="Upload Claim Form"
                status={
                  // userType !== 'authorizer'
                  claimForm === ''
                    ? current < 2
                      ? 'pending'
                      : current === 2
                        ? 'process'
                        : 'pending'
                    : current < 2 || current > 2
                      ? 'finish'
                      : 'process'
                  // : 'finish'
                }
              />
              <Step
                key={3}
                title="Review Claim Form"
                status={
                  // userType !== 'authorizer'
                  claimForm === ''
                    ? current < 3
                      ? 'pending'
                      : current === 3
                        ? 'process'
                        : 'pending'
                    : current > 3
                      ? 'finish'
                      : current === 3
                        ? 'process'
                        : current === 2
                          ? 'pending'
                          : 'finish'
                  // : 'finish'
                }
              />
              <Step
                key={4}
                title="Choose Taxes"
                status={
                  // userType !== 'authorizer'
                  claimForm === ''
                    ? 'pending'
                    : current > 4
                      ? 'finish'
                      : current === 4
                        ? 'process'
                        : current === 2 || current === 3
                          ? 'pending'
                          : 'finish'
                  // : 'finish'
                }
              />
              <Step
                key={5}
                title={userType === 'authorizer' ? 'Review RFP' : 'Preview RFP'}
                status={current > 5 ? 'finish' : current === 5 ? 'process' : 'pending'}
              />
            </Steps>
          </div>
          <div className="run-payment-steps-content">{onShowStepContent(current)}</div>
          <Row className="run-payment-steps-action">
            <Col>
              {current >= 3
              && current < 5
              && ((current === 4 && payment.isTax) || current !== 4) ? (
                <Button
                  className="run-payment-save-draft-button"
                  type="primary"
                  style={{
                    margin: '0 8px',
                  }}
                  onClick={current === 4 ? form.submit : onSaveDraft}>
                  Save as draft
                </Button>
                ) : null}

              {userType !== 'authorizer' && current === 5 && (
                <Button
                  type="primary"
                  style={{
                    margin: '0 8px',
                  }}
                  onClick={() => onEditRunPayment()}>
                  Edit
                </Button>
              )}
            </Col>

            <Col>
              {((current > 0 && current < 5) || (current === 0 && timesheeturl)) && (
                <Button
                  type="primary"
                  style={{
                    margin: '0 8px',
                  }}
                  onClick={() => prev()}>
                  Back
                </Button>
              )}
              {userType === 'moderator' && current < 5 && current === 2 ? (
                <Button
                  type="primary"
                  disabled={uploadingTimesheet === true && !claimForm}
                  onClick={() => next()}>
                  Next
                </Button>
              ) : (
                <>
                  {userType === 'administrator' && disabledNextButton === true && current !== 5 ? (
                    <Button type="primary" onClick={() => next()} disabled>
                      Next
                    </Button>
                  ) : null}

                  {userType === 'administrator' && disabledNextButton === false && current !== 5 ? (
                    <Button type="primary" onClick={() => next()}>
                      Next
                    </Button>
                  ) : null}
                </>
              )}

              {payment
                && (payment.status === 'incomplete' || payment.status === 'rejected')
                && current === 5
                && percent === 100 && (
                  <Button type="primary" onClick={() => onSubmitRFP()}>
                    {payment.status === 'incomplete' ? 'Submit' : 'Resubmit'}
                  </Button>
              )}
              {current === 6 - 1 && current < 5 && (
                <Button type="primary" onClick={() => message.success('Processing complete!')}>
                  Done
                </Button>
              )}

              {userType === 'authorizer'
                && payment
                && payment.status === 'pending'
                && current === 5 && (
                  <>
                    <Button type="danger" onClick={() => onReject()}>
                      Reject
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => onApprove()}
                      style={{ marginLeft: '5px' }}>
                      Approve
                    </Button>
                  </>
              )}
            </Col>
          </Row>
        </div>
      </Content>
    </>
  );
}
