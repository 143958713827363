import React, { useState, useEffect } from 'react';
import { Row, Col, message, Spin, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import axios from 'axios';
import moment from 'moment';

import LineChartRenderer from './charts/FinancialPerformanceLineChart';
import '../../pages/dashboard/Dashboard.css';
import { getToken } from '../../utils/AuthService';

export default function FinancialPerformanceOverviewChart() {
  const token = getToken();

  const currentYear = new Date().getFullYear();

  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [loadingFinancialPerformanceData, setLoadingFinancialPerformanceData] = useState(false);
  const [financialPerformanceData, setFinancialPerformanceData] = useState([]);

  useEffect(() => {
    const fetchDashboardStatisticsData = async () => {
      setLoadingFinancialPerformanceData(true);
      await axios
        .get(`v1/dashboard/financial-performance?selectedDate=${selectedYear}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((results) => {
          const originaldata = results.data.result;
          const newArr = [];

          originaldata.map((e) => {
            switch (e._id) {
              case 1:
                newArr.push({
                  month: 'Jan',
                  type: e.type,
                  amount: e.amount,
                });
                break;
              case 2:
                newArr.push({
                  month: 'Feb',
                  type: e.type,
                  amount: e.amount,
                });
                break;
              case 3:
                newArr.push({
                  month: 'Mar',
                  type: e.type,
                  amount: e.amount,
                });
                break;
              case 4:
                newArr.push({
                  month: 'Apr',
                  type: e.type,
                  amount: e.amount,
                });
                break;
              case 5:
                newArr.push({
                  month: 'May',
                  type: e.type,
                  amount: e.amount,
                });
                break;
              case 6:
                newArr.push({
                  month: 'Jun',
                  type: e.type,
                  amount: e.amount,
                });
                break;
              case 7:
                newArr.push({
                  month: 'July',
                  type: e.type,
                  amount: e.amount,
                });
                break;
              case 8:
                newArr.push({
                  month: 'Aug',
                  type: e.type,
                  amount: e.amount,
                });
                break;
              case 9:
                newArr.push({
                  month: 'Sept',
                  type: e.type,
                  amount: e.amount,
                });
                break;
              case 10:
                newArr.push({
                  month: 'Oct',
                  type: e.type,
                  amount: e.amount,
                });
                break;
              case 11:
                newArr.push({
                  month: 'Nov',
                  type: e.type,
                  amount: e.amount,
                });
                break;
              case 12:
                newArr.push({
                  month: 'Dec',
                  type: e.type,
                  amount: e.amount,
                });
                break;
              default:
                break;
            }
          });
          setFinancialPerformanceData(newArr);
        })
        .catch((error) => {
          message.error(`Failed to retrive dashboard financial performance data. ${error}`);
        });
    };

    fetchDashboardStatisticsData();
    setLoadingFinancialPerformanceData(false);
  }, [selectedYear]);

  const DropdownFilter = () => (
    <DatePicker 
      defaultValue={moment(selectedYear, 'YYYY')}
      picker="year"
      style={{
        width: 120,
      }}
      clearIcon={false}
      onChange={(dateString) => setSelectedYear(dateString)}
    />
  );

  return (
    <div className="financial-performance-overview-contents">
      <Row className="financial-performance-header">
        <Col className="dashboard-section-title">Financial Performance Overview</Col>
        <Col>
          <DropdownFilter />
        </Col>
      </Row>
      {loadingFinancialPerformanceData === false ? (
        financialPerformanceData.length !== 0 ? (
          <LineChartRenderer data={financialPerformanceData} />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              padding: '200px 0 0 0',
            }}
          >
            <p>No data to display</p>
          </div>
        )
      ) : (
        <Spin
          indicator={(
            <LoadingOutlined
              style={{
                fontSize: 24,
              }}
              spin
            />
          )}
        />
      )}
    </div>
  );
}
