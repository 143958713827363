import React, { useState } from 'react';
import { Form, Input, Button, Col, Row, Card, Avatar, Checkbox, message } from 'antd';
import moment from 'moment';
import QualificationModal from '../../components/register/QualificationModal';
import educationAvatar from '../../assets/images/register_page_education_avatar.svg';
import './Register.css';
import '../../styles/general.css';

const BankAndQualificationDetailsForm = (props) => {
  const { stepChangeHandler } = props;
  const [isAddQualificationModalVisible, setIsAddQualificationModalVisible] = useState(false);
  const [isEditQualificationModalVisible, setIsEditQualificationModalVisible] = useState(false);
  const [qualifications, setQualifications] = useState(() => {
    if (localStorage.getItem('qualifications')) {
      const qualificationsFromLocalStorage = JSON.parse(localStorage.getItem('qualifications'));

      // Convert date string to moment object
      qualificationsFromLocalStorage.forEach((qualificationFromLocalStorage) => {
        // eslint-disable-next-line no-param-reassign
        qualificationFromLocalStorage.duration[0] = moment(
          qualificationFromLocalStorage.duration[0],
        );
        // eslint-disable-next-line no-param-reassign
        qualificationFromLocalStorage.duration[1] = moment(
          qualificationFromLocalStorage.duration[1],
        );
      });
      return qualificationsFromLocalStorage;
    }
    return [];
  });
  const [qualificationToEdit, setQualificationToEdit] = useState({});
  const [isUseDifferentName, setIsUseDifferentName] = useState(false);

  const [form] = Form.useForm();

  const toggleAddQualificationModal = () => {
    setIsAddQualificationModalVisible(!isAddQualificationModalVisible);
  };

  const toggleEditQualificationModal = (qualification) => {
    setIsEditQualificationModalVisible(!isEditQualificationModalVisible);
    if (qualification) {
      setQualificationToEdit(qualification);
    }
  };

  const addQualification = (value) => {
    const newQualification = {
      ...value,
      id: qualifications.length + 1,
    };
    setQualifications((previousQualifications) => [...previousQualifications, newQualification]);
  };

  const editQualification = (value) => {
    const newQualifications = qualifications.map((qualification) => {
      if (qualification.id === qualificationToEdit.id) {
        return {
          ...value,
          id: qualification.id,
        };
      }
      return qualification;
    });
    setQualifications(newQualifications);
  };

  const deleteQualification = (id) => {
    const newQualifications = qualifications.filter((qualification) => qualification.id !== id);
    setQualifications(newQualifications);
  };

  const displayYearStudied = (startStudyDate, endStudyDate) => {
    const startYear = startStudyDate.format('YYYY');
    const endYear = endStudyDate.format('YYYY');
    return `${startYear} - ${endYear}`;
  };

  if (localStorage.getItem('bankDetails')) {
    const bankDetails = JSON.parse(localStorage.getItem('bankDetails'));
    form.setFieldsValue({
      bankName: bankDetails.bankName,
      accountName: bankDetails.accountName,
      accountNumber: bankDetails.accountNumber,
      swiftCode: bankDetails.swiftCode,
      bankBranch: bankDetails.bankBranch,
      epfNumber: bankDetails.epfNumber,
      socsoNumber: bankDetails.socsoNumber,
      pcbNumber: bankDetails.pcbNumber,
    });

    // Use account name from the personal details form
  }

  if (!isUseDifferentName) {
    form.setFieldsValue({
      accountName: JSON.parse(localStorage.getItem('personalDetails')).employeeName.toUpperCase(),
    });
  }

  const saveToLocalStorage = () => {
    localStorage.setItem('bankDetails', JSON.stringify(form.getFieldsValue()));
    localStorage.setItem('qualifications', JSON.stringify(qualifications));
    message.success('Bank details and qualifications saved successfully');
  };

  return (
    <>
      <Form
        form={form}
        name="register"
        layout="vertical"
        validateMessages={{
          required: '${label} is required!',
          types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
          },
          number: {
            range: '${label} must be between ${min} and ${max}',
          },
        }}
      >
        <div
          style={{
            background: '#EEEEEE',
            padding: '30px',
          }}
        >
          <h2>Bank Details</h2>
          <Row justify="end">
            <Col>
              <Checkbox
                name="useDifferentName"
                onChange={(e) => {
                  setIsUseDifferentName(e.target.checked);
                }}
              >
                Use different name
              </Checkbox>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="bankName"
                label="Bank Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Bank Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="accountName"
                label="Account Name"
                style={{
                  marginBottom: '0px',
                }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input 
                  placeholder="Account Name" 
                  disabled={!isUseDifferentName}
                  onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }}
                />
              </Form.Item>
              <div style={{ color: 'grey', fontSize: '14px' }}>
                *I hereby acknowledge that the bank details I entered is correct
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24} sm={8}>
              <Form.Item
                name="accountNumber"
                label="Account Number"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Account Number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                name="swiftCode"
                label="Swift Code"
                style={{
                  marginBottom: '0px',
                }}
                rules={[
                  {
                    pattern: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
                    message: 'Invalid swift code',
                  },
                ]}
              >
                <Input placeholder="AAAABBCC123" />
              </Form.Item>
              <div style={{ color: 'grey', fontSize: '14px' }}>*for overseas account only</div>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                name="bankBranch"
                label="Bank Branch"
                style={{
                  marginBottom: '0px',
                }}
                rules={[
                  {
                    type: 'string',
                    min: 1,
                  },
                ]}
              >
                <Input placeholder="Bank Branch" />
              </Form.Item>
              <div style={{ color: 'grey', fontSize: '14px' }}>*for overseas account only</div>
            </Col>
          </Row>
          <h2>Contribution Details (For Malaysian)</h2>
          <Row gutter={12}>
            <Col xs={24} sm={8}>
              <Form.Item
                name="epfNumber"
                label="EPF Number"
                rules={[
                  {
                    pattern: new RegExp('^[0-9]*$'),
                  },
                ]}
              >
                <Input placeholder="EPF Number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                name="socsoNumber"
                label="Sosco Number"
              >
                <Input placeholder="Socso Number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                name="pcbNumber"
                label="PCB Number"
              >
                <Input placeholder="PCB Number" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col>
              <h2
                style={{
                  marginBottom: '0px',
                }}
              >
                Qualification (optional)
              </h2>
              <div
                style={{
                  color: 'grey',
                  fontSize: '14px',
                }}
              >
                *Click on the Name of Institution, College & University to edit their information
              </div>
            </Col>
            <Col>
              <Button
                shape="circle"
                icon="+"
                className="add-family-member-button"
                onClick={() => {
                  saveToLocalStorage();
                  toggleAddQualificationModal();
                }}
              />
            </Col>
          </Row>
          <div
            style={{
              height: '8px',
            }}
          />
          {qualifications.length > 0 ? (
            qualifications.map((qualification) => (
              <Card
                className="family-member-card"
                onClick={() => {
                  toggleEditQualificationModal(qualification);
                }}
                hoverable
                key={qualification.id}
              >
                <Row justify="space-between" align="middle">
                  <Col>
                    <Avatar size={40} src={educationAvatar} />
                  </Col>
                  <div style={{ width: '13px' }} />
                  <Col>
                    <h3
                      style={{
                        color: '#000000',
                        textAlign: 'left',
                      }}
                    >
                      {qualification.name}
                    </h3>
                    <p>
                      {displayYearStudied(qualification.duration[0], qualification.duration[1])} |{' '}
                      {qualification.grade}
                    </p>
                  </Col>
                  <Col flex="auto" />
                </Row>
              </Card>
            ))
          ) : (
            <Row
              style={{
                width: '100%',
                background: '#FFFFFF',
                color: '#838383',
                padding: '12px',
              }}
              justify="center"
            >
              Please add Qualification by clicking the ‘+’ Icon
            </Row>
          )}
          <div
            style={{
              height: '35px',
            }}
          />
          <Row>
            <Col>
              <Form.Item>
                <Button type="secondary" onClick={saveToLocalStorage}>
                  Save changes
                </Button>
              </Form.Item>
            </Col>
            <Col flex="auto" />
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  saveToLocalStorage();
                  stepChangeHandler(1);
                }}
              >
                Back
              </Button>
            </Col>
            <div style={{ width: '12px' }} />
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  form.validateFields().then(() => {
                    saveToLocalStorage();
                    stepChangeHandler(3);
                  });
                }}
              >
                Next
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <QualificationModal
        isModalVisible={isAddQualificationModalVisible}
        title="Add field of study"
        toggleModal={toggleAddQualificationModal}
        addCallback={addQualification}
      />
      <QualificationModal
        isModalVisible={isEditQualificationModalVisible}
        title="Edit field of study"
        toggleModal={toggleEditQualificationModal}
        qualificationToEdit={qualificationToEdit}
        editCallback={editQualification}
        deleteCallback={deleteQualification}
      />
    </>
  );
};

export default BankAndQualificationDetailsForm;
