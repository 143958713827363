import axios from 'axios';

const pdfjs = require('pdfjs-dist/legacy/build/pdf');
const pdfjsworker = require('pdfjs-dist/legacy/build/pdf.worker.entry');
const XLSX = require('xlsx');

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsworker;

export default async function extractTimesheet(paymentId, token) {
  const { bufferData, contentType } = await axios.get(`/v1/payments/${paymentId}/getTimesheet`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access.token}`,
    },
  })
    .then((res) => {
      const data = {
        bufferData: res.data.Body.data,
        body: res.data.Body,
        contentType: res.data.ContentType,
      };
      return data;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });

  const extractedTimesheets = {
    day: 0,
    Onshore: 0,
    Offshore: 0,
    Travel: 0,
    Quarantine: 0,
    Standby: 0,
  };
  
  if (contentType === 'application/pdf') {
    const pdfDoc = pdfjs
      .getDocument({ data: bufferData })
      .promise.then((data) => data)
      .catch((err) => {
        console.log(err);
        return err;
      });
    // console.log(`Display pdf: ${JSON.stringify(pdfDoc)}`);
    const pdfPage = await (await pdfDoc).getPage(1);
    const pageContent = await pdfPage.getTextContent();
  
    let startTableHeader = false;
    let startExtract = false;
    let currentLocation;
  
    pageContent.items.forEach((data, i, arr) => {
      try {
        if (startExtract) {
          if (
            // eslint-disable-next-line no-restricted-globals
            isNaN(data.str)
            && data.str !== 'DAYS'
            && data.str
            && Object.prototype.hasOwnProperty.call(extractedTimesheets, data.str)
          ) {
            currentLocation = data.str;
          }
  
          if (
            currentLocation
            && arr[i + 2].str !== 'DAYS'
            && data.str !== ' '
            && data.str !== ''
            && data.str !== currentLocation
          ) {
            // console.log(data)
            extractedTimesheets[currentLocation] += 1;
          }
  
          if (arr[i + 2].str === 'DAYS') {
            currentLocation = null;
          }
  
          if (arr[i + 2].str === 'DAYS' && data.str !== 'DAYS' && currentLocation) {
            // worksiteData.totalDays = parseInt(data.str);
            if (extractedTimesheets[currentLocation] === parseInt(data.str, 10)) {
              console.log('Matched Total Days');
            } else {
              console.log('Unmatched Total Days');
            }
          }
  
          if (data.hasEOL) {
            currentLocation = null;
          }
  
          if (data.hasEOL && arr[i + 1].str === 'DAYS' && arr[i + 3].str === 'DAYS') {
            startExtract = false;
          }
        }
  
        if (!startExtract) {
          if (!startTableHeader && (data.str === 'WORK SITE' || (data.str === 'SITE' && arr[i - 2].str === 'WORK'))) {
            startTableHeader = true;
          }
  
          if (startTableHeader && data.hasEOL) {
            startExtract = true;
            startTableHeader = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  } else {
    const workbook = XLSX.read(bufferData, { type: 'buffer' });
    // console.log(workbook);
    const worksheet = workbook.Sheets.Sheet1;
    // console.log(worksheet);
    const kvPairs = Object.entries(worksheet);
    const filteredAColumns = kvPairs.filter(
      (val) => val[0].match(/[A]{1}[0-9]+/g),
    );
    const filteredAIColumns = kvPairs.filter(
      (val) => val[0].match(/[A]{1}[I]{1}[0-9]+/g),
    );
    const filteredARows = filteredAColumns.filter((val) => {
      const trimmedKey = val[0].slice(1);
      const parsedKey = parseInt(trimmedKey, 10);
      return parsedKey > 8 && parsedKey < 21;
    });
    const filteredAIRows = filteredAIColumns.filter((val) => {
      const trimmedKey = val[0].slice(2);
      const parsedKey = parseInt(trimmedKey, 10);
      return parsedKey > 8 && parsedKey < 21;
    });
    filteredARows.forEach((val, i) => {
      extractedTimesheets[val[1].w] += parseFloat(filteredAIRows[i][1].w);
    });
  }

  return extractedTimesheets;
}
