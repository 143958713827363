import React, { useState, useEffect } from 'react';
import { Row, Divider, Col, message, Spin, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import axios from 'axios';
import moment from 'moment';

import PSLPieRenderer from './charts/PSLPieChart';
import { getToken } from '../../utils/AuthService';

export default function PSLDistributionChart() {
  const token = getToken();

  const currentYear = new Date().getFullYear();

  const [selectedFilter, setSelectedFilter] = useState(currentYear.toString());
  const [loadingPSLDistributionData, setLoadingPSLDistributionData] = useState(false);
  const [PSLDistributionData, setPSLDistributionData] = useState([]);

  const onChangeDate = (date) => {
    setSelectedFilter(moment(date._d).format('YYYY'));
  };

  useEffect(() => {
    const fetchPSLDistributionData = async () => {
      setLoadingPSLDistributionData(true);
      await axios
        .get(`v1/dashboard/psl-distribution?selectedDate=${selectedFilter}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((results) => {
          setPSLDistributionData(results.data.result);
        })
        .catch((error) => {
          message.error(`Failed to retrive psl distribution data. ${error}`);
        });
    };

    fetchPSLDistributionData();
    setLoadingPSLDistributionData(false);
  }, [selectedFilter]);

  return (
    <div className="psl-distribution-contents">
      <Row className="psl-distribution-header">
        <Col className="dashboard-section-title">PSL Distribution</Col>
        <Col>
          <DatePicker
            defaultValue={moment()}
            format="YYYY"
            picker="year"
            onChange={onChangeDate}
            clearIcon={false}
          />
        </Col>
      </Row>
      <Divider />
      {loadingPSLDistributionData === false ? (
        PSLDistributionData.length !== 0 ? (
          <PSLPieRenderer data={PSLDistributionData} />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              padding: '200px 0 0 0',
            }}
          >
            <p>No Income</p>
          </div>
        )
      ) : (
        <Spin
          indicator={(
            <LoadingOutlined
              style={{
                fontSize: 24,
              }}
              spin
            />
          )}
        />
      )}
    </div>
  );
}
