import React, { useEffect, useState } from 'react';

import { Breadcrumb, PageHeader, Layout, Button, Select, Form, DatePicker, message } from 'antd';

import axios from 'axios';

import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { Option } = Select;

const RunPaymentMainPage = (props) => {
  const [form] = Form.useForm();
  const token = getToken();

  const [runPaymentDate, setrunPaymentDate] = useState('');
  const [consultantsInfo, setConsultantsInfo] = useState([]);
  const [selectedConsultant, setSelectConsultant] = useState();
  const [loadingAvailableConsultantsInfo, setLoadingAvailableConsultantsInfo] = useState(false);

  useEffect(() => {
    const getConsultants = async () => {
      setLoadingAvailableConsultantsInfo(true);
      await axios
        .get(`v1/payments/getConsultantList?runPaymentDate=${runPaymentDate}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit: 9999999,
          },
        })
        .then((results) => {
          const consultantsData = results.data;
          setConsultantsInfo(consultantsData);
        })
        .catch((error) => {
          message.error(`Failed to retrive consultants data. ${error}`);
        });
    };
    getConsultants();
    setLoadingAvailableConsultantsInfo(false);
  }, [runPaymentDate]);

  const validateMessages = {
    required: '${label} is required!',
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinishSelect = (values) => {
    const jsonBody = {
      runPaymentDate: values.date,
      consultantId: selectedConsultant.consultantId,
      calloutId: selectedConsultant.calloutId,
      positionId: selectedConsultant._id, // this consulatan id not position id
      mandayRateSet: selectedConsultant.mandayRateSet,
    };

    axios
      .post('v1/payments', jsonBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
      .then((res) => {
        console.log('res.data: ', res.data);
        props.history.push(
          '/run-payment/add-new',
          {
            payment: res.data,
          },
        );
      })
      .catch((error) => {
        message.error(`Failed to create new rfp  data. ${error}`, [15]);
      });
  };

  const BreadCrumbDiv = () => (
    <Breadcrumb>
      <Breadcrumb.Item>
        <a href="/run-payment">RFP</a>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const PageHeaderDiv = () => <PageHeader title="RFP" className="basic-pageheader" />;

  return (
    <>
      <div className="breadcrumb-layout">
        <BreadCrumbDiv />
        <PageHeaderDiv />
      </div>

      <Content className="layout-background">
        <div className="main-runpayment-content">
          <Form
            form={form}
            layout="vertical"
            name="run-payment-step1"
            onFinish={onFinishSelect}
            validateMessages={validateMessages}
          >
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                onChange={(val) => {
                  setrunPaymentDate(val);
                }}
                placeholder="Select date"
                picker="month"
                style={{ width: 450 }}
              />
            </Form.Item>
            <Form.Item label="Name" name="consultantId" rules={[{ required: true }]}>
              {loadingAvailableConsultantsInfo === false ? (
                <Select
                  allowClear
                  placeholder="Please select"
                  onChange={(val) => {
                    const searchID = val.slice(0, 24);
                    const filteredArray = consultantsInfo.filter((itm) => (
                      searchID.indexOf(itm._id) > -1
                    ));
                    setSelectConsultant(filteredArray[0]);
                  }}
                  disabled={runPaymentDate === ''}
                  showSearch
                >
                  {consultantsInfo.map((val) => (
                    <Option key={val._id} value={val._id + val.name}>
                      {val.name} - {val.mandayRateSet} - {val.contractNumber}
                    </Option>
                  ))}
                </Select>
              ) : null}
            </Form.Item>
          </Form>
          <Button
            className="orange-button"
            disabled={selectedConsultant === ''}
            type="primary"
            htmlType="submit"
            onClick={form.submit}
          >
            Run Payment
          </Button>
        </div>
      </Content>
    </>
  );
};

export default RunPaymentMainPage;
