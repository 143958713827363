import React, { useState, useEffect } from 'react';

import { Divider, Row, Tabs, List, Badge, message, Typography } from 'antd';
import {
  FileExclamationOutlined, 
  UserAddOutlined, 
  ExceptionOutlined, 
  DollarCircleOutlined,
  AlertOutlined,
} from '@ant-design/icons';

import axios from 'axios';
import moment from 'moment';

import { getToken } from '../../utils/AuthService';

const { Text } = Typography;

export default function ToDoNotificationList() {
  const token = getToken();

  const [selectedTab, setSelectedTab] = useState('1');
  const [loadingAdministratorTodo, setLoadingAdministratorTodo] = useState(false);
  const [administratorTodo, setAdministratorTodo] = useState([]);
  const [loadingAuthorizerTodo, setLoadingAuthorizerTodo] = useState(false);
  const [authorizerTodo, setAuthorizerTodo] = useState([]);
  const [updatedTodo, setUpdatedTodo] = useState(false);

  useEffect(() => {
    const fetchAdministratorTodo = async () => {
      setLoadingAdministratorTodo(true);
      try {
        const results = await axios.get('v1/dashboard/todo-list?role=administrator', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        });
        const originalData = results.data.results;
        const newAdministratorData = originalData.map((element) => {
          let content;
          const {
            _id,
            type,
            name,
            priority,
            nric,
            consultantId,
            workPermitEnd,
            insuranceEnd,
            completionDate,
            updatedAt,
            runPaymentDate,
            invoiceNumber,
            pslName,
          } = element;

          switch (type) {
            case 'insurance':
              content = `${name}'s insurance is expiring on ${moment(insuranceEnd).format('DD/MM/YY')}.`;
              return {
                type,
                content,
                priority,
                consultantId,
                calloutId: '',
                icon: <FileExclamationOutlined style={{ fontSize: '25px', color: '#F7931D' }} />,
                redirectPath: `/consultant-management/details?id=${consultantId}&name=${name}&tab=${'5'}`,
              };
            case 'work permit':
              content = `${name}'s work permit is expiring on ${moment(workPermitEnd).format('DD/MM/YY')}.`;
              return {
                type,
                content,
                priority,
                consultantId,
                calloutId: '',
                icon: <FileExclamationOutlined style={{ fontSize: '25px', color: '#F7931D' }} />,
                redirectPath: `/consultant-management/details?id=${consultantId}&name=${name}&tab=${'5'}`,
              };
            case 'contract':
              content = `${name}'s contract is expiring on ${moment(completionDate).format('DD/MM/YY')}.`;
              return {
                type,
                content,
                priority,
                consultantId,
                calloutId: _id,
                icon: <FileExclamationOutlined style={{ fontSize: '25px', color: '#F7931D' }} />,
                redirectPath: `/consultant-management/details?id=${consultantId}&name=${name}&tab=${'5'}`,
              };
            case 'registration':
              content = `${name}'s registration form is waiting to be approved.`;
              return {
                type,
                content,
                priority,
                consultantId,
                nric,
                calloutId: '',
                icon: <UserAddOutlined style={{ fontSize: '25px', color: '#F7931D' }} />,
                redirectPath: `/consultant-management?nric=${nric}`,
              };
            case 'rejected_rfp':
              content = `${name}'s RFP for ${moment(runPaymentDate).format('MMM')} 
              has been rejected at ${moment(updatedAt).format('DD/MM/YY')}.`;
              return {
                type,
                content,
                priority,
                consultantId,
                icon: <AlertOutlined style={{ fontSize: '25px', color: '#F7931D' }} />,
                redirectPath: `/consultant-management/details?id=${consultantId}&name=${name}&tab=${'2'}`,
              };
            case 'rejected_invoice':
              content = `RFI with invoice number ${invoiceNumber} 
              for ${pslName} has been rejected at ${moment(updatedAt).format('MMM')}.`;
              return {
                type,
                content,
                priority,
                consultantId,
                icon: <DollarCircleOutlined style={{ fontSize: '25px', color: '#F7931D' }} />,
                redirectPath: `/invoice-list?invoiceNumber=${invoiceNumber}`,
              };
            default:
              return null;
          }
        }).filter((item) => item !== null);
        setAdministratorTodo(newAdministratorData);
      } catch (error) {
        message.error(`Failed to retrieve administrator todo list data. ${error}`);
      } finally {
        setLoadingAdministratorTodo(false);
      }
    };

    const fetchAuthorizerTodo = async () => {
      setLoadingAuthorizerTodo(true);
      try {
        const results = await axios.get('v1/dashboard/todo-list?role=authorizer', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        });
        const originalData = results.data.results;
        const newAuthorizerData = originalData.map((element) => {
          let content;
          const { type, name, priority, consultantId, runPaymentDate, invoiceNumber, countConsultant, psl } = element;
          switch (type) {
            case 'rfp':
              content = `${name}'s RFP for ${moment(runPaymentDate).format('MMM')} pending for approval.`;
              return {
                type,
                content,
                priority,
                consultantId,
                calloutId: '',
                icon: <ExceptionOutlined style={{ fontSize: '25px', color: '#F7931D' }} />,
                redirectPath: `/consultant-management/details?id=${consultantId}&name=${name}&tab=${'2'}`,
              };
            case 'rfi':
              content = `RFI for ${countConsultant} consultants under ${psl} pending for approval.`;
              return {
                type,
                content,
                priority,
                consultantId,
                calloutId: '',
                icon: <ExceptionOutlined style={{ fontSize: '25px', color: '#F7931D' }} />,
                redirectPath: `/invoice-list?invoiceNumber=${invoiceNumber}`,
              };
            default:
              return null;
          }
        }).filter((item) => item !== null);
        setAuthorizerTodo(newAuthorizerData);
      } catch (error) {
        message.error(`Failed to retrieve authorizer todo list data. ${error}`);
      } finally {
        setLoadingAuthorizerTodo(false);
      }
    };

    fetchAdministratorTodo();
    fetchAuthorizerTodo();
  }, [updatedTodo]);

  const onclickMarkAsDone = async (consultantId, calloutId, type) => {
    setUpdatedTodo(false);
    let APIurl;
    let jsonBody = {};

    if (type === 'insurance') {
      APIurl = `v1/consultants/${consultantId}/update`;
      jsonBody = {
        isContinueInsurance: false,
      };
    } else if (type === 'work permit') {
      APIurl = `v1/consultants/${consultantId}/update`;
      jsonBody = {
        isContinueWorkPermit: false,
      };
    } else if (type === 'contract') {
      APIurl = `v1/callouts/${calloutId}/update`;
      jsonBody = {
        isContinueCallout: false,
      };
    }

    await axios
      .put(`${APIurl}`, jsonBody)
      .then(() => {
        setUpdatedTodo(true);
        message.success(`Updated ${type}!`);
      })
      .catch((error) => {
        setUpdatedTodo(true);
        message.error(`Failed to update consultant status. ${error}`);
      });
  };

  const onChangeTab = (key) => {
    setSelectedTab(key);
  };

  //* tab items
  const tabsItems = [
    {
      label: (
        <Row>
          <div className="todo-tab-title">Travis</div>
          <Badge
            className="site-badge-count-109"
            count={authorizerTodo.length}
            style={{
              backgroundColor: selectedTab === '1' ? '#FFDDB5' : '#F0F0F0',
              color: selectedTab === '1' ? '#F7931D' : '#00002D',
            }}
          />
        </Row>
      ),
      key: '1',
    },
    {
      label: (
        <Row>
          <div className="todo-tab-title">Yee Shan</div>
          <Badge
            className="site-badge-count-109"
            count={administratorTodo.length}
            style={{
              backgroundColor: selectedTab === '2' ? '#FFDDB5' : '#F0F0F0',
              color: selectedTab === '2' ? '#F7931D' : '#00002D',
            }}
          />
        </Row>
      ),
      key: '2',
    },
  ];

  return (
    <div className="todo-contents">
      <Row className="dashboard-section-title">To-do List</Row>
      <Divider />
      <Row flex="auto">
        <Tabs
          className="todo-tabs"
          defaultActiveKey="1"
          activeKey={selectedTab}
          type="card"
          centered
          onChange={onChangeTab}
          items={tabsItems}
        />
      </Row>
      <List
        itemLayout="horizontal"
        dataSource={selectedTab === '1' ? authorizerTodo : administratorTodo}
        loading={selectedTab === '1' ? loadingAuthorizerTodo : loadingAdministratorTodo}
        pagination={{
          total: selectedTab === '1' ? authorizerTodo.length : administratorTodo.length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          defaultCurrent: 1,
          showSizeChanger: false,
          defaultPageSize: 8,
        }}
        renderItem={(item) => {
          /* eslint max-len: ["error", { "code": 120 }] */
          const { type, content, priority, consultantId, calloutId, icon, redirectPath } = item;

          return (
            <List.Item>
              <List.Item.Meta
                style={{ alignItems: 'center' }}
                avatar={(
                  <div
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    {icon}
                  </div>
                )}
                title={(
                  <Typography.Link activeclassname="active" href={redirectPath}>
                    <Text style={{ color: '#000000' }}>
                      {content}
                    </Text>
                  </Typography.Link>
                )}
                description={
                  priority === 1 ? (
                    <Row style={{ display: 'flex', flexDirection: 'row', height: 30 }}>
                      <Badge status="error" text="High Priority" />
                      {type !== 'registration' && type !== 'rfi' && type !== 'rfp' 
                        && type !== 'rejected_rfp' && type !== 'rejected_invoice' ? (
                          <Typography.Link
                            type="link"
                            style={{ paddingLeft: '10px', cursor: 'pointer', color: '#f7931d' }}
                            onClick={() => onclickMarkAsDone(consultantId, calloutId, type)}>
                            Mark as done
                          </Typography.Link>
                        ) : null}
                    </Row>
                  ) : priority === 2 ? (
                    <Row style={{ display: 'flex', flexDirection: 'row', height: 30 }}>
                      <Badge status="warning" text="Medium Priority" />
                      {type !== 'registration' && type !== 'rfi' && type !== 'rfp' ? (
                        <Typography.Link
                          type="link"
                          style={{ paddingLeft: '10px', cursor: 'pointer', color: '#f7931d' }}
                          onClick={() => onclickMarkAsDone(consultantId, calloutId, type)}>
                          Mark as done
                        </Typography.Link>
                      ) : null}
                    </Row>
                  ) : (
                    <Row style={{ height: 30 }}>
                      <Badge status="processing" text="Low Priority" />
                      {type !== 'registration' && type !== 'rfi' && type !== 'rfp' ? (
                        <Typography.Link
                          type="link"
                          style={{ paddingLeft: '10px', cursor: 'pointer', color: '#f7931d' }}
                          onClick={() => onclickMarkAsDone(consultantId, calloutId, type)}>
                          Mark as done
                        </Typography.Link>
                      ) : null}
                    </Row>
                  )
                }
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
}
