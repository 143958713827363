import React, { useState, useEffect } from 'react';

import moment from 'moment';
import axios from 'axios';
import { saveAs } from 'file-saver';

import {
  Breadcrumb,
  Table,
  PageHeader,
  Input,
  Layout,
  Row,
  Skeleton,
  message,
  Typography,
  Card,
  Col,
  Button,
  DatePicker,
  Tooltip,
  Form,
} from 'antd';

import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Item } = Form;

export default function InvoiceHistory() {
  const token = getToken();
  const [searchForm] = Form.useForm();

  const [invoiceData, setInvoiceData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchCompany, setSearchCompany] = useState('');
  const [searchPSL, setSearchPSL] = useState('');
  const [searchInvoiceDate, setSearchInvoiceDate] = useState('');
  const [searchStartInvoiceNumber, setSearchStartInvoiceNumber] = useState('');
  const [searchEndInvoiceNumber, setSearchEndInvoiceNumber] = useState('');
  const [showReset, setShowReset] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await axios
        .get('v1/invoices', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          // todo: fix
          params: {
            isHistory: true,
            company: searchCompany,
            psl: searchPSL,
            startDate: searchInvoiceDate !== '' ? new Date(searchInvoiceDate[0]) : '',
            endDate: searchInvoiceDate !== '' ? new Date(searchInvoiceDate[1]) : '',
            startInvoiceNumber: searchStartInvoiceNumber,
            endInvoiceNumber: searchEndInvoiceNumber,
            // page: ,
            limit: 99999999,
            sortBy: 'createdAt:desc',
          },
        })
        .then((res) => {
          setLoading(false);
          setInvoiceData(res.data);

          searchForm.resetFields();
        })
        .catch((error) => {
          message.error(`Failed to retrive invoice history data. ${error}`);
        });
    };
    getData();
  }, [
    searchCompany,
    searchInvoiceDate,
    searchStartInvoiceNumber,
    searchEndInvoiceNumber,
    searchPSL,
  ]);

  const getDownloadCalloutFile = async (fileURL) => axios
    .get(`v1/download?fileUrl=${fileURL}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => response.data)
    .catch((e) => {
      message.error(`Failed to download invoice. ${e}`);
    });

  const downloadForm = async (invoiceId, fileURL) => {
    getDownloadCalloutFile(fileURL).then((blob) => {
      saveAs(blob, `${invoiceId}_Invoice`);
    });
  };

  const columns = [
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      width: '20%',
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      render: (val) => {
        if (val) {
          return val.toUpperCase();
        }
        return '-';
      },
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      sorter: (a, b) => a.invoiceNumber.localeCompare(b.invoiceNumber),
    },
    {
      title: 'Terms',
      dataIndex: 'terms',
      key: 'terms',
      align: 'justify',
      width: '10%',
      render: (val) => `${val}`,
    },
    {
      title: 'PSL',
      dataIndex: 'pslName',
      key: 'pslName',
      align: 'justify',
      sorter: (a, b) => a.pslName.localeCompare(b.pslName),
      render: (val) => {
        if (val) {
          return val.toUpperCase();
        }
        return '-';
      },
    },
    {
      title: 'Total Consultant',
      dataIndex: 'countConsultant',
      key: 'countConsultant',
      align: 'right',
      sorter: (a, b) => a.countConsultant - b.countConsultant,
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      align: 'right',
      width: '12%',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      render: (amount) => `RM ${(Math.round(amount * 100) / 100).toFixed(2)}`,
    },
    {
      title: 'Invoice Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      render: (data) => (data ? moment(data).format('DD/MM/YY') : '-'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: '12%',
      render: (_, data) => (
        <Button type="link">
          <Typography.Link onClick={() => downloadForm(data._id, data.invoiceUrl)}>
            Download
          </Typography.Link>
        </Button>
      ),
    },
  ];

  const onSearch = () => {
    searchForm
      .validateFields()
      .then((res) => {
        const { searchInvoice } = res;
        const { company, psl, invoiceDate, startInvoiceNumber, endInvoiceNumber } = searchInvoice;

        setSearchCompany(company);
        setSearchPSL(psl);
        setSearchInvoiceDate(invoiceDate);
        setSearchStartInvoiceNumber(startInvoiceNumber);
        setSearchEndInvoiceNumber(endInvoiceNumber);
        setShowReset(true);
        searchForm.resetFields();
      })
      .catch(() => {});
  };

  const onResetSearch = () => {
    setShowReset(false);
    setSearchCompany('');
    setSearchPSL('');
    setSearchInvoiceDate('');
    setSearchStartInvoiceNumber('');
    setSearchEndInvoiceNumber('');
    searchForm.resetFields();
  };

  const SearchBar = () => (
    <Form layout="horizontal" form={searchForm}>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 20,
        }}>
        <Col span={12}>
          <Item name={['searchInvoice', 'company']} initialValue={searchCompany}>
            <div className="header-section-search-bar-section">
              Company
              <Input defaultValue={searchCompany} />
            </div>
          </Item>
        </Col>
        <Col span={12}>
          <Item name={['searchInvoice', 'psl']} initialValue={searchPSL}>
            <div className="header-section-search-bar-section">
              PSL
              <Input defaultValue={searchPSL} />
            </div>
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div className="header-section-search-bar-section">Invoice Number</div>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 20,
            }}>
            <Col span={11}>
              <Item
                name={['searchInvoice', 'startInvoiceNumber']}
                initialValue={searchStartInvoiceNumber}>
                <Input placeholder="eg. 003" />
              </Item>
            </Col>
            <p style={{ paddingLeft: 10, paddingRight: 10 }}> - </p>
            <Col span={11}>
              <Item
                name={['searchInvoice', 'endInvoiceNumber']}
                initialValue={searchEndInvoiceNumber}>
                <Input placeholder="eg. 005" />
              </Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <div className="header-section-search-bar-section">Invoice Date</div>
          <Item name={['searchInvoice', 'invoiceDate']} initialValue={searchInvoiceDate}>
            <RangePicker style={{ width: '100%' }} />
          </Item>
        </Col>
      </Row>
    </Form>
  );
  const ApplyFilterButton = () => (
    <Button type="primary" key="submit" onClick={onSearch}>
      Apply
    </Button>
  );
  const ResetButton = () => (
    <Tooltip title="Reset Filters">
      <Button type="danger" disabled={!showReset} onClick={onResetSearch}>
        Reset
      </Button>
    </Tooltip>
  );

  const TableInvoiceHistory = () => (
    <div className="table-content">
      {loading === false ? (
        <Table
          scroll={{ x: 'auto' }}
          columns={columns}
          dataSource={invoiceData.results}
          pagination={{
            position: ['bottomRight'],
            total: invoiceData.totalResults,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            defaultCurrent: 1,
            showSizeChanger: true,
            defaultPageSize: 5,
            pageSizeOptions: ['5', '10', '50', '100'],
          }}
        />
      ) : (
        <Skeleton />
      )}
    </div>
  );

  const BreadCrumbDiv = () => (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Typography.Link href="/invoice-history">Invoice History</Typography.Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const PageHeaderDiv = () => <PageHeader title="Invoice History" className="basic-pageheader" />;

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <BreadCrumbDiv />
      <PageHeaderDiv />
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <div className="header-section">
          <Card className="header-section-card">
            <SearchBar />
            <Row className="apply-filter-button">
              {showReset === true ? (
                <Col className="vertical-align-center-button">
                  <Col>
                    <ApplyFilterButton />
                  </Col>
                  <Col className="right-positioned-button">
                    <ResetButton />
                  </Col>
                </Col>
              ) : (
                <Col className="vertical-align-center-button">
                  <ApplyFilterButton />
                </Col>
              )}
            </Row>
          </Card>
        </div>
        <TableInvoiceHistory />
      </Content>
    </>
  );
}
