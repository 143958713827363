import React, { useState } from 'react';
import { Form, Button, Col, Row, Card, Space, message } from 'antd';
import moment from 'moment';
import FamilyDetailsModal from '../../components/register/FamilyDetailsModal';
import EmergencyContactModal from '../../components/register/EmergencyContactModal';
// import avatar from '../../assets/images/register_page_avatar.svg';
import '../../styles/general.css';
import './Register.css';

const FamilyDetailsForm = (props) => {
  const { stepChangeHandler } = props;
  const [familyMembers, setFamilyMembers] = useState(() => {
    if (localStorage.getItem('familyMembers')) {
      const familyMembersFromLocalStorage = JSON.parse(localStorage.getItem('familyMembers'));
      familyMembersFromLocalStorage.forEach((familyMemberFromLocalStorage) => {
        // eslint-disable-next-line no-param-reassign
        familyMemberFromLocalStorage.dateOfBirth = moment(familyMemberFromLocalStorage.dateOfBirth);
      });

      return familyMembersFromLocalStorage;
    }
    return [];
  });
  const [emergencyContacts, setEmergencyContacts] = useState(() => {
    if (localStorage.getItem('emergencyContacts')) {
      return JSON.parse(localStorage.getItem('emergencyContacts'));
    }
    return [];
  });

  const [isAddFamilyModalVisible, setIsAddFamilyModalVisible] = useState(false);
  const [isEditFamilyModalVisible, setIsEditFamilyModalVisible] = useState(false);
  const [isAddEmergencyModalVisible, setIsAddEmergencyModalVisible] = useState(false);
  const [isEditEmergencyModalVisible, setIsEditEmergencyModalVisible] = useState(false);

  const [familyMemberToEdit, setFamilyMemberToEdit] = useState({});
  const [emergencyContactToEdit, setEmergencyContactToEdit] = useState({});

  const toggleAddFamilyModal = () => {
    setIsAddFamilyModalVisible(!isAddFamilyModalVisible);
  };

  const toggleEditFamilyModal = (familyMember) => {
    setIsEditFamilyModalVisible(!isEditFamilyModalVisible);
    if (familyMember) {
      setFamilyMemberToEdit(familyMember);
    }
  };

  const toggleAddEmergencyModal = () => {
    setIsAddEmergencyModalVisible(!isAddEmergencyModalVisible);
  };

  const toggleEditEmergencyModal = (emergencyContact) => {
    setIsEditEmergencyModalVisible(!isEditEmergencyModalVisible);
    if (emergencyContact) {
      setEmergencyContactToEdit(emergencyContact);
    }
  };

  const [form] = Form.useForm();

  const addFamilyMember = (value) => {
    const newFamilyMember = {
      ...value,
      id: familyMembers.length + 1,
    };
    setFamilyMembers((previousFamilyMembers) => [...previousFamilyMembers, newFamilyMember]);
  };

  const editFamilyMember = (value) => {
    const newFamilyMembers = familyMembers.map((familyMember) => {
      if (familyMember.id === familyMemberToEdit.id) {
        return {
          ...value,
          id: familyMember.id,
        };
      }
      return familyMember;
    });
    setFamilyMembers(newFamilyMembers);
  };

  const deleteFamilyMember = (id) => {
    const newFamilyMembers = familyMembers.filter((familyMember) => familyMember.id !== id);
    setFamilyMembers(newFamilyMembers);
  };

  const addEmergencyContact = (value) => {
    const newEmergencyContact = {
      ...value,
      id: emergencyContacts.length + 1,
    };
    setEmergencyContacts((previousEmergencyContacts) => [
      ...previousEmergencyContacts,
      newEmergencyContact,
    ]);
  };

  const editEmergencyContact = (value) => {
    const newEmergencyContacts = emergencyContacts.map((emergencyContact) => {
      if (emergencyContact.id === emergencyContactToEdit.id) {
        return {
          ...value,
          id: emergencyContact.id,
        };
      }
      return emergencyContact;
    });
    setEmergencyContacts(newEmergencyContacts);
  };

  const deleteEmergencyContact = (id) => {
    const newEmergencyContacts = emergencyContacts.filter(
      (emergencyContact) => emergencyContact.id !== id,
    );
    setEmergencyContacts(newEmergencyContacts);
  };

  const saveToLocalStorage = () => {
    localStorage.setItem('familyMembers', JSON.stringify(familyMembers));
    localStorage.setItem('emergencyContacts', JSON.stringify(emergencyContacts));
    message.success('Family details and emergency contacts saved successfully');
  };

  return (
    <>
      <Form form={form} name="family-details" layout="vertical">
        <div>
          <div
            style={{
              background: '#EEEEEE',
              padding: '30px',
            }}
          >
            <Row justify="space-between" align="middle">
              <Col>
                <h2>Family Details</h2>
                *Click on the family member’s name to edit their information
              </Col>
              <Space />
              <Col>
                <Button
                  shape="circle"
                  icon="+"
                  className="add-family-member-button"
                  onClick={toggleAddFamilyModal}
                />
              </Col>
            </Row>
            <div style={{ height: '23px' }} />
            {familyMembers.length > 0 ? (
              familyMembers.map((familyMember) => (
                <Card
                  onClick={() => {
                    toggleEditFamilyModal(familyMember);
                  }}
                  hoverable
                  key={familyMember.id}
                >
                  <Row justify="space-between" align="middle">
                    {/* <Col>
                      <Avatar size={40} src={avatar} />
                    </Col> */}
                    <div style={{ width: '13px' }} />
                    <Col>
                      <h3
                        style={{
                          color: '#000000',
                          textAlign: 'left',
                        }}
                      >
                        {familyMember.name}
                      </h3>
                      <p>{familyMember.relationship}</p>
                    </Col>
                    <Col flex="auto" />
                  </Row>
                </Card>
              ))
            ) : (
              <Row
                style={{
                  width: '100%',
                  background: '#FFFFFF',
                  color: '#838383',
                  padding: '12px',
                }}
                justify="center"
              >
                Please add Family members by clicking the ‘+’ Icon
              </Row>
            )}
            <div style={{ height: '36px' }} />
            <Row align="middle">
              <Col>
                <h2>Emergency Contact</h2>
                *Click on the emergency contact’s name to edit their information
              </Col>
              <Col flex="auto" />
              <Col>
                <Button
                  shape="circle"
                  icon="+"
                  className="add-family-member-button"
                  onClick={toggleAddEmergencyModal}
                />
              </Col>
            </Row>
            <div style={{ height: '16px' }} />
            {emergencyContacts.length > 0 ? (
              emergencyContacts.map((emergencyContact) => (
                <Card
                  onClick={() => {
                    toggleEditEmergencyModal(emergencyContact);
                  }}
                  hoverable
                  key={emergencyContact.id}
                >
                  <Row justify="space-between" align="middle">
                    {/* <Col>
                      <Avatar size={40} src={avatar} />
                    </Col> */}
                    <div style={{ width: '13px' }} />
                    <Col>
                      <h3
                        style={{
                          color: '#000000',
                          textAlign: 'left',
                        }}
                      >
                        {emergencyContact.name}
                      </h3>
                      <p>
                        {emergencyContact.relationship} | {`${emergencyContact.country}${emergencyContact.phoneNumber}`}
                      </p>
                    </Col>
                    <Col flex="auto" />
                  </Row>
                </Card>
              ))
            ) : (
              <Row
                style={{
                  width: '100%',
                  background: '#FFFFFF',
                  color: '#838383',
                  padding: '12px',
                }}
                justify="center"
              >
                Please add Emergency Contacts by clicking the ‘+’ Icon
              </Row>
            )}
            <div style={{ height: '30px' }} />
            <Row justify="space-between">
              <Col>
                <Button onClick={saveToLocalStorage}>Save changes</Button>
              </Col>
              <Col flex="auto" />
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    saveToLocalStorage();
                    stepChangeHandler(0);
                  }}
                >
                  Back
                </Button>
              </Col>
              <div style={{ width: '12px' }} />
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    saveToLocalStorage();
                    stepChangeHandler(2);
                  }}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
      <FamilyDetailsModal
        isModalVisible={isAddFamilyModalVisible}
        title="Add Family Member"
        toggleModal={toggleAddFamilyModal}
        addCallback={addFamilyMember}
      />
      <FamilyDetailsModal
        isModalVisible={isEditFamilyModalVisible}
        title="Edit Family Member"
        toggleModal={toggleEditFamilyModal}
        familyMemberToEdit={familyMemberToEdit}
        editCallback={editFamilyMember}
        deleteCallback={deleteFamilyMember}
      />
      <EmergencyContactModal
        isModalVisible={isAddEmergencyModalVisible}
        title="Add Emergency Contact"
        toggleModal={toggleAddEmergencyModal}
        addCallback={addEmergencyContact}
      />
      <EmergencyContactModal
        isModalVisible={isEditEmergencyModalVisible}
        title="Edit Emergency Contact"
        toggleModal={toggleEditEmergencyModal}
        emergencyContactToEdit={emergencyContactToEdit}
        editCallback={editEmergencyContact}
        deleteCallback={deleteEmergencyContact}
      />
    </>
  );
};

export default FamilyDetailsForm;
