import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';

const RegisterSuccess = () => (
  <div>
    <Row justify="center">
      <Col>
        <div
          style={{
            height: '100px',
          }}
        />
        <Row justify="center">
          <Col>
            <CheckCircleFilled
              style={{
                color: '#F7931D',
                fontSize: '70px',
              }}
            />
          </Col>
        </Row>
        <div
          style={{
            height: '30px',
          }}
        />
        <h1
          style={{
            textAlign: 'center',
          }}
        >
          Successfully submitted registration form!
        </h1>
        <p style={{ textAlign: 'center' }}>
          We will contact you shortly for further registeration process in next 10 working days.
        </p>
        <div
          style={{
            height: '542px',
          }}
        />
      </Col>
    </Row>
  </div>
);

export default RegisterSuccess;
