import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Divider, message, Typography, DatePicker } from 'antd';

import axios from 'axios';
import moment from 'moment';

import '../../pages/dashboard/Dashboard.css';
import { getToken } from '../../utils/AuthService';

export default function DashboardTopMostStatistics() {
  const token = getToken();

  const [loadingDashboardTopStatistics, setLoadingDashboardTopStatistics] = useState(false);
  const [dashboardTopStatisticsData, setDashboardTopStatisticsData] = useState({
    revenue: { amount: 0, averageAmount: 0 },
    cost: { amount: 0, averageAmount: 0 },
    pbt: { amount: 0, averageAmount: 0 },
    pendingInvoice: 0,
  });

  const currentDate = new Date();
  const dateFormatList = ['YYYY', 'YY'];
  const [selectedDate, setSelectedDate] = useState(currentDate.getFullYear());

  const onChangeDate = (date) => {
    setSelectedDate(moment(date._d).format('YYYY'));
  };

  const fetchDashboardStatisticsData = async () => {
    setLoadingDashboardTopStatistics(true);
    await axios
      .get('v1/dashboard/statistic', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
        params: {
          year: selectedDate,
        },
      })
      .then((results) => {
        const revenueToDate = results.data.revenue.amount;
        let revenueToDateText;
        // if (revenueToDate) {
        //   if (revenueToDate >= 1000) {
        //     revenueToDateText = `${(revenueToDate / 1000).toFixed(3)} K`;
        //   } else if (revenueToDate >= 1000000) {
        //     revenueToDateText = `${(revenueToDate / 1000000).toFixed(3)} M`;
        //   } else {
        //     revenueToDateText = revenueToDate.toFixed(2);
        //   }
        // } else {
        //   revenueToDateText = '0';
        // }
        if (revenueToDate) {
          if (revenueToDate > 0) {
            if (revenueToDate >= 1000) {
              revenueToDateText = `${(revenueToDate / 1000).toFixed(3)} K`;
            } else if (revenueToDate >= 1000000) {
              revenueToDateText = `${(revenueToDate / 1000000).toFixed(3)} M`;
            } else {
              revenueToDateText = revenueToDate.toFixed(2);
            }
          } else if (revenueToDate <= 0) {
            if (revenueToDate <= -1000) {
              revenueToDateText = `${(revenueToDate / 1000).toFixed(3)} K`;
            } else if (revenueToDate <= -1000000) {
              revenueToDateText = `${(revenueToDate / 1000000).toFixed(3)} K`;
            } else {
              revenueToDateText = `${revenueToDate.toFixed(2)}`;
            }
          } else if (revenueToDate === 0) {
            revenueToDateText = '0';
          }
        } else {
          revenueToDateText = '0';
        }

        const avgRevenue = results.data.revenue.averageAmount;
        let avgRevenueText;
        // if (avgRevenue) {
        //   if (avgRevenue >= 1000) {
        //     avgRevenueText = `${(avgRevenue / 1000).toFixed(3)} K`;
        //   } else if (avgRevenue >= 1000000) {
        //     avgRevenueText = `${(avgRevenue / 1000000).toFixed(3)} M`;
        //   } else {
        //     avgRevenueText = avgRevenue.toFixed(2);
        //   }
        // } else {
        //   avgRevenueText = '0';
        // }

        if (avgRevenue) {
          if (avgRevenue > 0) {
            if (avgRevenue >= 1000) {
              avgRevenueText = `${(avgRevenue / 1000).toFixed(3)} K`;
            } else if (avgRevenue >= 1000000) {
              avgRevenueText = `${(avgRevenue / 1000000).toFixed(3)} M`;
            } else {
              avgRevenueText = avgRevenue.toFixed(2);
            }
          } else if (avgRevenue <= 0) {
            if (avgRevenue <= -1000) {
              avgRevenueText = `${(avgRevenue / 1000).toFixed(3)} K`;
            } else if (avgRevenue <= -1000000) {
              avgRevenueText = `${(avgRevenue / 1000000).toFixed(3)} K`;
            } else {
              avgRevenueText = `${avgRevenue.toFixed(2)}`;
            }
          } else if (avgRevenue === 0) {
            avgRevenueText = '0';
          }
        } else {
          avgRevenueText = '0';
        }

        const costToDate = results.data.cost.amount;
        let costToDateText;
        if (costToDate) {
          if (costToDate > 0) {
            if (costToDate >= 1000) {
              costToDateText = `${(costToDate / 1000).toFixed(3)} K`;
            } else if (costToDate >= 1000000) {
              costToDateText = `${(costToDate / 1000000).toFixed(3)} M`;
            } else {
              costToDateText = costToDate.toFixed(2);
            }
          } else if (costToDate <= 0) {
            if (costToDate <= -1000) {
              costToDateText = `${(costToDate / 1000).toFixed(3)} K`;
            } else if (costToDate <= -1000000) {
              costToDateText = `${(costToDate / 1000000).toFixed(3)} M`;
            } else {
              costToDateText = `${costToDate.toFixed(2)}`;
            }
          } else if (costToDate === 0) {
            costToDateText = '0';
          }
        } else {
          costToDateText = '0';
        }

        const avgCost = results.data.cost.averageAmount;
        let avgCostText;
        // if (avgCost) {
        //   if (avgCost >= 1000) {
        //     avgCostText = `${(avgCost / 1000).toFixed(3)} K`;
        //   } else if (avgCost >= 1000000) {
        //     avgCostText = `${(avgCost / 1000000).toFixed(3)} M`;
        //   } else {
        //     avgCostText = avgCost.toFixed(2);
        //   }
        // } else {
        //   avgCostText = '0';
        // }

        if (avgCost) {
          if (avgCost > 0) {
            if (avgCost >= 1000) {
              avgCostText = `${(avgCost / 1000).toFixed(3)} K`;
            } else if (avgCost >= 1000000) {
              avgCostText = `${(avgCost / 1000000).toFixed(3)} M`;
            } else {
              avgCostText = avgCost.toFixed(2);
            }
          } else if (avgCost <= 0) {
            if (avgCost <= -1000) {
              avgCostText = `${(avgCost / 1000).toFixed(3)} K`;
            } else if (avgCost <= -1000000) {
              avgCostText = `${(avgCost / 1000000).toFixed(3)} K`;
            } else {
              avgCostText = `${avgCost.toFixed(2)}`;
            }
          } else if (avgCost === 0) {
            avgCostText = '0';
          }
        } else {
          avgCostText = '0';
        }

        const pbtToDate = results.data.pbt.amount;
        let pbtToDateText;
        if (pbtToDate) {
          if (pbtToDate > 0) {
            if (pbtToDate >= 1000) {
              pbtToDateText = `${(pbtToDate / 1000).toFixed(3)} K`;
            } else if (pbtToDate >= 1000000) {
              pbtToDateText = `${(pbtToDate / 1000000).toFixed(3)} M`;
            } else {
              pbtToDateText = pbtToDate.toFixed(2);
            }
          } else if (pbtToDate <= 0) {
            if (pbtToDate <= -1000) {
              pbtToDateText = `${(pbtToDate / 1000).toFixed(3)} K`;
            } else if (pbtToDate <= -1000000) {
              pbtToDateText = `${(pbtToDate / 1000000).toFixed(3)} K`;
            } else {
              pbtToDateText = `${pbtToDate.toFixed(2)}`;
            }
          } else if (pbtToDate === 0) {
            pbtToDateText = '0';
          }
        } else {
          pbtToDateText = '0';
        }

        const avgPBT = results.data.pbt.averageAmount;
        let avgPBTText;
        // if (avgPBT) {
        //   if (avgPBT >= 1000) {
        //     avgPBTText = `${(avgPBT / 1000).toFixed(3)} K`;
        //   } else if (avgPBT >= 1000000) {
        //     avgPBTText = `${(avgPBT / 1000000).toFixed(3)} M`;
        //   } else {
        //     avgPBTText = avgPBT.toFixed(2);
        //   }
        // } else {
        //   avgPBTText = '0';
        // }

        if (avgPBT) {
          if (avgPBT > 0) {
            if (avgPBT >= 1000) {
              avgPBTText = `${(avgPBT / 1000).toFixed(3)} K`;
            } else if (avgPBT >= 1000000) {
              avgPBTText = `${(avgPBT / 1000000).toFixed(3)} M`;
            } else {
              avgPBTText = avgPBT.toFixed(2);
            }
          } else if (avgPBT <= 0) {
            if (avgPBT <= -1000) {
              avgPBTText = `${(avgPBT / 1000).toFixed(3)} K`;
            } else if (avgPBT <= -1000000) {
              avgPBTText = `${(avgPBT / 1000000).toFixed(3)} K`;
            } else {
              avgPBTText = `${avgPBT.toFixed(2)}`;
            }
          } else if (avgPBT === 0) {
            avgPBTText = '0';
          }
        } else {
          avgPBTText = '0';
        }

        setDashboardTopStatisticsData({
          revenueToDate: revenueToDateText,
          avgRevenue: avgRevenueText,
          costToDate: costToDateText,
          avgCost: avgCostText,
          pbtToDate: pbtToDateText,
          avgpbt: avgPBTText,
          pendingInvoice: results.data.pendingInvoice,
        });
      })
      .catch((error) => {
        message.error(`Failed to retrive dashboard top statistics data. ${error}`);
      })
      .finally(() => {
        setLoadingDashboardTopStatistics(false);
      });
  };

  useEffect(() => {
    fetchDashboardStatisticsData();
  }, [selectedDate]);

  return (
    <div className="site-card-wrapper">
      <Card>
        <Row style={{ marginBottom: '15px' }}>
          <DatePicker
            defaultValue={moment()}
            format={dateFormatList}
            picker="year"
            onChange={onChangeDate}
            clearIcon={false}
          />
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={12} md={6}>
            <Card className="dashboard-statistics-card" bodyStyle={{ padding: '15px' }}>
              <Row className="dashboard-statistics-card-title">Revenue to Date</Row>
              <Row className="dashboard-statistics-card-contents">
                {loadingDashboardTopStatistics === false ? (
                  dashboardTopStatisticsData.revenueToDate
                ) : (
                  <p>Loading...</p>
                )}
              </Row>
              <Divider />
              <Row className="dashboard-statistics-card-footer-contents">
                <Col>Average Monthly Revenue</Col>
                <Col>
                  {loadingDashboardTopStatistics === false ? (
                    dashboardTopStatisticsData.avgRevenue
                  ) : (
                    <p>Loading...</p>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="dashboard-statistics-card" bodyStyle={{ padding: '15px' }}>
              <Row className="dashboard-statistics-card-title">Cost to Date</Row>
              <Row className="dashboard-statistics-card-contents">
                {loadingDashboardTopStatistics === false ? (
                  dashboardTopStatisticsData.costToDate
                ) : (
                  <p>Loading...</p>
                )}
              </Row>
              <Divider />
              <Row className="dashboard-statistics-card-footer-contents">
                <Col>Average Monthly Cost</Col>
                <Col>
                  {loadingDashboardTopStatistics === false ? (
                    dashboardTopStatisticsData.avgCost
                  ) : (
                    <p>Loading...</p>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="dashboard-statistics-card" bodyStyle={{ padding: '15px' }}>
              <Row className="dashboard-statistics-card-title">PBT to Date</Row>
              <Row className="dashboard-statistics-card-contents">
                {loadingDashboardTopStatistics === false ? (
                  dashboardTopStatisticsData.pbtToDate
                ) : (
                  <p>Loading...</p>
                )}
              </Row>
              <Divider />
              <Row className="dashboard-statistics-card-footer-contents">
                <Col>Average Monthly PBT</Col>
                <Col>
                  {loadingDashboardTopStatistics === false ? (
                    dashboardTopStatisticsData.avgpbt
                  ) : (
                    <p>Loading...</p>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="dashboard-statistics-card" bodyStyle={{ padding: '15px' }}>
              <Row className="dashboard-statistics-card-title">Pending Invoice</Row>
              <Row className="dashboard-statistics-card-contents">
                {loadingDashboardTopStatistics === false ? (
                  dashboardTopStatisticsData.pendingInvoice
                ) : (
                  <p>Loading...</p>
                )}
              </Row>
              <Divider />
              <Row className="dashboard-statistics-card-redirects-text">
                <Typography.Link style={{ color: '#f7931d' }} href="/invoice-list">
                  Go to Invoice List
                </Typography.Link>
              </Row>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
