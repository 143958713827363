import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, Select } from 'antd';

const FamilyDetailsModal = (props) => {
  const {
    isModalVisible,
    title,
    toggleModal,
    familyMemberToEdit,
    addCallback,
    editCallback,
    deleteCallback,
  } = props;

  const [form] = Form.useForm();

  const [relationship, setRelationship] = useState('');

  const { Item } = Form;
  const { Option } = Select;

  const relationshipList = ['Parents', 'Wife', 'Husband', 'Son', 'Daughter', 'Relatives', 'Friends', 'Other'];

  useEffect(() => {
    setRelationship(familyMemberToEdit ? familyMemberToEdit.relationship : '');
  }, [familyMemberToEdit]);

  const displayButton = () => {
    if (title === 'Add Family Member') {
      return (
        <>
          <Button
            onClick={() => {
              form.resetFields();
              toggleModal();
            }}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              if (form) {
                form.validateFields().then((values) => {
                  form.resetFields();
                  toggleModal();
                  addCallback(values);
                });
              }
            }}
          >
            Add
          </Button>
        </>
      );
    }

    form.setFieldsValue({
      name: familyMemberToEdit.name,
      relationship: familyMemberToEdit.relationship,
      others: familyMemberToEdit.others || null,
      dateOfBirth: familyMemberToEdit.dateOfBirth,
      occupation: familyMemberToEdit.occupation,
    });
    return (
      <>
        <Button
          onClick={() => {
            if (form) {
              form.resetFields();
              toggleModal();
              deleteCallback(familyMemberToEdit.id);
            }
          }}
        >
          Delete
        </Button>
        <Button
          type="primary"
          onClick={() => {
            if (form) {
              form.validateFields().then((values) => {
                form.resetFields();
                toggleModal();
                editCallback(values);
              });
            }
          }}
        >
          Save Changes
        </Button>
      </>
    );
  };

  return (
    <Modal title={title} open={isModalVisible} onCancel={toggleModal} footer={[displayButton()]}>
      <Form
        form={form}
        name="family-details-or-emergency-contact"
        layout="vertical"
        validateMessages={{
          required: '${label} is required!',
        }}
      >
        <Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input 
            maxLength={80} 
            onInput={(e) => { e.target.value = e.target.value.toUpperCase(); }}
          />
        </Item>
        <Item
          name="relationship"
          label="Relationship"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            style={{
              width: '100%',
            }}
            showSearch
            placeholder="Please select"
            optionFilterProp="children"
            filterOption={relationshipList}
            onChange={(value) => setRelationship(value)}
          >
            {relationshipList.map((val) => (
              <Option key={val} value={val}>
                {val}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="others"
          label="Others (please specify)"
          rules={[
            {
              required: relationship === 'Other',
            },
          ]}
        >
          <Input disabled={relationship !== 'Other'} />
        </Item>
        <Item
          name="dateOfBirth"
          label="Date of Birth"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <DatePicker
            disabledDate={(current) => current && current > Date.now()}
            style={{ width: '100%' }}
          />
        </Item>
        <Item
          name="occupation"
          label="Occupation"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Item>
      </Form>
    </Modal>
  );
};

export default FamilyDetailsModal;
