import React from 'react';
import { Pie, measureTextWidth } from '@ant-design/plots';

export default function SurveyPie({ data }) {
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    const R = containerWidth / 2;

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(R ** 2 / (textWidth / 2) ** 2 + textHeight ** 2)), 1);
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };">${text}</div>`;
  }

  const config = {
    appendPadding: 10,
    data,
    angleField: 'total',
    colorField: 'name',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    legend: {
      layout: 'vertical',
      position: 'bottom',
    },
    animation: false,
    statistic: {
      title: {
        style: {
          fontSize: 16,
          textAlign: 'center',
        },
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt((width / 2) ** 2) + (height / 28) ** 2;
          const text = datum ? datum.type : 'Income';
          return renderStatistic(d, text, {
            fontSize: 28,
          });
        },
      },
      content: {
        style: {
          fontSize: 20,
        },
        customHtml: (container, view, datum, statisticData) => {
          let totalVal;
          const total = statisticData.reduce((r, d) => r + d.total, 0);
          if (total >= 1000) {
            totalVal = `${(total / 1000).toFixed(3)} K`;
          } else if (total >= 1000000) {
            totalVal = `${(total / 1000000).toFixed(3)} M`;
          } else {
            totalVal = `RM ${total.toFixed(2)}`;
          }

          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `RM ${datum.total}`
            : `${totalVal}`;
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
    },
  };

  return <Pie {...config} />;
}
