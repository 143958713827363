import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Table,
  Col,
  PageHeader,
  Layout,
  DatePicker,
  Select,
  Row,
  Card,
  Button,
  Skeleton,
  message,
  Tooltip,
  Form,
} from 'antd';

import axios from 'axios';
import moment from 'moment';

import './AuditLog.css';
import { getToken } from '../../utils/AuthService';
import { capitalizedFirstAlphabet } from '../../utils/capitalizedFirstAlphabet';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Item } = Form;

export default function AuditLog() {
  const token = getToken();
  const [searchForm] = Form.useForm();

  const [auditLogData, setAuditLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [distinctUserRoles, setDistinctUserRoles] = useState([]);
  const [isLoadingDistinctUserRoles, setIsLoadingDistinctUserRoles] = useState(false);
  const [distinctPages, setDistinctPages] = useState([]);
  const [isLoadingDistinctPages, setIsLoadingDistinctPages] = useState(false);

  const [searchUserRole, setSearchUserRole] = useState('');
  const [searchUserId, setSearchUserId] = useState('');
  const [searchPage, setSearchPage] = useState('');
  const [searchDateTime, setSearchDateTime] = useState('');
  const [showReset, setShowReset] = useState(false);

  // Fetch Audit log from API
  useEffect(async () => {
    const fetchAuditLog = async () => {
      setIsLoading(true);
      await axios
        .get('v1/auditlogs', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            userId: searchUserId,
            fromPage: searchPage,
            startDate: searchDateTime !== '' ? new Date(searchDateTime[0]) : '',
            endDate: searchDateTime !== '' ? new Date(searchDateTime[1]) : '',
            sortBy: 'createdAt:desc',
            limit: 9999999, // todo: fix
          },
        })
        .then((result) => {
          setAuditLogData(result.data.results);
          setIsLoading(false);

          searchForm.resetFields();
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.data.code === 404) {
            message.error('Failed to retrieve Audit Log list');
          }
        });
    };

    await fetchAuditLog();
  }, [searchUserId, searchPage, searchDateTime]);

  useEffect(async () => {
    const fetchDistinctUserRoles = async () => {
      setIsLoadingDistinctUserRoles(true);
      await axios
        .get('v1/auditlogs/getDistinctUserRoles', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            sortBy: 'createdAt:desc',
            limit: 9999999, // todo: fix
          },
        })
        .then((result) => {
          setDistinctUserRoles(result.data.results);
          setIsLoadingDistinctUserRoles(false);
        })
        .catch((err) => {
          setIsLoadingDistinctUserRoles(false);
          if (err.response.data.code === 404) {
            message.error('Failed to retrieve distinct user roles data');
          }
        });
    };

    await fetchDistinctUserRoles();
  }, []);

  useEffect(async () => {
    const fetchDistinctPages = async () => {
      setIsLoadingDistinctPages(true);
      await axios
        .get('v1/auditlogs/getDistinctPages', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            sortBy: 'createdAt:desc',
            limit: 9999999, // todo: fix
          },
        })
        .then((result) => {
          const { data } = result;
          const array = [];
          let counter = 0;
          data.map((val) => {
            counter += 1;
            array.push({ id: counter, pageName: val });
          });

          setDistinctPages(array);
          setIsLoadingDistinctPages(false);
        })
        .catch((err) => {
          setIsLoadingDistinctPages(false);
          if (err.response.data.code === 404) {
            message.error('Failed to retrieve distinct pages data');
          }
        });
    };

    await fetchDistinctPages();
  }, []);

  const columns = [
    {
      title: 'User',
      dataIndex: 'userId',
      key: 'username',
      render: (val) => {
        if (val) {
          return capitalizedFirstAlphabet(val.name);
        }
        return '-';
      },
    },
    {
      title: 'Role',
      dataIndex: 'userId',
      key: 'userRole',
      render: (val) => {
        if (val) {
          return capitalizedFirstAlphabet(val.role);
        }
        return '-';
      },
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      key: 'activity',
    },
    {
      title: 'Page',
      dataIndex: 'fromPage',
      key: 'fromPage',
      width: 200,
    },
    {
      title: 'DateTime',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: (a, b) => moment(a.invoiceDate).unix() - moment(b.invoiceDate).unix(),
      render: (data) => (data ? moment(data).format('DD MMM YY HH:mm:ss') : '-'),
    },
  ];

  const AuditLogTable = () => (
    <div className="table-content">
      {isLoading ? (
        <Skeleton />
      ) : (
        <Table
          scroll={{ x: 1100 }}
          columns={columns}
          dataSource={auditLogData}
          pagination={{
            position: ['bottomRight'],
            total: auditLogData.totalResults,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            defaultCurrent: 1,
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ['10', '50'],
          }}
        />
      )}
    </div>
  );

  const onSearch = async () => {
    searchForm
      .validateFields()
      .then(async (res) => {
        const { searchAuditLog } = res;
        const { userRole, pageName, dateTime } = searchAuditLog;

        let selectedUserId;
        distinctUserRoles.map((val) => {
          if (val.userRole === userRole) {
            selectedUserId = val._id;
          }
        });

        setSearchUserId(selectedUserId);
        setSearchUserRole(userRole);
        setSearchPage(pageName);
        setSearchDateTime(dateTime);
        setShowReset(true);

        searchForm.resetFields();
      })
      .catch(() => {});
  };
  const onResetSearch = () => {
    setShowReset(false);
    setSearchUserRole('');
    setSearchPage('');
    setSearchDateTime('');
  };
  const ApplyFilterButton = () => (
    <Button type="primary" key="submit" onClick={onSearch}>
      Apply
    </Button>
  );
  const ResetButton = () => (
    <Tooltip title="Reset Filters">
      <Button type="primary" disabled={!showReset} onClick={onResetSearch}>
        Reset
      </Button>
    </Tooltip>
  );

  const SearchButton = () => (
    <Form layout="vertical" form={searchForm}>
      <Row>
        <Col span={7}>
          <div className="header-section-search-bar-section">User</div>
          <Item name={['searchAuditLog', 'userRole']} initialValue={searchUserRole}>
            {isLoadingDistinctUserRoles ? (
              <Skeleton />
            ) : (
              <Select allowClear showSearch placeholder="Please select" style={{ width: 200 }}>
                {distinctUserRoles.map((val) => (
                  <Option key={val._id} value={val.userRole}>
                    {capitalizedFirstAlphabet(val.userRole)}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>
        <Col span={7}>
          <div className="header-section-search-bar-section">Page</div>
          <Item name={['searchAuditLog', 'pageName']} initialValue={searchPage}>
            {isLoadingDistinctPages ? (
              <Skeleton />
            ) : (
              <Select allowClear showSearch placeholder="Please select" style={{ width: 200 }}>
                {distinctPages.map((val) => (
                  <Option key={val.id} value={val.pageName}>
                    {capitalizedFirstAlphabet(val.pageName)}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>
        <Col span={10}>
          <div className="header-section-search-bar-section">Date Time</div>
          <Item name={['searchAuditLog', 'dateTime']} initialValue={searchDateTime}>
            <RangePicker
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
              }}
              style={{ width: '100%' }}
            />
          </Item>
        </Col>
      </Row>
    </Form>
  );

  const BreadCrumbDiv = () => (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/audit-log">Audit Log</a>
        </Breadcrumb.Item>
      </Breadcrumb>
    </>
  );

  const PageHeaderDiv = () => <PageHeader title="Audit Log" className="basic-pageheader" />;

  return (
    <>
      <div className="breadcrumb-layout">
        <BreadCrumbDiv />
        <PageHeaderDiv />
      </div>

      <Content className="layout-background">
        <div className="header-section">
          <Card className="header-section-card">
            <Row className="header-section-card-row">
              <Col>
                <SearchButton />
              </Col>
              {showReset === true ? (
                <Col className="vertical-align-center-button">
                  <Col>
                    <ApplyFilterButton />
                  </Col>
                  <Col className="right-positioned-button">
                    <ResetButton />
                  </Col>
                </Col>
              ) : (
                <Col className="vertical-align-center-button">
                  <ApplyFilterButton />
                </Col>
              )}
            </Row>
          </Card>
        </div>
        <AuditLogTable />
      </Content>
    </>
  );
}
