import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { PageHeader, Breadcrumb, Row, Col, Button, Spin, message, Modal, Form, Input, InputNumber, DatePicker, Select, Collapse } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import axios from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';

import DetailCallOutManagement from '../../components/callOutManagement/DetailCallOutManagement';
import { getToast, getToken, removeToast, setToast } from '../../utils/AuthService';
import './callOutManagement.css';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

export default function CallOutManagementDetail() {
  const location = useLocation();
  const { id, calloutFormURL, serviceAgreementURL } = location.state;
  const token = getToken();
  const toast = getToast();
  const [form] = Form.useForm();

  const [data, setData] = useState([]);
  const [consultantsData, setConsultantsData] = useState([]);
  const [newCallOutData, setNewCalloutData] = useState([]);
  const [consultantsInfo, setConsultantInfo] = useState([]);
  const [pslData, setPSLData] = useState([]);
  const [pslInfo, setPSLInfo] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingContractButton, setLoadingContractButton] = useState(false);
  const [loadingCallOutButton, setLoadingFormButton] = useState(false);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    const getData = () => {
      axios
        .get(`v1/callouts/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setData(res.data);
          setPositionData(res.data.position);
          setConsultantInfo(res.data.consultantId);
          setPSLInfo(res.data.pslId);
        })
        .catch((error) => {
          // TODO: check if data got error
          message.error(`Failed to retrive call out form details data. ${error}`);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getData();
  }, [newCallOutData]);

  useEffect(() => {
    const getPSLData = async () => {
      await axios
        .get('v1/psls', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setPSLData(res.data.results);
        });
    };

    const getConsultantData = async () => {
      await axios
        .get('v1/consultants', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setConsultantsData(res.data.results);
        });
    };
    getPSLData();
    getConsultantData();
  }, [newCallOutData]);

  const successMessage = () => {
    message.success('Successfully Updated');
  };

  if (toast) {
    successMessage();
    removeToast();
  }

  const toggleOpenModel = () => {
    setOpen(!open);
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinish = async (values) => {
    setConfirmLoading(true);

    const newPositionArr = [];

    values.position.map((val) => {
      if (val.monthlyRate) {
        newPositionArr.push({
          mandayRateSet: val.mandayRateSet,
          monthlyRate: val.monthlyRate,
          isMonthly: true,
        });
      } else {
        newPositionArr.push({
          mandayRateSet: val.mandayRateSet,
          dayRate: val.dayRate,
          onshoreRate: val.onshoreRate,
          offshoreRate: val.offshoreRate,
          standbyRate: val.standbyRate,
          quarantineRate: val.quarantineRate,
          travelRate: val.travelRate,
          isMonthly: false,
        });
      }
    });

    let reqConsultantId;
    consultantsData.map((val) => {
      if (val.name === values.consultant) {
        reqConsultantId = val._id;
      }
    });

    const jsonBody = {
      consultantId: reqConsultantId,
      apoaNumber: values.apaoNo,
      costCenter: values.costCenter,
      sapId: values.sapID,
      project: values.project,
      rotation: values.rotation,
      allowancePerDay: values.allowance,
      workingHour: values.workingHour,
      effectiveDate: values.duration[0],
      completionDate: values.duration[1],
      country: values.country,
      pslId: values.psl,
      jobReqNumber: values.jobReqNo,
      position: newPositionArr,
    };

    await axios.put(`v1/callouts/${id}/update`, jsonBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    }).then((res) => {
      setNewCalloutData(res.data);
      setOpen(false);
      setConfirmLoading(false);
      setToast(true);
    });
  };

  form.setFieldsValue({
    duration: [moment(data.effectiveDate), moment(data.completionDate)],
    consultant: consultantsInfo.name,
    psl: pslInfo.id,
  });

  const editForm = () => (
    <Form
      form={form}
      layout="vertical"
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item label="Name" name={['consultant']} rules={[{ required: true }]}>
        <Select placeholder="Please select" initialvalues={consultantsInfo.name} allowClear>
          {consultantsData.map((value) => (
            <>
              <Option key={value.id} value={value.name}>
                {value.name}
              </Option>
            </>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="APAO No"
        initialValue={data.apoaNumber}
        name={['apaoNo']}
        rules={[{ required: true }]}
      >
        <Input placeholder="example" />
      </Form.Item>

      <Form.Item
        label="Cost Center"
        initialValue={data.costCenter}
        name={['costCenter']}
        rules={[{ required: true }]}
      >
        <Input placeholder="example" />
      </Form.Item>

      <Form.Item
        label="SAP ID"
        initialValue={data.sapId}
        name={['sapID']}
        rules={[{ required: true }]}
      >
        <Input placeholder="example" />
      </Form.Item>

      <Form.Item
        label="Project"
        initialValue={data.project}
        name={['project']}
        rules={[{ required: true }]}
      >
        <Input placeholder="example" />
      </Form.Item>

      <Form.Item
        label="Rotation"
        initialValue={data.rotation}
        name={['rotation']}
        rules={[{ required: true }]}
      >
        <Input placeholder="example" />
      </Form.Item>

      <Form.Item
        label="Allowance"
        initialValue={data.allowancePerDay}
        name={['allowance']}
        rules={[
          {
            type: 'number',
            min: 0,
          },
          { required: true },
        ]}
      >
        <InputNumber placeholder="0" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label="Working hours"
        initialValue={data.workingHour}
        name={['workingHour']}
        rules={[
          {
            type: 'number',
            min: 0,
            max: 24,
          },
          { required: true },
        ]}
      >
        <InputNumber placeholder="example" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="Duration" name={['duration']} rules={[{ required: true }]}>
        <RangePicker style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label="Country"
        initialValue={data.country}
        name={['country']}
        rules={[{ required: true }]}
      >
        <Select placeholder="Please select" allowClear>
          <Option value="Indonesian">Indonesian</Option>
          <Option value="Australian">Australian</Option>
          <Option value="Malaysian">Malaysian</Option>
          <Option value="Thai">Thai</Option>
          <Option value="Indian">Indian</Option>
        </Select>
      </Form.Item>

      <Form.Item label="PSL" name={['psl']} rules={[{ required: true }]}>
        <Select placeholder="Please select" initialvalues={pslInfo.id} allowClear>
          {pslData.map((value) => (
            <Option key={value.id} value={value.id}>
              {value.psl}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Job Req No"
        initialValue={data.jobReqNumber}
        name={['jobReqNo']}
        rules={[{ required: true }]}
      >
        <Input placeholder="example" />
      </Form.Item>

      {positionData.map((position, i) => (
        <>
          <Col label="Manday Rate" name={['position', i]}>
            <Collapse defaultActiveKey={['1']}>
              <Panel header={`${position.mandayRateSet} Manday rate`} key={1}>
                <Form.Item
                  style={{ maxHeight: '0px' }}
                  initialValue={position.mandayRateSet}
                  name={['position', i, 'mandayRateSet']}
                />
                {position.dayRate !== null && position.dayRate !== undefined ? (
                  <Form.Item
                    label="Day rate(Day Rate)"
                    initialValue={position.dayRate}
                    name={['position', i, 'dayRate']}
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                      },
                    ]}
                  >
                    <InputNumber placeholder="0" style={{ width: '100%' }} defaultValue={10} />
                  </Form.Item>
                ) : null}
                {position.onshoreRate !== null && position.onshoreRate !== undefined ? (
                  <Form.Item
                    label="Manday rate(Onshore)"
                    initialValue={position.onshoreRate}
                    name={['position', i, 'onshoreRate']}
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                      },
                    ]}
                  >
                    <InputNumber placeholder="0" style={{ width: '100%' }} />
                  </Form.Item>
                ) : null}

                {position.offshoreRate !== null && position.offshoreRate !== undefined ? (
                  <Form.Item
                    label="Manday rate(Offshore)"
                    initialValue={position.offshoreRate}
                    name={['position', i, 'offshoreRate']}
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                      },
                    ]}
                  >
                    <InputNumber placeholder="0" style={{ width: '100%' }} />
                  </Form.Item>
                ) : null}

                {position.standbyRate !== null && position.standbyRate !== undefined ? (
                  <Form.Item
                    label="Manday rate(Standby)"
                    initialValue={position.standbyRate}
                    name={['position', i, 'standbyRate']}
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                      },
                    ]}
                  >
                    <InputNumber placeholder="0" style={{ width: '100%' }} />
                  </Form.Item>
                ) : null}

                {position.quarantineRate !== null && position.quarantineRate !== undefined ? (
                  <Form.Item
                    label="Manday rate(Quarantine)"
                    initialValue={position.quarantineRate}
                    name={['position', i, 'quarantineRate']}
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                      },
                    ]}
                  >
                    <InputNumber placeholder="0" style={{ width: '100%' }} />
                  </Form.Item>
                ) : null}

                {position.travelRate !== null && position.travelRate !== undefined ? (
                  <Form.Item
                    label="Manday rate(Travel)"
                    initialValue={position.travelRate}
                    name={['position', i, 'travelRate']}
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                      },
                    ]}
                  >
                    <InputNumber placeholder="0" style={{ width: '100%' }} />
                  </Form.Item>
                ) : null}

                {position.monthlyRate !== null && position.monthlyRate !== undefined ? (
                  <Form.Item
                    label="Monthly rate"
                    initialValue={position.monthlyRate}
                    name={['position', i, 'monthlyRate']}
                    rules={[
                      {
                        type: 'number',
                        min: 0,
                      },
                    ]}
                  >
                    <InputNumber placeholder="0" style={{ width: '100%' }} />
                  </Form.Item>
                ) : null}

              </Panel>
            </Collapse>
          </Col>
        </>
      ))}
    </Form>
  );

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
      }}
      spin
    />
  );

  const displayBreadCrumb = () => (
    <>
      <div className="breadcrumb-layout">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/call-out-management">Call Out Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Call Out Management Details</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title="Call Out Management Details" className="basic-pageheader" />
      </div>
    </>
  );

  const EditCalloutForm = () => (
    <>
      <Button type="primary" onClick={toggleOpenModel}>
        Edit
      </Button>
      <Modal
        title={<div style={{ fontWeight: '700' }}>Edit Work Information</div>}
        open={open}
        bodyStyle={{ height: '60vh', overflowY: 'auto' }}
        onOk={form.submit}
        confirmLoading={confirmLoading}
        onCancel={toggleOpenModel}
      >
        {editForm()}
      </Modal>
    </>
  );
  const editButton = () => (
    <>
      <EditCalloutForm />
    </>
  );

  const getDownloadContractFile = async (fileURL) => axios
    .get(`v1/download?fileUrl=${fileURL}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => {
      setLoadingContractButton(false);
      return response.data;
    })
    .catch((e) => {
      setLoadingContractButton(false);
      message.error(`Failed to download service agreement form. ${e}`);
    });

  const downloadContract = async (consultantId, fileURL) => {
    setLoadingContractButton(true);

    getDownloadContractFile(fileURL).then((blob) => {
      saveAs(blob, `${consultantId}_Service_Agreement`);
    });
  };

  const contractButton = () => (
    <>
      {serviceAgreementURL ? (
        <Button
          disabled={!(serviceAgreementURL !== '' || serviceAgreementURL !== null)}
          loading={loadingContractButton}
          type="primary"
          onClick={() => downloadContract(id, serviceAgreementURL)}
        >
          Download Service Agreement
        </Button>
      ) : null}
    </>
  );

  const getDownloadCalloutFile = async (fileURL) => axios
    .get(`v1/download?fileUrl=${fileURL}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => {
      setLoadingFormButton(false);
      return response.data;
    })
    .catch((e) => {
      setLoadingFormButton(false);
      message.error(`Failed to download call out form. ${e}`);
    });

  const downloadCallOut = async (consultantId, fileURL) => {
    setLoadingFormButton(true);

    getDownloadCalloutFile(fileURL).then((blob) => {
      saveAs(blob, `${consultantId}_Call_Out_Form`);
    });
  };

  const formButton = () => (
    <>
      {calloutFormURL ? (
        <Button
          loading={loadingCallOutButton}
          type="primary"
          onClick={() => downloadCallOut(id, calloutFormURL)}
          disabled={!(calloutFormURL !== '' || calloutFormURL !== null)}
        >
          Download Call Out form
        </Button>
      ) : null}
    </>
  );

  return (
    <>
      {displayBreadCrumb()}
      <div className="content-area">
        {loading === false ? (
          DetailCallOutManagement(data, positionData)
        ) : (
          <Spin style={{ margin: '10vh' }} size="large" indicator={antIcon} spinning={loading} />
        )}
        <Row style={{ justifyContent: 'end', margin: '20px 0px' }} gutter={10}>
          <Col>{editButton()}</Col>
          <Col>{contractButton()}</Col>
          <Col>{formButton()}</Col>
        </Row>
      </div>
    </>
  );
}
