import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Ant Design
import { Form, Input, Button, Row, Col, Card, Spin, Layout, Image, Divider, Checkbox } from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import 'antd/dist/antd.less';

import axios from 'axios';

import './login.css';
import logo from '../../assets/images/vta_logo.png';
import { setUserSession } from '../../utils/AuthService';

const { Content, Footer } = Layout;
const { Item } = Form;

export default function Login() {
  const history = useHistory();

  const currentYear = new Date().getFullYear();

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 30,
      }}
      spin
    />
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (values) => {
    setLoading(true);

    axios
      .post('/v1/auth/login', {
        username: values.username,
        password: values.password,
      })
      .then((response) => {
        setLoading(false);
        setUserSession(values.remember, response.data.tokens, response.data.user);

        history.push('/');
        // window.location.reload();
      })
      .catch((e) => {
        console.log(`error: ${e}`);
        setLoading(false);
        setError("Username and password entered doesn't match any account. Please try again.");
      });
  };

  return (
    <>
      <Layout className="login-layout" style={{ minHeight: '100vh' }}>
        <Content className="login-content">
          <>
            <Row type="flex" justify="space-around" className="wrapper4">
              <Image width={200} src={logo} preview={false} />
            </Row>
            {/* <Row type="flex" justify="space-around" align="middle" className="wrapper1">
              <h4>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi..</h4>
            </Row> */}

            <Row type="flex" justify="space-around" align="middle" className="wrapper2">
              <Col sm={24} md={10} lg={6}>
                {loading === false ? (
                  <Card className="login" bordered={false} hoverable={false}>
                    <h3>Login</h3>
                    <Divider />
                    <Form
                      name="normal_login"
                      className="login-form"
                      initialValues={{
                        remember: false,
                      }}
                      onFinish={(val) => handleSubmit(val)}>
                      <Item
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your username!',
                          },
                        ]}>
                        <Input
                          prefix={<UserOutlined className="site-form-item-icon" />}
                          placeholder=" Username"
                        />
                      </Item>

                      <Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]}>
                        <Input.Password
                          prefix={<LockOutlined className="site-form-item-icon" />}
                          type="password"
                          placeholder=" Password"
                        />
                      </Item>

                      <Item name="remember" valuePropName="checked" wrapperCol={{ span: 16 }}>
                        <Checkbox>Remember me</Checkbox>
                      </Item>

                      <Item>
                        {error && (
                          <>
                            <small style={{ color: 'red' }}>{error}</small>
                            <br />
                          </>
                        )}
                        <br />
                        <Button
                          type="default"
                          block
                          htmlType="submit"
                          className="login-form-button">
                          Login
                        </Button>
                      </Item>
                    </Form>
                  </Card>
                ) : (
                  <div className="loading-logo">
                    <Spin size="large" indicator={antIcon} spinning={loading} />
                  </div>
                )}
              </Col>
            </Row>

            {/* <Row type="flex" justify="end" align="bottom" className="wrapper3">
              <Col span={2}>
                <Button shape="circle" icon={<QuestionOutlined />} />
              </Col>
            </Row> */}
          </>
        </Content>
        <Footer
          className="login-footer"
          style={{ backgroundColor: '#f0f2f5', textAlign: 'center' }}>
          <h4> Copyright ©{currentYear} Vitallium Asia</h4>
        </Footer>
      </Layout>
      {/* )} */}
    </>
  );
}
