import React, { useState } from 'react';
import 'antd/dist/antd.less';
import { Header } from 'antd/lib/layout/layout';
import { Col, Image, Row, Steps } from 'antd';
import RegisterPageHeader from '../../assets/images/register_page_header.jpg';
import PersonalDetailsForm from './PersonalDetailsForm';
import FamilyDetailsForm from './FamilyDetailsForm';
import BankDetailsForm from './BankAndQualificationDetailsForm';
import RegisterSuccess from './RegisterSuccess';
import UploadDocumentsForm from './UploadDocumentsForm';

import logo from '../../assets/images/vta_logo.png';

const RegisterPage = () => {
  const [currentStep, setStep] = useState(0);
  const stepChangeHandler = (selectedStep) => {
    setStep(selectedStep);
  };

  let displayedForm;

  switch (currentStep) {
    case 0:
      displayedForm = <PersonalDetailsForm stepChangeHandler={stepChangeHandler} />;
      break;
    case 1:
      displayedForm = <FamilyDetailsForm stepChangeHandler={stepChangeHandler} />;
      break;
    case 2:
      displayedForm = <BankDetailsForm stepChangeHandler={stepChangeHandler} />;
      break;
    case 3:
      displayedForm = <UploadDocumentsForm stepChangeHandler={stepChangeHandler} />;
      break;
    default:
      displayedForm = <RegisterSuccess />;
  }

  return (
    <div className="register-page">
      <Header
        style={{
          background: `url(${RegisterPageHeader})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top 860px center',
          height: '133px',
          textAlign: 'start',
          color: 'white',
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={7}>
            <h1 style={{ color: 'white' }}>Registration Form</h1>
          </Col>
          <Col span={10}>
            <Image
              width={200}
              height={50}
              src={logo}
              style={{
                marginLeft: '58%',
              }}
            />
          </Col>
          <Col span={7} />
        </Row>
        <p>
          Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in
          hendrerit urna.
        </p>
      </Header>
      <div style={{ padding: '0 30px' }}>
        <div style={{ height: '32px' }} />
        <Steps
          current={currentStep}
          style={{
            background: 'white',
          }}
          responsive="true"
        >
          <Steps.Step title="Personal details" />
          <Steps.Step title="Family details" />
          <Steps.Step title="Bank details & qualifications" />
          <Steps.Step title="Upload documents" />
        </Steps>
        <div style={{ height: '23px' }} />
        {displayedForm}
        <div style={{ height: '30px' }} />
      </div>
    </div>
  );
};
export default RegisterPage;
