import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  DatePicker,
  message,
  Upload,
  Button,
  Collapse,
  Checkbox,
} from 'antd';
import { InboxOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';

import axios from 'axios';

import CountriesList from '../../assets/file/countriesList.json';
import { getToken } from '../../utils/AuthService';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const { Item } = Form;
const { Panel } = Collapse;

export default function FormCallOutManagement() {
  const [callOutForm] = Form.useForm();
  const token = getToken();
  const history = useHistory();

  const [loadingPSL, setLoadingPSL] = useState(false);
  const [PSLData, setPSLData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [monthlyChecked, setMonthlyChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [monthlyChecked2, setMonthlyChecked2] = useState(false);

  const [loadingDistinctMandayRateSet, setLoadingDistinctMandayRateSet] = useState(false);
  const [distinctMandayRateSet, setDistinctMandayRateSet] = useState([]);

  const [uploadedCallOutFormURL, setUploadedCallOutFormURL] = useState('');
  const [uploadedCallOutFilename, setUploadedCallOutFilename] = useState('');
  const [uploadedServiceAgreementURL, setUploadedServiceAgreementURL] = useState('');
  const [uploadedServiceAgreementFilename, setUploadedServiceAgreementFilename] = useState('');
  const [showSecondPosition, setShowSecondPosition] = useState(false);
  const [positionFieldsCount, setPositionFieldsCount] = useState(0);
  const [loadingConsultantsInfo, setLoadingConsultantsInfo] = useState(false);
  const [consultantsInfo, setConsultantsInfo] = useState([]);
  const [calloutFileList, setCallOutFileList] = useState([]);
  const [serviceAgreementFileList, setServiceAgreementFileList] = useState([]);

  const defaultURL = process.env.REACT_APP_NODE_ENV === 'PRODUCTION'
    ? process.env.REACT_APP_PRODUCTION_URL : process.env.REACT_APP_NODE_ENV === 'STAGING'
      ? process.env.REACT_APP_SANDBOX_URL : process.env.REACT_APP_LOCAL_URL;

  useEffect(() => {
    const fetchPSLData = async () => {
      setLoadingPSL(true);
      await axios
        .get('v1/psls?limit=99999', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((results) => {
          const originalResults = results.data.results;
          const newArr = [];
          originalResults.map((e) => {
            if (e.apoaNumber) {
              newArr.push(e);
            }
          });

          setPSLData(newArr);
          return results;
        })
        .catch((error) => {
          message.error(`Failed to retrive PSL data. ${error}`);
        });
    };
    fetchPSLData();
    setLoadingPSL(false);

    const fetchMandayRateSets = async () => {
      setLoadingDistinctMandayRateSet(true);
      await axios
        .get('v1/callouts/getDistinctMandayRateSets', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((results) => {
          setDistinctMandayRateSet(results.data.distinct_manday_rate_sets);
          return results;
        })
        .catch((error) => {
          message.error(`Failed to retrive distinct manday rate sets data. ${error}`);
        });
    };
    fetchMandayRateSets();
    setLoadingDistinctMandayRateSet(false);

    const fetchConsultants = async () => {
      setLoadingConsultantsInfo(true);
      await axios
        .get('v1/consultants/getValidConsultants', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit: 9999999,
          },
        })
        .then((results) => {
          const consultantsData = results.data;

          const consultantsInfoArr = [];
          consultantsData.map((val) => {
            consultantsInfoArr.push({ id: val._id, name: val.name.toUpperCase() });
          });

          setConsultantsInfo(consultantsInfoArr);
        })
        .catch((error) => {
          message.error(`Failed to retrive consultants data. ${error}`);
        });
    };
    fetchConsultants();
    setLoadingConsultantsInfo(false);
  }, []);

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  const onChangeMonthly = (e) => {
    setMonthlyChecked(e.target.checked);
  };

  const onChange2 = (e) => {
    setChecked2(e.target.checked);
  };

  const onChangeMonthly2 = (e) => {
    setMonthlyChecked2(e.target.checked);
  };

  const uploadCalloutProps = {
    name: 'file',
    action: `${defaultURL}v1/callouts/upload-callout-form`,
    maxCount: 1,
    beforeUpload(file) {
      // todo: check again
      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (sizeInMB > 5) {
        message.error(`${file.name} exceeds 5MB`);
      }

      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        message.error(`${file.name} is not a excel file or PDF file`);
      }

      return sizeInMB || isPDF || Upload.LISI_IGNORE;
    },
    // todo: need check onchg again
    onChange(info) {
      setUploadedCallOutFilename(info.file.name);
      if (info.file.status !== 'uploading') {
        setCallOutFileList(info.file.name);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove: (file) => {
      console.log('file: ', file);
    },
    customRequest: (options) => {
      const data = new FormData();
      data.append('calloutForm', options.file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
          Authorization: `Bearer ${token.access.token}`,
        },
      };
      axios
        .post(options.action, data, config)
        .then((res) => {
          console.log('res.data.Location: ', res.data.Location);
          setUploadedCallOutFormURL(res.data.Location);
          message.success(`${options.file.name} file uploaded successfully.`);
        })
        .catch((err) => {
          message.error(`${options.file.name} file upload failed. ${err}`);
        });
    },
  };

  const uploadServiceAgreementProps = {
    name: 'file',
    action: `${defaultURL}v1/callouts/upload-service-agreement`,
    maxCount: 1,
    beforeUpload(file) {
      // todo: check again
      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (sizeInMB > 5) {
        message.error(`${file.name} exceeds 5MB`);
      }

      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        message.error(`${file.name} is not a excel file or PDF file`);
      }

      return sizeInMB || isPDF || Upload.LISI_IGNORE;
    },
    // todo: need check onchg again
    onChange(info) {
      setUploadedServiceAgreementFilename(info.file.name);
      const { status } = info.file;
      if (status !== 'uploading') {
        setServiceAgreementFileList(info.file.name);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove: (file) => {
      console.log('file: ', file);
    },
    customRequest: (options) => {
      const data = new FormData();
      data.append('serviceAgreement', options.file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
          Authorization: `Bearer ${token.access.token}`,
        },
      };
      axios
        .post(options.action, data, config)
        .then((res) => {
          console.log('SA url: ', res.data.Location);
          setUploadedServiceAgreementURL(res.data.Location);
          message.success(`${options.file.name} file uploaded successfully.`);
        })
        .catch((err) => {
          message.error(`${options.file.name} file upload failed. ${err}`);
        });
    },
  };

  const onFinish = (event) => {
    const calloutData = event.callOut;

    const positionArr = [];
    if (monthlyChecked) {
      positionArr.push({
        mandayRateSet: calloutData.mandayRateRow1,
        monthlyRate: calloutData.monthlyRow1,
        isMonthly: monthlyChecked,
      });
    } else {
      positionArr.push({
        mandayRateSet: calloutData.mandayRateRow1,
        onshoreRate: calloutData.onshoreRow1,
        dayRate: calloutData.dayRow1,
        offshoreRate: calloutData.offshoreRow1,
        standbyRate: calloutData.standbyRow1,
        quarantineRate: calloutData.quarantineRow1,
        travelRate: calloutData.travelRow1,
        isMonthly: monthlyChecked,
      });
    }

    if (positionFieldsCount === 1) {
      if (monthlyChecked2) {
        positionArr.push({
          mandayRateSet: calloutData.mandayRateRow2,
          monthlyRate: calloutData.monthlyRow2,
          isMonthly: monthlyChecked2,
        });
      } else {
        positionArr.push({
          mandayRateSet: calloutData.mandayRateRow2,
          onshoreRate: calloutData.onshoreRow2,
          dayRate: calloutData.dayRow2,
          offshoreRate: calloutData.offshoreRow2,
          standbyRate: calloutData.standbyRow2,
          quarantineRate: calloutData.quarantineRow2,
          travelRate: calloutData.travelRow2,
          isMonthly: monthlyChecked2,
        });
      }
    }

    const found = PSLData.find((val) => val.id === calloutData.psl);
    const selectedApoaNum = found.apoaNumber;

    let isApplyTax;
    if (calloutData.applyTax === 'yes') {
      isApplyTax = true;
    } else if (calloutData.applyTax === 'no') {
      isApplyTax = false;
    }

    let reqConsultantId;
    consultantsInfo.map((val) => {
      if (val.name === calloutData.selectedConsultant) {
        reqConsultantId = val.id;
      }
    });

    const costCenterVal = calloutData.costCenter.trim();

    const jsonBody = {
      consultantId: reqConsultantId,
      contractNumber: calloutData.contractNo,
      apoaNumber: selectedApoaNum,
      costCenter: costCenterVal,
      sapId: calloutData.sapID,
      project: calloutData.project,
      rotation: calloutData.rotation,
      allowancePerDay: calloutData.allowance,
      workingHour: calloutData.workingHours,
      effectiveDate: calloutData.duration[0],
      completionDate: calloutData.duration[1],
      country: calloutData.country,
      pslId: calloutData.psl,
      jobReqNumber: calloutData.jobReqNo,
      position: positionArr,
      contractUrl: uploadedServiceAgreementURL,
      calloutFormUrl: uploadedCallOutFormURL,
      invoicingSchedule: 'Monthly',
      isTax: isApplyTax,
    };

    setUploadedCallOutFilename('');
    setUploadedServiceAgreementFilename('');

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    };
    axios
      .post('v1/callouts', jsonBody, config)
      .then(() => {
        message.success('Successfully create a new call out form!');
        // callOutForm.resetFields();
        history.push('/call-out-management');
      })
      .catch((err) => {
        if (err.response) {
          message.error('Failed to create. Please ensure all required fields are filled in.', 3.5);
        } else if (err.request) {
          message.error('Failed. Please ensure all required fields are filled in.');
        } else {
          message.error(err.message);
        }
      });
  };

  return (
    <Form
      form={callOutForm}
      layout="vertical"
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
      >
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 10,
        }}>
        <Col className="gutter-row" span={10}>
          <Item label="Name" name={['callOut', 'selectedConsultant']} rules={[{ required: true }]}>
            {loadingConsultantsInfo === false ? (
              <Select allowClear showSearch placeholder="Please select">
                {consultantsInfo.map((val) => (
                  <Option key={val.id} value={val.name}>
                    {val.name}
                  </Option>
                ))}
              </Select>
            ) : null}
          </Item>
        </Col>

        <Col className="gutter-row" span={7}>
          <Item
            label="Contract No"
            name={['callOut', 'contractNo']}
            rules={[{ required: true }]}
            initialValue="CW222506">
            <Input disabled placeholder="CW222506" />
          </Item>
        </Col>

        <Col className="gutter-row" span={7}>
          <Item label="Cost Center" name={['callOut', 'costCenter']} rules={[{ required: true }]}>
            <Input maxLength={15} />
          </Item>
        </Col>
      </Row>

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 10,
        }}>
        <Col className="gutter-row" span={6}>
          <Item label="SAP ID" name={['callOut', 'sapID']} rules={[{ required: true }]}>
            <Input />
          </Item>
        </Col>

        <Col className="gutter-row" span={6}>
          <Item label="Project" name={['callOut', 'project']} rules={[{ required: true }]}>
            <Input />
          </Item>
        </Col>

        <Col className="gutter-row" span={4}>
          <Item label="Rotation" name={['callOut', 'rotation']} rules={[{ required: true }]}>
            <Input placeholder="28/28" />
          </Item>
        </Col>

        <Col className="gutter-row" span={4}>
          <Item
            label="Allowance"
            name={['callOut', 'allowance']}
            rules={[{ required: true, type: 'number' }]}>
            <InputNumber style={{ width: '100%' }} />
          </Item>
        </Col>

        <Col className="gutter-row" span={3}>
          <Item
            label="Working Hours"
            name={['callOut', 'workingHours']}
            rules={[
              {
                type: 'number',
                min: 0,
                max: 24,
              },
              { required: true },
            ]}>
            <InputNumber style={{ width: '100%' }} placeholder="1" />
          </Item>
        </Col>
      </Row>

      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 10,
        }}>
        <Col className="gutter-row" span={6}>
          <Item label="Duration" name={['callOut', 'duration']} rules={[{ required: true }]}>
            <RangePicker style={{ width: '100%' }} />
          </Item>
        </Col>

        <Col className="gutter-row" span={4}>
          <Item label="Country" name={['callOut', 'country']} rules={[{ required: true }]}>
            <Select allowClear showSearch placeholder="Please select">
              {CountriesList.map((val) => (
                <Option key={val} value={val}>
                  {val}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>

        <Col className="gutter-row" span={7}>
          <Item label="PSL" name={['callOut', 'psl']} rules={[{ required: true }]}>
            <Select allowClear placeholder="Please select">
              {loadingPSL === false
                ? PSLData.map((val) => (
                  <Option key={val} value={val.id}>
                    {val.psl}
                  </Option>
                ))
                : null}
            </Select>
          </Item>
        </Col>

        <Col className="gutter-row" span={4}>
          <Item label="Job Req No" name={['callOut', 'jobReqNo']} rules={[{ required: true }]}>
            <Input />
          </Item>
        </Col>

        <Col className="gutter-row" span={3}>
          <Item label="Apply Tax" name={['callOut', 'applyTax']} rules={[{ required: true }]}>
            <Select allowClear placeholder="Please select">
              <Option value="yes">
                Yes
              </Option>
              <Option value="no">
                No
              </Option>
            </Select>
          </Item>
        </Col>
      </Row>

      <Collapse defaultActiveKey={['1']} style={{ marginBottom: '15px' }}>
        <Panel header="Position" key="1">
          <Row
            className="position-1"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 10,
            }}>
            <Col className="gutter-row" span={8}>
              <Item
                label={(
                  <Row>
                    <Col style={{ paddingRight: 15 }}>Manday rate set</Col>
                    <Col>
                      <Checkbox checked={checked} onChange={onChange} style={{ color: '#F7931D' }}>
                        custom
                      </Checkbox>
                    </Col>
                    <Col>
                      <Checkbox checked={monthlyChecked} onChange={onChangeMonthly} style={{ color: '#F7931D' }}>
                        monthly rate
                      </Checkbox>
                    </Col>
                  </Row>
                )}
                name={['callOut', 'mandayRateRow1']}
                rules={[{ required: true }]}>
                {checked === false ? (
                  <Select placeholder="Please select" allowClear showSearch>
                    {loadingDistinctMandayRateSet === false
                      ? distinctMandayRateSet.map((val) => (
                        <Option key={val} value={val}>
                          {val}
                        </Option>
                      ))
                      : null}
                  </Select>
                ) : (
                  <Input placeholder="Input manday rate set" maxLength={30} />
                )}
              </Item>
            </Col>
            <Col span={monthlyChecked ? 5 : 16}>
              {monthlyChecked ? (
                <Item label="Monthly" name={['callOut', 'monthlyRow1']} rules={[{ required: monthlyChecked }]} initialValue={0}>
                  <InputNumber defaultValue={0} />
                </Item>
              ) : (
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 10,
                  }}
                >
                  <Col span={4}>
                    <Item label="Day rate" name={['callOut', 'dayRow1']} rules={[{ required: !monthlyChecked }]} initialValue={0}>
                      <InputNumber defaultValue={0} />
                    </Item>
                  </Col>
                  <Col span={4}>
                    <Item label="Onshore" name={['callOut', 'onshoreRow1']} rules={[{ required: !monthlyChecked }]} initialValue={0}>
                      <InputNumber defaultValue={0} />
                    </Item>
                  </Col>

                  <Col className="gutter-row" span={4}>
                    <Item
                      label="Offshore"
                      name={['callOut', 'offshoreRow1']}
                      rules={[{ required: !monthlyChecked }]}
                      initialValue={0}>
                      <InputNumber defaultValue={0} />
                    </Item>
                  </Col>

                  <Col className="gutter-row" span={4}>
                    <Item label="Standby" name={['callOut', 'standbyRow1']} rules={[{ required: !monthlyChecked }]} initialValue={0}>
                      <InputNumber defaultValue={0} />
                    </Item>
                  </Col>

                  <Col className="gutter-row" span={4}>
                    <Item
                      label="Quarantine"
                      name={['callOut', 'quarantineRow1']}
                      rules={[{ required: !monthlyChecked }]}
                      initialValue={0}>
                      <InputNumber defaultValue={0} />
                    </Item>
                  </Col>

                  <Col className="gutter-row" span={4}>
                    <Item label="Travel" name={['callOut', 'travelRow1']} rules={[{ required: !monthlyChecked }]} initialValue={0}>
                      <InputNumber defaultValue={0} />
                    </Item>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          {showSecondPosition === true ? (
            <Row
              className="position-2"
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 10,
              }}>
              <Col className="gutter-row" span={8}>
                <Item
                  label={(
                    <Row>
                      <Col style={{ paddingRight: 15 }}>Manday rate set 2</Col>
                      <Col>
                        <Checkbox checked={checked2} onChange={onChange2} style={{ color: '#F7931D' }}>
                          custom
                        </Checkbox>
                      </Col>
                      <Col>
                        <Checkbox checked={monthlyChecked2} onChange={onChangeMonthly2} style={{ color: '#F7931D' }}>
                          monthly rate
                        </Checkbox>
                      </Col>
                    </Row>
                  )}
                  name={['callOut', 'mandayRateRow2']}
                >
                  {checked2 === false ? (
                    <Select placeholder="Please select" allowClear showSearch>
                      {loadingDistinctMandayRateSet === false
                        ? distinctMandayRateSet.map((val) => (
                          <Option key={val} value={val}>
                            {val}
                          </Option>
                        ))
                        : null}
                    </Select>
                  ) : (
                    <Input placeholder="Input manday rate set" maxLength={30} />
                  )}
                </Item>
              </Col>
              <Col span={monthlyChecked2 ? 5 : 15}>
                {monthlyChecked2 ? (
                  <Item label="Monthly" name={['callOut', 'monthlyRow2']} rules={[{ required: monthlyChecked2 }]} initialValue={0}>
                    <InputNumber defaultValue={0} />
                  </Item>
                ) : (
                  <Row
                    gutter={{
                      xs: 8,
                      sm: 16,
                      md: 24,
                      lg: 10,
                    }}
                  >
                    <Col span={4}>
                      <Item label="Day rate" name={['callOut', 'dayRow2']} rules={[{ required: !monthlyChecked }]} initialValue={0}>
                        <InputNumber defaultValue={0} />
                      </Item>
                    </Col>

                    <Col span={4}>
                      <Item label="Onshore" name={['callOut', 'onshoreRow2']} rules={[{ required: !monthlyChecked2 }]} initialValue={0}>
                        <InputNumber defaultValue={0} />
                      </Item>
                    </Col>

                    <Col className="gutter-row" span={4}>
                      <Item
                        label="Offshore"
                        name={['callOut', 'offshoreRow2']}
                        rules={[{ required: !monthlyChecked2 }]}
                        initialValue={0}>
                        <InputNumber defaultValue={0} />
                      </Item>
                    </Col>

                    <Col className="gutter-row" span={4}>
                      <Item label="Standby" name={['callOut', 'standbyRow2']} rules={[{ required: !monthlyChecked2 }]} initialValue={0}>
                        <InputNumber defaultValue={0} />
                      </Item>
                    </Col>

                    <Col className="gutter-row" span={4}>
                      <Item
                        label="Quarantine"
                        name={['callOut', 'quarantineRow2']}
                        rules={[{ required: !monthlyChecked2 }]} 
                        initialValue={0}>
                        <InputNumber defaultValue={0} />
                      </Item>
                    </Col>

                    <Col className="gutter-row" span={4}>
                      <Item label="Travel" name={['callOut', 'travelRow2']} rules={[{ required: !monthlyChecked2 }]} initialValue={0}>
                        <InputNumber defaultValue={0} />
                      </Item>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col
                span={1}
                offset={monthlyChecked2 ? 10 : 0}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Item label="" name={['callOut', 'deleted']}>
                  <MinusCircleOutlined
                    style={{ color: '#f7931d' }}
                    onClick={() => {
                      setPositionFieldsCount(positionFieldsCount - 1);
                      setShowSecondPosition(false);
                    }}
                  />
                </Item>
              </Col>
            </Row>
          ) : null}

          {positionFieldsCount >= 1 ? null : (
            <Item>
              <Button
                type="dashed"
                onClick={() => {
                  setPositionFieldsCount(positionFieldsCount + 1);
                  setShowSecondPosition(true);
                }}
                style={{
                  width: '100%',
                }}
                icon={<PlusOutlined />}>
                Add Row
              </Button>
            </Item>
          )}
        </Panel>
      </Collapse>

      <Item
        label="Upload Callout Form"
        name={['callOut', 'callOutFile']}
      // rules={[{ required: true }]}
      >
        <Dragger maxCount={1} accept=".pdf" {...uploadCalloutProps} fileList={calloutFileList}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload CallOut Form (PDF only)
          </p>
          <p className="ant-upload-text" style={{ fontWeight: 700 }}>
            Please check to make sure correct file is uploaded
          </p>
          <p className="ant-upload-hint">Maximum file size is 5MB</p>
        </Dragger>
        <div style={{ paddingTop: 5 }}>{uploadedCallOutFilename}</div>
      </Item>

      <Item
        label="Upload Service Agreement"
        name={['callOut', 'serviceAgreementFile']}
      // rules={[{ required: true }]}
      >
        <Dragger
          maxCount={1}
          accept=".pdf"
          {...uploadServiceAgreementProps}
          fileList={serviceAgreementFileList}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload Service Agreement (PDF only)
          </p>
          <p className="ant-upload-text" style={{ fontWeight: 700 }}>
            Please check to make sure correct file is uploaded
          </p>
          <p className="ant-upload-hint">Maximum file size is 5MB</p>
        </Dragger>
        <div style={{ paddingTop: 5 }}>{uploadedServiceAgreementFilename}</div>
      </Item>

      <Row style={{ justifyContent: 'end', marginTop: '50px' }}>
        <Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Item>
      </Row>
    </Form>
  );
}
