import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DashboardPage from '../pages/dashboard/Dashboard';
import ConsultantManagementPage from '../pages/consultantManagement/ConsultantManagement';
import ConsultantDetailsPage from '../pages/consultantManagement/ConsultantDetails';
import QuotationPage from '../pages/quotation/Quotation';
import FormQuotation from '../pages/quotation/NewQuotationForm';
import RunPaymentMainPage from '../pages/runPayment/RunPaymentMain';
import RunPaymentPage from '../pages/runPayment/RunPayment';
import CallOutManagementPage from '../pages/callOutManagement/MainCallOutManagement';
import CallOutManagementDetailPage from '../pages/callOutManagement/DetailCallOutManagement';
import CallOutManagementFormPage from '../pages/callOutManagement/AddCallOutManagementForm';
import InvoiceListPage from '../pages/invoiceList/InvoiceList';
import RunInvoicePage from '../pages/invoiceList/RunInvoice';
import ApproveInvoicePage from '../pages/invoiceList/ApproveInvoice';
import InvoiceHistoryPage from '../pages/invoiceHistory/InvoiceHistory';
import PslManagement from '../pages/pslManagement/PslManagement';
import AuditLog from '../pages/auditLog/AuditLog';
import NotFoundPage from './404';

export default function RouteList() {
  const handleAdminRoutes = () => (
    <>
      <Route exact path="/" component={DashboardPage} />
      <Route key="/index" exact path="/dashboard" component={DashboardPage} />
      <Route
        key="/consultant-management"
        exact
        path="/consultant-management"
        component={ConsultantManagementPage}
      />
      <Route
        key="/consultant-management/details"
        exact
        path="/consultant-management/details"
        component={ConsultantDetailsPage}
      />
      <Route key="/quotation-history" exact path="/quotation-history" component={QuotationPage} />
      <Route
        key="/quotation-history/add-new"
        exact
        path="/quotation-history/add-new"
        component={FormQuotation}
      />
      <Route key="/run-payment" exact path="/run-payment" component={RunPaymentMainPage} />
      <Route key="/psl-management" exact path="/psl-management" component={PslManagement} />
      <Route
        key="/run-payment/add-new"
        exact
        path="/run-payment/add-new"
        component={RunPaymentPage}
      />
      <Route
        key="/run-payment/edit/:paymentId"
        exact
        path="/run-payment/edit/:paymentId"
        component={RunPaymentPage}
      />
      <Route
        key="/run-payment/review/:paymentId"
        exact
        path="/run-payment/review/:paymentId"
        component={RunPaymentPage}
      />
      <Route
        key="/call-out-management"
        exact
        path="/call-out-management"
        component={CallOutManagementPage}
      />
      <Route
        key="/call-out-management/detail/:name"
        exact
        path="/call-out-management/detail/:name"
        component={CallOutManagementDetailPage}
      />
      <Route
        key="/call-out-management/add-form"
        exact
        path="/call-out-management/add-form"
        component={CallOutManagementFormPage}
      />
      <Route key="/invoice-list" exact path="/invoice-list" component={InvoiceListPage} />
      <Route key="/invoice-form/:id" exact path="/invoice-form/:id" component={RunInvoicePage} />
      <Route
        key="/invoice-form/view/:id"
        exact
        path="/invoice-form/view/:id"
        component={ApproveInvoicePage}
      />
      <Route
        key="/invoice-form/edit/:id"
        exact
        path="/invoice-form/edit/:id"
        component={RunInvoicePage}
      />
      <Route
        key="/invoice-form/approve/:id"
        exact
        path="/invoice-form/approve/:id"
        component={ApproveInvoicePage}
      />
      <Route key="/invoice-history" exact path="/invoice-history" component={InvoiceHistoryPage} />
      <Route key="/audit-log" exact path="/audit-log" component={AuditLog} />
    </>
  );

  // TODO modify when api implement
  const handleRolesRoutes = (roles) => {
    switch (roles) {
      case 'operator':
        return NotFoundPage();
      case 'admin':
        return handleAdminRoutes();
      default:
        return NotFoundPage();
    }
  };

  return <Switch>{handleRolesRoutes('admin')}</Switch>;
}
