import React, { useEffect, useState } from 'react';
import { Row, Divider, Table, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import axios from 'axios';

import '../../pages/dashboard/Dashboard.css';
import '../../styles/general.css';
import { getToken } from '../../utils/AuthService';

export default function PerformanceHistory() {
  const token = getToken();

  const [loadingPerformanceHistory, setLoadingPerformanceHistory] = useState(false);
  const [performanceHistoryData, setPerformanceHistoryData] = useState([]);

  function getMonthNumberFromName(monthName) {
    return new Date(`${monthName} 1, 2022`).getMonth() + 1;
  }

  useEffect(() => {
    const fetchPerformanceHistory = async () => {
      setLoadingPerformanceHistory(true);
      await axios
        .get('v1/dashboard/performance-history', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((results) => {
          const originalData = results.data.result;
          const newArr = [];

          originalData.map((e) => {
            e.data.map((val) => {
              const date = new Date(e._id, getMonthNumberFromName(val.month));
              newArr.push({
                date,
                cost: val.cost,
                revenue: val.revenue,
                profit: val.pbt,
                margin: val.margin,
              });
            });
          });

          // Function to compare two dates
          const compareDates = (a, b) => {
            if (a.date > b.date) return -1;
            if (a.date < b.date) return 1;
            return 0;
          };

          // Sorting the data by date
          newArr.sort(compareDates);

          setPerformanceHistoryData(newArr);
        })
        .catch((error) => {
          message.error(`Failed to retrive dashboard performance history data. ${error}`);
        });
    };

    fetchPerformanceHistory();
    setLoadingPerformanceHistory(false);
  }, []);

  const columns = [
    {
      title: 'Month',
      dataIndex: 'date',
      key: 'date',
      width: '5%',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (data) => (data ? moment(data).format('MMM YY') : '-'),
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      width: '5%',
      sorter: (a, b) => a.cost - b.cost,
      render: (data) => {
        if (data) {
          if (data >= 0) {
            if (data >= 1000) {
              return `${(data / 1000).toFixed(3)}K`;
            }
            if (data >= 1000000) {
              return `${(data / 1000000).toFixed(3)}M`;
            }
          } else if (data < 0) {
            if (data <= 1000) {
              return `${(data / 1000).toFixed(3)}K`;
            }
            if (data <= 1000000) {
              return `${(data / 1000000).toFixed(3)}M`;
            }
          }
          return data.toFixed(2);
        }
        return '0';
      },
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
      width: '5%',
      sorter: (a, b) => a.revenue - b.revenue,
      render: (data) => {
        if (data) {
          if (data >= 1000) {
            return `${(data / 1000).toFixed(3)}K`;
          }
          if (data >= 1000000) {
            return `${(data / 1000000).toFixed(3)}M`;
          }
          return data.toFixed(2);
        }
        return '0';
      },
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
      width: '5%',
      sorter: (a, b) => a.profit - b.profit,
      render: (data) => {
        if (data) {
          if (data >= 0) {
            if (data >= 1000) {
              return `${(data / 1000).toFixed(3)}K`;
            }
            if (data >= 1000000) {
              return `${(data / 1000000).toFixed(3)}M`;
            }
          } else if (data < 0) {
            if (data <= 1000) {
              return `${(data / 1000).toFixed(3)}K`;
            }
            if (data <= 1000000) {
              return `${(data / 1000000).toFixed(3)}M`;
            }
          }
          return data.toFixed(2);
        }
        return '0';
      },
    },
    {
      title: 'Margin',
      dataIndex: 'margin',
      key: 'margin',
      width: '5%',
      sorter: {
        compare: (a, b) => a.margin - b.margin,
      },
      render: (data) => {
        if (data) {
          return `${data.toFixed(2)}%`;
        }
        return '0.00%';
      },
    },
  ];

  return (
    <div className="performance-history-top-statistics">
      <Row className="dashboard-section-title">Performance History</Row>
      <Divider />
      {loadingPerformanceHistory === false ? (
        performanceHistoryData.length !== 0 ? (
          <Table
            scroll={{ x: 'auto' }}
            columns={columns}
            loading={loadingPerformanceHistory}
            dataSource={performanceHistoryData.map((item, index) => ({ ...item, key: index }))}
            pagination={{
              total: performanceHistoryData.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              defaultCurrent: 1,
              showSizeChanger: false,
              defaultPageSize: 5,
            }}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              padding: '200px 0 0 0',
            }}
          >
            <p>No data to display</p>
          </div>
        )
      ) : (
        <Spin
          indicator={(
            <LoadingOutlined
              style={{
                fontSize: 24,
              }}
              spin
            />
          )}
        />
      )}
    </div>
  );
}
