import React, { useEffect, useState } from 'react';
import {
  PageHeader,
  Breadcrumb,
  Button,
  Input,
  Row,
  Modal,
  Form,
  message,
  Skeleton,
  Table,
  Select,
  Card,
  Col,
  Layout,
  Tooltip,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import axios from 'axios';
import moment from 'moment';

import './pslManagement.css';
import CountriesList from '../../assets/file/countriesList.json';
import { getToken } from '../../utils/AuthService';

const { Item } = Form;
const { Option } = Select;
const { Content } = Layout;

export default function PslManagement() {
  const token = getToken();

  const [addForm] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [pslData, setPslData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [update, setUpdate] = useState({});
  const [openAddPsl, setOpenAddPsl] = useState(false);
  const [openEditPsl, setOpenEditPsl] = useState(false);
  const [loadingAddPsl, setLoadingAddPsl] = useState(false);
  const [editingId, setEditingId] = useState();

  const [showReset, setShowReset] = useState(false);
  const [searchCompanyVal, setSearchCompanyVal] = useState('');
  const [searchPSLVal, setSearchPSLVal] = useState('');
  const [searchAPOA, setSearchAPOA] = useState('');

  // Fetch PSL list from API
  useEffect(async () => {
    const fetchPsl = async () => {
      setIsLoading(true);
      await axios
        .get('v1/psls', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            company: searchCompanyVal,
            psl: searchPSLVal,
            apoaNumber: searchAPOA,
            sortBy: 'createdAt:desc',
            limit: 9999999,
          },
        })
        .then((result) => {
          setPslData(result.data.results);
          setIsLoading(false);

          searchForm.resetFields();
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.data.code === 404) {
            message.error('Failed to retrieve PSL List');
          }
        });
    };

    await fetchPsl();
  }, [update, searchCompanyVal, searchPSLVal, searchAPOA]);

  // Display Add PSL form modal
  const AddButton = () => (
    <Button type="primary" onClick={() => setOpenAddPsl(true)}>
      <Row>
        <Col className="vertical-align-center-button">
          <PlusCircleOutlined style={{ fontSize: '16px', paddingRight: 10 }} />
        </Col>
        Add New PSL
      </Row>
    </Button>
  );

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/psl-management">PSL Management</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row style={{ justifyContent: 'space-between' }}>
        <PageHeader title="PSL List" className="basic-pageheader" />
        <Col className="vertical-align-center-button">
          <AddButton />
        </Col>
      </Row>
    </div>
  );

  const onSearch = async () => {
    searchForm
      .validateFields()
      .then(async (res) => {
        const { searchPSL } = res;
        const { company, psl, apoaNumber } = searchPSL;

        console.log('company: ', company);
        setSearchCompanyVal(company);
        setSearchPSLVal(psl);
        setSearchAPOA(apoaNumber);
        setShowReset(true);
        searchForm.resetFields();
      })
      .catch(() => {});
  };
  const onResetSearch = () => {
    setShowReset(false);
    setSearchCompanyVal('');
    setSearchPSLVal('');
    setSearchAPOA('');
  };

  const SearchBar = () => (
    <Form layout="horizontal" form={searchForm}>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 20,
        }}>
        <Col span={8}>
          <Item name={['searchPSL', 'company']} initialValue={searchCompanyVal}>
            <div className="header-section-search-bar-section">
              Company
              <Input defaultValue={searchCompanyVal} />
            </div>
          </Item>
        </Col>
        <Col span={8}>
          <Item name={['searchPSL', 'psl']} initialValue={searchPSLVal}>
            <div className="header-section-search-bar-section">
              PSL
              <Input defaultValue={searchPSLVal} />
            </div>
          </Item>
        </Col>
        <Col span={8}>
          <Item name={['searchPSL', 'apoaNumber']} initialValue={searchAPOA}>
            <div className="header-section-search-bar-section">
              APOA Number
              <Input defaultValue={searchAPOA} />
            </div>
          </Item>
        </Col>
      </Row>
    </Form>
  );
  const ApplyFilterButton = () => (
    <Button type="primary" key="submit" onClick={onSearch}>
      Apply
    </Button>
  );
  const ResetButton = () => (
    <Tooltip title="Reset Filters">
      <Button type="danger" disabled={!showReset} onClick={onResetSearch}>
        Reset
      </Button>
    </Tooltip>
  );

  // Clear Add PSL form and close the modal
  const onCancel = () => {
    addForm.resetFields();
    setOpenAddPsl(false);
    setOpenEditPsl(false);
  };
  // Process submitted Add PSL form data
  const onSubmit = async () => {
    addForm
      .validateFields()
      .then(async (results) => {
        console.log(results);
        setLoadingAddPsl(true);
        const url = openAddPsl ? 'v1/psls' : `v1/psls/${editingId}/update`;
        const method = openAddPsl ? 'post' : 'put';
        const data = {
          company: results.company,
          psl: results.psl,
          apoaNumber: results.apoaNumber,
          address1: results.address1,
          state: results.state,
          country: results.country,
          postcode: results.postcode,
        };

        const config = {
          url,
          method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          data,
        };

        const result = await axios(config).catch(() => {
          setLoadingAddPsl(false);
          message.error(openAddPsl ? 'Failed to create PSL' : 'Failed to update PSL');
        });

        if (result.status === 201 || result.status === 200) {
          addForm.resetFields();
          setLoadingAddPsl(false);
          setOpenAddPsl(false);
          setOpenEditPsl(false);
          setEditingId(undefined);
          setUpdate(result);
          message.success(openAddPsl ? 'PSL created successfully!' : 'PSL updated successfully!');
        }

        if (result.status !== 201 && result.status !== 200) {
          setLoadingAddPsl(false);
          message.error(openAddPsl ? 'Failed to create PSL' : 'Failed to update PSL');
        }
      })
      .catch(() => {});
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      number: '${label} is not a valid number!',
      string: '${label} is not a valid string!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  // Add PSL form modal
  const AddPslModal = () => (
    <>
      <Modal
        title={openAddPsl ? 'Create New PSL' : 'Edit Psl'}
        open={openAddPsl || openEditPsl}
        centered
        width={800}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={onSubmit} loading={loadingAddPsl}>
            Save Changes
          </Button>,
        ]}>
        <Form
          layout="vertical"
          form={addForm}
          validateMessages={validateMessages}
        >
          <Item
            label="Company"
            name="company"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input placeholder="Enter Company" />
          </Item>
          <Item
            label="PSL Name"
            name="psl"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input placeholder="Enter PSL Name" />
          </Item>
          <Item
            label="APOA Number"
            name="apoaNumber"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input placeholder="Enter APOA Number" maxLength={10} />
          </Item>
          <Item label="Address" required>
            <Item
              name="address1"
              className="address-field"
              rules={[
                {
                  required: true,
                  message: 'Address Line 1 is required',
                },
              ]}>
              <Input placeholder="Address Line 1" />
            </Item>
            <Item
              name="state"
              className="address-field"
              rules={[
                {
                  required: true,
                  message: 'City/State is required',
                },
              ]}>
              <Input placeholder="City/State" />
            </Item>
            <Item
              name="postcode"
              className="address-field"
              rules={[
                {
                  required: true,
                  message: 'Postcode is required',
                },
                {
                  pattern: new RegExp(/\b\d{5}\b/g),
                  message: 'Postcode is invalid',
                },
              ]}>
              <Input placeholder="Postcode" maxLength={5} />
            </Item>
            <Item
              name="country"
              className="address-field"
              rules={[
                {
                  required: true,
                  message: 'Country is required',
                },
              ]}>
              <Select allowClear showSearch placeholder="Select a country">
                {CountriesList.map((val) => (
                  <Option key={val} value={val}>
                    {val}
                  </Option>
                ))}
              </Select>
            </Item>
          </Item>
        </Form>
      </Modal>
    </>
  );

  // PSL List table column
  const columns = [
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: '17%',
      sorter: (a, b) => a.company.localeCompare(b.company),
      render: (val) => {
        if (val) {
          return val.toUpperCase();
        }
        return '-';
      },
    },
    {
      title: 'PSL',
      dataIndex: 'psl',
      key: 'psl',
      align: 'justify',
      sorter: (a, b) => a.psl.localeCompare(b.psl),
      render: (val) => {
        if (val) {
          return val.toUpperCase();
        }
        return '-';
      },
    },
    {
      title: 'APOA Number',
      dataIndex: 'apoaNumber',
      key: 'apoaNumber',
      width: '13%',
      align: 'right',
      sorter: (a, b) => a.psl.localeCompare(b.psl),
    },
    {
      title: 'Address',
      dataIndex: 'address1',
      key: 'address1',
      align: 'justify',
      sorter: (a, b) => a.address1.localeCompare(b.address1),
      render: (_, record) => (
        <p>
          {record.address1}, {record.postcode}, {record.state}, {record.country}
        </p>
      ),
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '12%',
      align: 'right',
      render: (data) => (data ? moment(data).format('DD/MM/YYYY') : '-'),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: '12%',
      fixed: 'right',
      render: (_, psl) => (
        <Button
          type="link"
          onClick={() => {
            setOpenEditPsl(true);
            setEditingId(psl.id);
            addForm.setFieldsValue(psl);
          }}  
        >
          Edit
        </Button>
      ),
    },
  ];

  // PSL list table component
  const TablePslManagement = () => (
    <div className="table-content">
      {isLoading ? (
        <Skeleton />
      ) : (
        <Table
          scroll={{ x: 'auto' }}
          columns={columns}
          dataSource={pslData.map((item, index) => ({ ...item, key: index }))}
          pagination={{
            total: pslData.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            defaultCurrent: 1,
            showSizeChanger: true,
            defaultPageSize: 5,
            pageSizeOptions: ['5', '10'],
          }}
        />
      )}
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <div className="header-section">
          <Card className="header-section-card">
            <SearchBar />
            <Row className="apply-filter-button">
              {showReset === true ? (
                <Col className="vertical-align-center-button">
                  <Col>
                    <ApplyFilterButton />
                  </Col>
                  <Col className="right-positioned-button">
                    <ResetButton />
                  </Col>
                </Col>
              ) : (
                <Col className="vertical-align-center-button">
                  <ApplyFilterButton />
                </Col>
              )}
            </Row>
          </Card>
        </div>
        <TablePslManagement />
        <AddPslModal />
      </Content>
    </>
  );
}
