import React from 'react';
import { Link } from 'react-router-dom';
import { PageHeader, Breadcrumb } from 'antd';
import FormCallOutManagement from '../../components/callOutManagement/FormCallOutManagement';

import './callOutManagement.css';

export default function CallOutManagementForm() {
  const displayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/call-out-management">Call Out Management</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add a Call Out Form</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title="Add a Call Out Form" className="basic-pageheader" />
    </div>
  );

  return (
    <>
      {displayBreadCrumb()}
      <div className="content-area">
        <FormCallOutManagement />
      </div>
    </>
  );
}
