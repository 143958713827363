import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Layout,
  Breadcrumb,
  Table,
  PageHeader,
  Button,
  Tag,
  Input,
  Row,
  message,
  Typography,
  Card,
  Col,
  Select,
  Skeleton,
  Form,
  Tooltip,
} from 'antd';

import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';

import './ConsultantManagement.css';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { Option } = Select;
const { Item } = Form;

const defaultURL = process.env.REACT_APP_NODE_ENV === 'PRODUCTION'
  ? process.env.REACT_APP_PRODUCTION_FRONTEND_URL
  : process.env.REACT_APP_NODE_ENV === 'STAGING'
    ? process.env.REACT_APP_SANDBOX_FRONTEND_URL
    : process.env.REACT_APP_LOCAL_FRONTEND_URL;

export default function ConsultantManagement() {
  const token = getToken();
  const { search } = useLocation();
  const queryObj = queryString.parse(search);
  const { nric } = queryObj;

  const [searchForm] = Form.useForm();

  const [consultantManagementList, setConsultantManagementList] = useState([]);
  const [loadingConsultantData, setLoadingConsultantData] = useState([]);

  const [searchConsultantName, setSearchConsultantName] = useState('');
  const [searchContactNumber, setSearchContactNumber] = useState('');
  const [searchNRIC, setSearchNRIC] = useState(nric);
  const [searchEmail, setSearchEmail] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [showReset, setShowReset] = useState(false);

  useEffect(() => {
    const fetchConsultantData = async () => {
      setLoadingConsultantData(true);
      await axios
        .get('v1/consultants', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          // todo: fix
          params: {
            consultantName: searchConsultantName,
            nric: searchNRIC,
            contactNumber: searchContactNumber,
            email: searchEmail,
            status: searchStatus,
            limit: 9999999, // todo: fix
            // page: ,
            sortBy: 'createdAt:desc',
          },
        })
        .then((results) => {
          setConsultantManagementList(results.data.results);
          searchForm.resetFields();
        })
        .catch((error) => {
          message.error(`Failed to retrive consultant data. ${error}`);
        });
    };

    fetchConsultantData();
    setLoadingConsultantData(false);
  }, [searchConsultantName, searchContactNumber, searchNRIC, searchEmail, searchStatus]);

  function renderTagColorStatus(text) {
    switch (text) {
      case 'pending approval':
        return 'orange';
      case 'active':
        return 'green';
      case 'inactive':
        return 'red';
      default:
        return '';
    }
  }

  const columns = [
    {
      title: 'No.',
      dataIndex: 'consultantNumber',
      key: 'consultantNumber',
      width: '8%',
      sorter: (a, b) => a.consultantNumber.localeCompare(b.consultantNumber),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (val) => {
        if (val) {
          return val.toUpperCase();
        }
        return '-';
      },
    },
    {
      title: 'Contact Number',
      dataIndex: 'contactPhone',
      key: 'contactPhone',
      width: '13%',
      // render: (index, data) => {
      //   let contactData = '';
      //   if (data.nationality === 'Malaysia') {
      //     contactData = `+60 ${index}`;
      //     return contactData;
      //   }
      //   return index;
      // },
    },
    {
      title: 'NRIC/ Passport',
      dataIndex: 'nric',
      key: 'nric',
      width: '12%',
      align: 'right',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        switch (status) {
          case 'active':
            return <Tag color={renderTagColorStatus(status)}>Active</Tag>;
          case 'inactive':
            return <Tag color={renderTagColorStatus(status)}>Inactive</Tag>;
          case 'pending approval':
            return <Tag color={renderTagColorStatus(status)}>Pending Approval</Tag>;
          default:
            return '';
        }
      },
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '13%',
      align: 'right',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (value) => moment(value).format('DD/MM/YY'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: '12%',
      fixed: 'right',
      render: (_, consultant) => (
        <Button type="link">
          <NavLink
            style={{ color: '#f7931d' }}
            to={{
              pathname: '/consultant-management/details',
              search: `?id=${consultant._id}&name=${consultant.name}&tab=${'1'}`,
            }}
            activeclassname="active"
          >
            Details
          </NavLink>
        </Button>
      ),
    },
  ];

  const onSearch = async () => {
    searchForm
      .validateFields()
      .then(async (res) => {
        const { searchConsultant } = res;
        const { consultantName, contactNumber, nricPassport, email, status } = searchConsultant;

        setSearchConsultantName(consultantName);
        setSearchContactNumber(contactNumber);
        setSearchNRIC(nricPassport);
        setSearchEmail(email);
        setSearchStatus(status);
        setShowReset(true);

        searchForm.resetFields();
      })
      .catch(() => {});
  };
  const onResetSearch = () => {
    setShowReset(false);
    setSearchConsultantName('');
    setSearchContactNumber('');
    setSearchNRIC('');
    setSearchEmail('');
    setSearchStatus('');
  };
  const SearchBar = () => (
    <Form layout="horizontal" form={searchForm}>
      <Row>
        <Col span={12}>
          <Item name={['searchConsultant', 'consultantName']} initialvalue={searchConsultantName}>
            <div className="header-section-search-bar-section">
              Name
              <Input defaultValue={searchConsultantName} />
            </div>
          </Item>
        </Col>
        <Col span={12}>
          <Item name={['searchConsultant', 'contactNumber']} initialvalue={searchContactNumber}>
            <div className="header-section-search-bar-section">
              Contact Number
              <Input defaultValue={searchContactNumber} />
            </div>
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Item name={['searchConsultant', 'nricPassport']} initialvalue={searchNRIC}>
            <div className="header-section-search-bar-section">
              NRIC/ Passport
              <Input defaultValue={searchNRIC} />
            </div>
          </Item>
        </Col>
        <Col span={8}>
          <Item name={['searchConsultant', 'email']} initialvalue={searchEmail}>
            <div className="header-section-search-bar-section">
              Email
              <Input defaultValue={searchEmail} />
            </div>
          </Item>
        </Col>
        <Col span={8}>
          <div className="header-section-search-bar-section">
            Status
          </div>
          <Item name={['searchConsultant', 'status']} initialvalue={searchStatus}>
            <Select allowClear showSearch placeholder="Select Status" style={{ width: '100%' }} initialvalue={searchStatus}>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
              <Option value="pending approval">Pending Approval</Option>
            </Select>
          </Item>
        </Col>
      </Row>
    </Form>

  );
  const ApplyFilterButton = () => (
    <Button type="primary" key="submit" onClick={onSearch}>
      Apply
    </Button>
  );
  const ResetButton = () => (
    <Tooltip title="Reset Filters">
      <Button type="danger" disabled={!showReset} onClick={onResetSearch}>
        Reset
      </Button>
    </Tooltip>
  );

  const GetLinkButton = () => (
    <CopyToClipboard
      text={`${defaultURL}register`}
      onCopy={() => message.success('Copied registration link successfully!')}
    >
      <Button type="primary">
        Generate registration form link
      </Button>
    </CopyToClipboard>
  );

  const ConsultantManagementTable = () => (
    <div className="table-content">
      {loadingConsultantData ? (
        <Skeleton />
      ) : (
        <Table
          scroll={{ x: 'auto' }}
          columns={columns}
          dataSource={consultantManagementList}
          pagination={{
            total: consultantManagementList.length, // todo: fix
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            defaultCurrent: 1,
            showSizeChanger: true,
            defaultPageSize: 5,
            pageSizeOptions: ['5', '10'],
          }}
        />
      )}
    </div>
  );

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Typography.Link href="/consultant-management">Consultant Management</Typography.Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row style={{ justifyContent: 'space-between' }}>
        <PageHeader title="Consultant Management List" className="basic-pageheader" />
        <Col className="header-breadCrumb-button">
          <GetLinkButton />
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <div className="header-section">
          <Card className="header-section-card">
            <SearchBar />
            <Row className="apply-filter-button">
              {showReset === true ? (
                <Col className="vertical-align-center-button">
                  <Col>
                    <ApplyFilterButton />
                  </Col>
                  <Col className="right-positioned-button">
                    <ResetButton />
                  </Col>
                </Col>
              ) : (
                <Col className="vertical-align-center-button">
                  <ApplyFilterButton />
                </Col>
              )}
            </Row>
          </Card>
        </div>
        <ConsultantManagementTable />
      </Content>
    </>
  );
}
