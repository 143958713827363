import React from 'react';
import moment from 'moment';

import { Row, Descriptions, Tag } from 'antd';

export default function DetailCallOutManagement(data, positionData) {
  const positionName = positionData.map((value) => value.mandayRateSet).join(', ');
  
  return (
    <>
      <Descriptions
        labelStyle={{ maxWidth: '150px', fontWeight: '600', fontSize: '14px', lineHeight: '22px' }}
        contentStyle={{ fontWeight: '400' }}
        column={1}
        span={1}
        bordered
      >
        <Descriptions.Item label="Name">
          <Row>{data.consultantId.name}</Row>
        </Descriptions.Item>
        <Descriptions.Item label="Position">
          <Row>{positionName}</Row>
        </Descriptions.Item>
        <Descriptions.Item label="Contract No">
          <Row>{data.contractNumber}</Row>
        </Descriptions.Item>
        <Descriptions.Item label="APOA No">
          <Row>{data.apoaNumber}</Row>
        </Descriptions.Item>
        <Descriptions.Item label="Cost Center">
          <Row>{data.costCenter}</Row>
        </Descriptions.Item>
        <Descriptions.Item label="SAP ID">
          <Row>{data.sapId}</Row>
        </Descriptions.Item>
        <Descriptions.Item label="Project">
          <Row>{data.project}</Row>
        </Descriptions.Item>
        <Descriptions.Item label="Rotation">
          <Row>{data.rotation}</Row>
        </Descriptions.Item>
        <Descriptions.Item label="Allowance">
          <Row>RM {data.allowancePerDay}</Row>
        </Descriptions.Item>
        <Descriptions.Item label="Working hours">
          <Row>{data.workingHour} hours</Row>
        </Descriptions.Item>
        <Descriptions.Item label="Duration">
          <Row>
            {moment(data.effectiveDate).format('DD/MM/YYYY')} -{' '}
            {moment(data.completionDate).format('DD/MM/YYYY')}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label="Country">
          <Row>{data.country}</Row>
        </Descriptions.Item>
        <Descriptions.Item label="PSL">
          <Row>{data.pslId.psl}</Row>
        </Descriptions.Item>
        <Descriptions.Item label="Job Req No">
          <Row>{data.jobReqNumber}</Row>
        </Descriptions.Item>
        {positionData.map((position) => (
          <>
            <Descriptions.Item label={`Manday rate (${position.mandayRateSet})`}>
              <Row>
                {position.monthlyRate !== null && position.monthlyRate !== undefined ? (
                  <Tag color="gold">Monthly Rate: RM {position.monthlyRate}</Tag>
                ) : (
                  ''
                )}
                {position.dayRate !== null && position.dayRate !== undefined ? (
                  <Tag color="magenta">Day rate: RM {position.dayRate}</Tag>
                ) : (
                  ''
                )}
                {position.onshoreRate !== null && position.onshoreRate !== undefined ? (
                  <Tag color="magenta">Onshore: RM {position.onshoreRate}</Tag>
                ) : (
                  ''
                )}
                {position.offshoreRate !== null && position.offshoreRate !== undefined ? (
                  <Tag color="red">Offshore: RM {position.offshoreRate}</Tag>
                ) : (
                  ''
                )}
                {position.standbyRate !== null && position.standbyRate !== undefined ? (
                  <Tag color="orange">Standby: RM {position.standbyRate}</Tag>
                ) : (
                  ''
                )}
                {position.quarantineRate !== null && position.quarantineRate !== undefined ? (
                  <Tag color="blue">Quarantine: RM {position.quarantineRate}</Tag>
                ) : (
                  ''
                )}
                {position.travelRate !== null && position.travelRate !== undefined ? (
                  <Tag color="purple">Travel: RM {position.travelRate}</Tag>
                ) : (
                  ''
                )}
              </Row>
            </Descriptions.Item>
          </>
        ))}
      </Descriptions>
    </>
  );
}
