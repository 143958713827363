import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  Breadcrumb,
  PageHeader,
  Table,
  Layout,
  Input,
  Row,
  Col,
  Button,
  Modal,
  Form,
  DatePicker,
  message,
  Tooltip,
  Skeleton,
  Card,
  Tag,
  Select,
  Popconfirm,
} from 'antd';
import { PlusCircleOutlined, ExclamationCircleOutlined, CloseCircleFilled } from '@ant-design/icons';

import moment from 'moment';
import axios from 'axios';
import { saveAs } from 'file-saver';

import { getToken, getUser } from '../../utils/AuthService';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

export default function Quotation() {
  const token = getToken();
  const user = getUser();
  const userRole = user.role;

  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [rejectForm] = Form.useForm();

  const [loadingQuotationHistory, setLoadingQuotationHistory] = useState(false);
  const [quotationHistoryData, setQuotationHistoryData] = useState(false);
  const [pagination, setPagination] = useState(false);

  const [searchPSL, setSearchPSL] = useState('');
  const [searchRecipient, setSearchRecipient] = useState('');
  const [searchConsultant, setSearchConsultant] = useState('');
  const [searchPosition, setSearchPosition] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchCreationDate, setSearchCreationDate] = useState('');
  const [showReset, setShowReset] = useState(false);

  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [currQuotationNo, setCurrQuotationNo] = useState('');
  const [IsReview, setIsReview] = useState('');
  const [value, setValue] = useState('');
  const [viewModalTitle, setViewModalTitle] = useState('');
  const [fileURL, setFileURL] = useState('');
  const [approvedQuotation, setApprovedQuotation] = useState(false);
  const [rejectedQuotation, setRejectedQuotation] = useState(false);

  useEffect(() => {
    const fetchQuotationHistoryData = async () => {
      setLoadingQuotationHistory(true);
      await axios
        .get('v1/quotation/quotationHistoryTable', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            psl: searchPSL,
            recipient: searchRecipient,
            consultantName: searchConsultant,
            status: searchStatus,
            position: searchPosition,
            startCreationDate: searchCreationDate !== '' ? new Date(searchCreationDate[0]) : '',
            endCreationDate: searchCreationDate !== '' ? new Date(searchCreationDate[1]) : '',
            limit: 99999, // todo: fix
          },
        })
        .then((results) => {
          // todo: fix
          setPagination({
            ...pagination,
            total: results.data.totalResults,
          });

          setQuotationHistoryData(results.data);

          form.setFieldsValue({
            manday_rates_list: [
              {
                title: 'Manday Rate 1',
                name: '',
                amount: '',
              },
            ],
            remarks_list: [
              {
                title: 'Remarks Item 1',
                remarks: '',
              },
            ],
          });

          searchForm.resetFields();

          return results;
        })
        .catch((error) => {
          message.error(`Failed to retrive Quotation history table data. ${error}`);
        });
    };
    fetchQuotationHistoryData();
    setLoadingQuotationHistory(false);
  }, 
  [searchPSL, searchRecipient, 
    searchConsultant, searchCreationDate, 
    approvedQuotation, rejectedQuotation,
    searchStatus, searchPosition]);

  //* download helper functions
  const getDownloadQuotation = async (filetype, fileLink) => axios
    .get(`v1/download?fileUrl=${fileLink}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((response) => response.data)
    .catch((e) => {
      message.error(`Failed to download ${filetype}. ${e}`);
    });
  const downloadQuotation = async (filetype, fileLink, consultantName) => {
    getDownloadQuotation(filetype, fileLink).then((blob) => {
      saveAs(blob, `${consultantName}_${filetype}`);
    });
  };

  //* preview quotation
  const previewQuotation = (consultantName, quotationNo, quotationUrl, quotationId, isReview) => {
    setShowPDFViewer(true);
    setViewModalTitle(`${consultantName} (Contract No: ${quotationNo})`);
    setFileURL(quotationUrl);
    setCurrQuotationNo(quotationId);
    if (isReview === 'review') {
      setIsReview(true);
    } else {
      setIsReview(false);
    }
  };

  const onApprove = async (quotationId) => {
    const config = {
      url: `v1/quotation/${quotationId}/approve`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    await axios(config)
      .then(() => {
        message.success('Approved quotation!');
        setApprovedQuotation(true);
        setShowPDFViewer(false);
      })
      .catch((error) => {
        message.error(`Failed to approve Quotation. ${error}`);
      });
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const onRunReject = async (rejectVal) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    const jsonBody = {
      rejectRemarks: rejectVal,
    };

    await axios.put(`v1/quotation/${currQuotationNo}/reject`, jsonBody, config)
      .then(() => {
        message.success('Rejected quotation!');
        setRejectedQuotation(true);
        setShowPDFViewer(false);
        rejectForm.resetFields();
      })
      .catch((error) => {
        message.error(`Failed to reject quotation. ${error}`);
        rejectForm.resetFields();
      });
  };
  const onRejectValidate = async () => {
    rejectForm
      .validateFields()
      .then(async (res) => {
        const { rejectQ } = res;
        const { rejectQuotationVal } = rejectQ;

        await onRunReject(rejectQuotationVal);
      })
      .catch(() => {});
  };
  const onReject = () => {
    confirm({
      title: 'Are you sure to reject this quotation? Please give a reason.',
      content: <RemarksCommentBox onChange={handleChange} commentVal={value} />,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      centered: true,

      async onOk() {
        await onRejectValidate();
      },

      onCancel() {},
    });
  };
  
  const RemarksCommentBox = () => (
    <Form form={rejectForm} onFinish={onRejectValidate}>
      <Form.Item
        name={['rejectQ', 'rejectQuotationVal']}
        rules={[
          {
            required: true,
          },
        ]}>
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  );

  const columns = [
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      key: 'recipient',
      sorter: (a, b) => a.recipient.localeCompare(b.recipient),
      render: (val) => val.toUpperCase(),
    },
    {
      title: 'PSL',
      dataIndex: 'psl',
      key: 'psl',
    },
    {
      title: 'Quotation No',
      dataIndex: 'quotation_no',
      key: 'quotation_no',
      sorter: (a, b) => a.quotation_no.localeCompare(b.quotation_no),
    },
    {
      title: 'Terms',
      dataIndex: 'payment_terms',
      key: 'payment_terms',
      width: '10%',
      render: (data) => `${data} days`,
    },
    {
      title: 'Consultant Name',
      dataIndex: 'consultant_name',
      key: 'consultant',
      width: '15%',
      sorter: (a, b) => a.consultant_name.localeCompare(b.consultant_name),
      render: (val) => val.toUpperCase(),
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      width: '12%',
      sorter: (a, b) => a.position.localeCompare(b.position),
      render: (val) => val.toUpperCase(),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, data) => {
        switch (status) {
          case 'pending':
            return <Tag color="orange">Pending</Tag>;
          case 'approved':
            return <Tag color="green">Approved</Tag>;
          case 'rejected':
            return (
              <Popconfirm
                placement="top"
                title={
                  <div>This quotation is rejected on {moment(data.updatedAt).format('DD/MM/YYYY')} due to <b>{data.reject_remarks}</b>.</div>
                }
                icon={(
                  <CloseCircleFilled
                    style={{
                      color: '#FF4D4F',
                    }}
                  />
                )}
                okCancel="Close">
                <Tag color="red">Rejected</Tag>
              </Popconfirm>
            );
          default:
            return '';
        }
      },
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '12%',
      align: 'right',
      render: (data) => (data ? moment(data).format('DD/MM/YYYY') : '-'),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: '15%',
      render: (_, data) => {
        const quotationId = data._id;
        const fileUrl = data.quotation_file_url;
        const consultantName = data.consultant_name;
        const quotationNo = data.quotation_no;
        const quotationFileUrl = data.quotation_file_url;

        if (userRole === 'authorizer') {
          if (data.status === 'pending') {
            return (
              <Button
                type="link"
                onClick={
                  () => previewQuotation(
                    consultantName, 
                    quotationNo, 
                    quotationFileUrl, 
                    quotationId,
                    'review',
                  )
                }>
                Review
              </Button>
            );
          }
          return (
            <>
              <Button
                type="link"
                onClick={
                  () => previewQuotation(
                    consultantName, 
                    quotationNo, 
                    quotationFileUrl, 
                    quotationId,
                  )
                }>
                Preview
              </Button>
              <Button
                type="link"
                onClick={() => downloadQuotation('Quotation', fileUrl, consultantName)}>
                Download
              </Button>
            </>
          );
        }
        if (userRole === 'administrator') {
          if (data.status === 'rejected') {
            return (
              <Button
                type="link"
                onClick={
                  () => previewQuotation(
                    consultantName, 
                    quotationNo, 
                    quotationFileUrl, 
                    quotationId,
                  )
              }>
                Preview
              </Button>
            );
          } 
          if (data.status === 'approved') {
            return (
              <>
                <Button
                  type="link"
                  onClick={
                    () => previewQuotation(
                      consultantName, 
                      quotationNo, 
                      quotationFileUrl, 
                      quotationId,
                    )
                }>
                  Preview
                </Button>
                <Button
                  type="link"
                  onClick={() => downloadQuotation('Quotation', fileUrl, consultantName)}>
                  Download
                </Button>
              </>
            );
          }
        }
        return null;
      },
    },
  ];
  const TableQuotationHistory = () => (
    <div className="table-content">
      {loadingQuotationHistory ? (
        <Skeleton />
      ) : (
        <Table
          scroll={{ x: 'auto' }}
          columns={columns}
          dataSource={quotationHistoryData.results}
          pagination={{
            position: ['bottomRight'],
            total: quotationHistoryData.totalResults,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            defaultCurrent: 1,
            showSizeChanger: true,
            defaultPageSize: 5,
            pageSizeOptions: ['5', '10'],
          }}
        />
      )}
    </div>
  );

  const PDFViewer = () => (
    <Modal
      title={`${viewModalTitle}`}
      open={showPDFViewer}
      centered
      bodyStyle={{ overflowY: 'auto' }}
      width={1000}
      onCancel={() => setShowPDFViewer(false)}
      footer={IsReview ? (
        [
          <Button key="reject" type="danger" onClick={() => onReject(currQuotationNo)}>
            Reject
          </Button>,
          <Button
            key="approve"
            type="primary"
            onClick={() => onApprove(currQuotationNo)}
          >
            Approve
          </Button>,
        ]
      ) : (null)}
      >
      <iframe
        id="pdf-view"
        src={`${fileURL}#view=fitW`}
        title="pdfFileViewer"
        height={550}
        width="100%"
      />
    </Modal>
  );

  const onSearch = () => {
    searchForm
      .validateFields()
      .then((res) => {
        const { searchQuotation } = res;
        const { psl, recipient, consultantName, position, status, creationDate } = searchQuotation;

        setSearchPSL(psl);
        setSearchRecipient(recipient);
        setSearchConsultant(consultantName);
        setSearchPosition(position);
        setSearchStatus(status);
        setSearchCreationDate(creationDate);
        setShowReset(true);
        searchForm.resetFields();
      })
      .catch(() => {});
  };
  const onResetSearch = () => {
    setShowReset(false);
    setSearchPSL('');
    setSearchRecipient('');
    setSearchConsultant('');
    setSearchPosition('');
    setSearchStatus('');
    setSearchCreationDate('');
    searchForm.resetFields();
  };

  const SearchBar = () => (
    <Form layout="horizontal" form={searchForm}>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 20,
        }}>
        <Col span={8}>
          <Item name={['searchQuotation', 'recipient']} initialvalue={searchRecipient}>
            <div className="header-section-search-bar-section">
              Recipient
              <Input initialvalues={searchRecipient} />
            </div>
          </Item>
        </Col>
        <Col span={8}>
          <Item name={['searchQuotation', 'psl']} initialvalue={searchPSL}>
            <div className="header-section-search-bar-section">
              PSL
              <Input initialvalues={searchPSL} />
            </div>
          </Item>
        </Col>
        <Col span={8}>
          <Item name={['searchQuotation', 'consultantName']} initialvalue={searchConsultant}>
            <div className="header-section-search-bar-section">
              Consultant Name
              <Input initialvalues={searchConsultant} />
            </div>
          </Item>
        </Col>
      </Row>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 20,
        }}>
        <Col span={6}>
          <div className="header-section-search-bar-section">Position</div>
          <Item name={['searchQuotation', 'position']} initialvalue={searchPosition}>
            <Input initialvalues={searchPosition} />
          </Item>
        </Col>
        <Col span={6}>
          <div className="header-section-search-bar-section">Status</div>
          <Item name={['searchQuotation', 'status']} initialvalue={searchStatus}>
            <Select allowClear showSearch placeholder="Select Status" style={{ width: '100%' }} initialvalue={searchStatus}>
              <Option value="pending">Pending</Option>
              <Option value="approved">Approved</Option>
              <Option value="rejected">Rejected</Option>
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <div className="header-section-search-bar-section">Creation Date</div>
          <Item name={['searchQuotation', 'creationDate']} initialvalue={searchCreationDate}>
            <RangePicker style={{ width: '100%' }} />
          </Item>
        </Col>
      </Row>
    </Form>
  );
  const ApplyFilterButton = () => (
    <Button type="primary" key="submit" onClick={onSearch}>
      Apply
    </Button>
  );
  const ResetButton = () => (
    <Tooltip title="Reset Filters">
      <Button type="danger" disabled={!showReset} onClick={onResetSearch}>
        Reset
      </Button>
    </Tooltip>
  );

  const CreateNewQuotationButton = () => (
    <Link to="/quotation-history/add-new">
      <Button type="primary">
        <Row>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <PlusCircleOutlined style={{ fontSize: '16px', paddingRight: 10 }} />
          </Col>
          Add New Quotation
        </Row>
      </Button>
    </Link>
  );

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/quotation-history">Quotation History</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row style={{ justifyContent: 'space-between' }}>
        <PageHeader title="Quotation History" className="basic-pageheader" />
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <CreateNewQuotationButton />
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <div className="header-section">
          <Card className="header-section-card">
            <SearchBar />
            <Row className="apply-filter-button">
              {showReset === true ? (
                <Col className="vertical-align-center-button">
                  <Col>
                    <ApplyFilterButton />
                  </Col>
                  <Col className="right-positioned-button">
                    <ResetButton />
                  </Col>
                </Col>
              ) : (
                <Col className="vertical-align-center-button">
                  <ApplyFilterButton />
                </Col>
              )}
            </Row>
          </Card>
        </div>
        <TableQuotationHistory />
        <PDFViewer />
      </Content>
    </>
  );
}
