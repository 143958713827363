import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import './App.less';
import LoginPage from './pages/login/Login';
import LayoutPage from './layout/layout';
import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';
import RegisterPage from './pages/register/Register';

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <Switch>
            <PublicRoute path="/login" component={LoginPage} />
            <PublicRoute path="/register" component={RegisterPage} />
            <PrivateRoute path="*" component={LayoutPage} />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}
