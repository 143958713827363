import React from 'react';
import { Button, DatePicker, Form, Input, Modal, Select } from 'antd';
import countriesList from '../../assets/file/countriesList.json';

const QualificationModal = (props) => {
  const {
    isModalVisible,
    title,
    toggleModal,
    qualificationToEdit,
    addCallback,
    editCallback,
    deleteCallback,
  } = props;

  const [form] = Form.useForm();

  const { Item } = Form;
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  if (qualificationToEdit) {
    form.setFieldsValue({
      name: qualificationToEdit.name,
      country: qualificationToEdit.country,
      duration: qualificationToEdit.duration,
      fieldOfStudy: qualificationToEdit.fieldOfStudy,
      grade: qualificationToEdit.grade,
    });
  }

  const displayButton = () => {
    if (title === 'Add field of study') {
      return (
        <>
          <Button
            onClick={() => {
              form.resetFields();
              toggleModal();
            }}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              if (form) {
                form.validateFields().then((values) => {
                  form.resetFields();
                  toggleModal();
                  addCallback(values);
                });
              }
            }}
          >
            Add
          </Button>
        </>
      );
    }

    return (
      <>
        <Button
          onClick={() => {
            if (form) {
              form.resetFields();
              toggleModal();
              deleteCallback(qualificationToEdit.id);
            }
          }}
        >
          Delete
        </Button>
        <Button
          type="primary"
          onClick={() => {
            if (form) {
              form.validateFields().then((values) => {
                form.resetFields();
                toggleModal();
                editCallback(values);
              });
            }
          }}
        >
          Save Changes
        </Button>
      </>
    );
  };

  return (
    <Modal title={title} open={isModalVisible} onCancel={toggleModal} footer={[displayButton()]}>
      <Form
        form={form}
        name="family-details-or-emergency-contact"
        layout="vertical"
        validateMessages={{
          required: '${label} is required!',
        }}
      >
        <Item
          name="name"
          label="Name of Institution, College & University"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Item>
        <Item
          name="country"
          label="Country"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            style={{
              width: '100%',
            }}
            showSearch
            placeholder="Please select"
            optionFilterProp="children"
            filterOption={countriesList}
          >
            {countriesList.map((val) => (
              <Option key={val} value={val}>
                {val}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="duration"
          label="Duration"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <RangePicker
            disabledDate={(current) => current && current > Date.now()}
            style={{
              width: '100%',
            }}
          />
        </Item>
        <Item
          name="fieldOfStudy"
          label="Field of Study"
          rules={[
            {
              required: true,
            },
          ]}
          >
          <Input />
        </Item>
        {/* <Item
          name="grade"
          label="Grade"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Example: CGPA 3.78" />
        </Item> */}
      </Form>
    </Modal>
  );
};

export default QualificationModal;
