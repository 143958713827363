import axios from 'axios';

const pdfjs = require('pdfjs-dist/legacy/build/pdf');
const pdfjsworker = require('pdfjs-dist/legacy/build/pdf.worker.entry');
const XLSX = require('xlsx');

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsworker;

function isDate(dt) {
  const regexDate = /^(?:([1-9]|0[1-9]|[12][0-9]|3[01])[\/.]([1-9]|0[1-9]|1[012])[\/.](19|20)[0-9]{2})$/;
  const regexSecDate = /^(?:([1-9]|0[1-9]|[12][0-9]|3[01])[\-.]([1-9]|0[1-9]|1[012])[\-.][0-9]{2})$/;
  return regexDate.test(dt) || regexSecDate.test(dt);
}

export default async function extractClaimForm(paymentId, token) {
  const { bufferData, contentType } = await axios
    .get(`/v1/payments/${paymentId}/getClaimForm`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
    .then((res) => {
      const data = {
        bufferData: res.data.Body.data,
        contentType: res.data.ContentType,
      };
      return data;
    })
    .catch((err) => {
      console.log(err.response);
      return false;
    });

  if (!bufferData) {
    return false;
  }

  if (contentType === 'application/pdf') {
    const pdfDoc = pdfjs
      .getDocument({ data: bufferData })
      .promise.then((data) => data)
      .catch((err) => {
        console.log(err);
      });
  
    const pdfPage = await (await pdfDoc).getPage(1);
    const pageContent = await pdfPage.getTextContent();
  
    let startClaimItem = false;
    let startLine = false;
    let descriptionFound = false;
  
    const claimFormItems = [];
    const itemArr = [];
    let description;
    let total;
  
    pageContent.items.forEach((item) => {
      if (startClaimItem) {
        if (startLine) {
          if (!descriptionFound) {
            if (item.str !== ' ') {
              if (item.str !== 'YES' && item.str !== 'NO') {
                itemArr.push(item.str);
              } else {
                descriptionFound = true;
                description = itemArr.join(' ');
              }
            }
          } else {
            if (/^\d+\.\d{0,2}$/.test(item.str) || /^\d+$/.test(item.str)) {
              total = item.str;
            }
            if (isDate(item.str) || item.str === 'TOTAL') {
              claimFormItems.push({
                description,
                amount: parseFloat(total),
              });
              descriptionFound = false;
              itemArr.splice(0);
              description = null;
              total = null;
            }
          }
        }
  
        if (isDate(item.str)) {
          startLine = true;
        }
      }
  
      if (item.str.includes('RATE)')) {
        startClaimItem = true;
      }
    });
  
    return claimFormItems;
  }

  const workbook = XLSX.read(bufferData, { type: 'buffer' });
  
  const worksheet = workbook.Sheets.Sheet1;
  // console.log(worksheet);
  const kvPairs = Object.entries(worksheet);
  const filteredBColumns = kvPairs.filter((val) => val[0].includes('B'));
  const filteredKColumns = kvPairs.filter((val) => val[0].includes('K'));
  const filteredBRows = filteredBColumns.filter((val) => {
    const trimmedKey = val[0].slice(1);
    const parsedKey = parseInt(trimmedKey, 10);
    return parsedKey > 10 && parsedKey < 42;
  });
  const filteredKRows = filteredKColumns.filter((val) => {
    const trimmedKey = val[0].slice(1);
    const parsedKey = parseInt(trimmedKey, 10);
    return parsedKey > 10 && parsedKey < 42;
  });
  const claimItems = filteredBRows.map((val, i) => {
    const newVal = {
      description: val[1].w,
      amount: parseFloat(filteredKRows[i][1].w),
    };
    return newVal;
  });

  return claimItems;
}
