import React, { useState } from 'react';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, message, Row, Spin, Upload } from 'antd';
import axios from 'axios';
import { saveAs } from 'file-saver';

const hal20RulesUrl = 'https://vitallium-asia.s3.ap-southeast-1.amazonaws.com/20rules/Hal20rules/Hal+20+Rules+of+Contractor+Responsibilities.pdf';

const registerUser = async (props) => {
  const { form } = props;

  const agreementSigned = form.getFieldValue('agreement');
  const rulesSigned = form.getFieldValue('rules');

  const nricOrPassportFile = form.getFieldValue('nricOrPassport').fileList[0].originFileObj;
  const resumeOrCVFile = form.getFieldValue('resumeOrCV').fileList[0].originFileObj;
  let academicTranscriptOrCertificateFile;

  if (form.getFieldValue('academicTranscriptOrCertificate') !== undefined) {
    academicTranscriptOrCertificateFile = form.getFieldValue('academicTranscriptOrCertificate')
      .fileList[0].originFileObj;
  }

  localStorage.getItem('consultant');

  const personalDetails = JSON.parse(localStorage.getItem('personalDetails'));
  const familyMembers = JSON.parse(localStorage.getItem('familyMembers'));
  const emergencyContacts = JSON.parse(localStorage.getItem('emergencyContacts'));
  const bankDetails = JSON.parse(localStorage.getItem('bankDetails'));
  const qualifications = JSON.parse(localStorage.getItem('qualifications'));

  // Remove null values  from familyMembers, emergencyContacts and qualifications
  const familyMembersWithoutNull = familyMembers.filter((familyMember) => familyMember !== null);
  const emergencyContactsWithoutNull = emergencyContacts.filter(
    (emergencyContact) => emergencyContact !== null,
  );
  const qualificationsWithoutNull = qualifications.filter(
    (qualification) => qualification !== null,
  );

  // Remove id from familyMembers, emergencyContacts and qualifications
  const filteredFamilyMembers = familyMembersWithoutNull.map((familyMember) => {
    const { id, ...rest } = familyMember;
    return rest;
  });

  const filteredEmergencyContacts = emergencyContactsWithoutNull.map((emergencyContact) => {
    const { id, ...rest } = emergencyContact;
    if (rest.phoneNumber) {
      rest.phoneNumber = `${rest.country}${rest.phoneNumber}`;
    }
    delete rest.country;
    return rest;
  });

  const filteredQualifications = qualificationsWithoutNull.map((qualification) => {
    const { id, duration, ...rest } = qualification;
    return {
      ...rest,
      startDate: duration[0],
      endDate: duration[1],
    };
  });

  const consultant = {
    name: personalDetails.employeeName,
    nationality: personalDetails.nationality,
    nric: personalDetails.nricOrPassport,
    dateOfBirth: personalDetails.dateOfBirth,
    gender: personalDetails.gender,
    email: personalDetails.email,
    maritalStatus: personalDetails.maritalStatus,
    address: personalDetails.address,
    contactHome: personalDetails.contactNumberHome,
    contactPhone: `${personalDetails.country}${personalDetails.contactNumberPhone}`,
    bankName: bankDetails.bankName,
    accountName: bankDetails.accountName,
    accountNumber: bankDetails.accountNumber,
    epfNumber: bankDetails.epfNumber,
    socsoNumber: bankDetails.socsoNumber,
    pcbNumber: bankDetails.pcbNumber,
    agreementSigned,
    rulesSigned,
  };

  const consultantPayload = {
    consultant: {
      ...consultant,
    },
    family: filteredFamilyMembers,
    emergencyContact: filteredEmergencyContacts,
    qualification: filteredQualifications,
  };

  const response = await axios.post('/v1/consultants', consultantPayload);
  if (response.status !== 201) {
    throw new Error('Creating consultant failed');
  }
  const consultantId = response.data.id;
  // Upload NRIC
  const nricFormData = new FormData();
  nricFormData.append('docs', nricOrPassportFile);
  const nricResponse = await axios.post(
    `/v1/consultants/${consultantId}/uploadDocs?type=nric`,
    nricFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  if (nricResponse.status !== 200) {
    throw new Error('Uploading NRIC / Passport failed');
  }

  // Upload Resume
  const resumeFormData = new FormData();
  resumeFormData.append('docs', resumeOrCVFile);
  const resumeResponse = await axios.post(
    `/v1/consultants/${consultantId}/uploadDocs?type=resume`,
    resumeFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  if (resumeResponse.status !== 200) {
    throw new Error('Uploading Resume / CV failed');
  }

  // Upload Academic Transcript
  if (academicTranscriptOrCertificateFile) {
    const academicTranscriptFormData = new FormData();
    academicTranscriptFormData.append('docs', academicTranscriptOrCertificateFile);
    const academicTranscriptResponse = await axios.post(
      `/v1/consultants/${consultantId}/uploadDocs?type=transcript`,
      academicTranscriptFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (academicTranscriptResponse.status !== 200) {
      throw new Error('Academic Transcript or Certificate upload failed');
    }
  }

  // Clear localStorage
  localStorage.clear();
};

const UploadDocumentsForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [nricFileList, setNricFileList] = useState([]);
  const [resumeFileList, setResumeFileList] = useState([]);
  const [transcriptFileList, setTranscriptFileList] = useState([]);

  const [form] = Form.useForm();
  const { stepChangeHandler } = props;
  const { Item } = Form;
  const { Dragger } = Upload;

  // const tailFormItemLayout = {
  //   wrapperCol: {
  //     xs: {
  //       span: 24,
  //       offset: 0,
  //     },
  //     sm: {
  //       span: 16,
  //       offset: 8,
  //     },
  //   },
  // };

  const getDownloadCalloutFile = async (fileURL) => axios
    .get(`v1/download?fileUrl=${fileURL}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((e) => {
      message.error(`Failed to download call out form. ${e}`);
    });

  const downloadForm = async (fileURL) => {
    getDownloadCalloutFile(fileURL).then((blob) => {
      saveAs(blob, 'Hal 20 Rules');
    });
  };

  return (
    <Spin spinning={isLoading === true}>
      <Form
        form={form}
        name="upload-documents"
        layout="vertical"
        validateMessages={{
          required: '${label} is required',
        }}>
        <div
          style={{
            background: '#EEEEEE',
            padding: '30px',
          }}>
          <h2>Upload Documents</h2>
          <Item
            name="nricOrPassport"
            label="NRIC/Passport"
            rules={[
              {
                required: true,
              },
            ]}>
            <Dragger
              name="files"
              accept=".jpg, .jpeg, .png, .pdf"
              beforeUpload={(file) => {
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                  message.error('File must smaller than 5MB! Please remove and upload a new file.');
                }
                return isLt5M || Upload.LIST_IGNORE;
              }}
              style={{
                padding: '23px',
              }}
              maxCount={1}
              fileList={nricFileList}
              onChange={({ fileList }) => {
                const newFileList = fileList.map((val) => {
                  const newVal = val;
                  newVal.status = 'done';
                  // console.log(newVal);
                  return newVal;
                });
                setNricFileList(newFileList);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload NRIC/Passport
              </p>
              <p className="ant-upload-hint">
                Maximum file size is 5MB. Only .pdf and image files are allowed.
              </p>
            </Dragger>
          </Item>

          <Item
            name="resumeOrCV"
            label="Resume/CV"
            rules={[
              {
                required: true,
              },
            ]}>
            <Dragger
              name="files"
              accept=".pdf"
              style={{
                padding: '23px',
              }}
              fileList={resumeFileList}
              maxCount={1}
              beforeUpload={(file) => {
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                  message.error('File must smaller than 5MB! Please remove and upload a new file.');
                }
                return isLt5M || Upload.LIST_IGNORE;
              }}
              onChange={({ fileList }) => {
                const newFileList = fileList.map((val) => {
                  const newVal = val;
                  newVal.status = 'done';
                  // console.log(newVal);
                  return newVal;
                });
                setResumeFileList(newFileList);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload Resume/CV</p>
              <p className="ant-upload-hint">
                Maximum file size is 5MB. Only .pdf files are allowed.
              </p>
            </Dragger>
          </Item>

          <Item
            name="academicTranscriptOrCertificate"
            label="Academic Transcript/Certificate"
            rules={[
              {
                required: false,
              },
            ]}>
            <Dragger
              accept=".pdf"
              name="files"
              style={{
                padding: '23px',
              }}
              maxCount={1}
              beforeUpload={(file) => {
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                  message.error('File must smaller than 5MB! Please remove and upload a new file.');
                }
                return isLt5M || Upload.LIST_IGNORE;
              }}
              fileList={transcriptFileList}
              onChange={({ fileList }) => {
                const newFileList = fileList.map((val) => {
                  const newVal = val;
                  newVal.status = 'done';
                  // console.log(newVal);
                  return newVal;
                });
                setTranscriptFileList(newFileList);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload Academic Transcript/Certificate
              </p>
              <p className="ant-upload-hint">
                Maximum file size is 5MB. Only .pdf files are allowed. (Optional)
              </p>
            </Dragger>
          </Item>

          <Item
            style={{ marginBottom: 0 }}
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Please tick to agree with terms \& conditions'))),
              },
            ]}
            // {...tailFormItemLayout}
          >
            <Checkbox>
              I agreed with the terms & conditions.
              {/* <Button
                type="link"
                style={{ paddingLeft: '3px' }}
                onClick={() => downloadForm(hal20RulesUrl)}
              >
                Hal 20 Rules<DownloadOutlined />
              </Button> */}
            </Checkbox>
          </Item>

          <Item
            name="rules"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Please tick to acknowledge Hal 20 Rules'))),
              },
            ]}
            // {...tailFormItemLayout}
          >
            <Checkbox>
              I agreed the above information entered is accurate and I acknowledged the
              <Button
                type="link"
                style={{ paddingLeft: '3px' }}
                onClick={() => downloadForm(hal20RulesUrl)}
              >
                Hal 20 Rules<DownloadOutlined />
              </Button>
            </Checkbox>
          </Item>

          <Row justify="end">
            <Col>
              <Button type="primary" onClick={() => stepChangeHandler(2)}>
                Back
              </Button>
            </Col>
            <div style={{ width: '12px' }} />
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                onClick={async () => {
                  try {
                    setIsLoading(true);
                    await form.validateFields();
                    await registerUser({
                      form,
                    });
                    stepChangeHandler(4);
                  } catch (error) {
                    message.error(error.message);
                  } finally {
                    setIsLoading(false);
                  }
                }}>
                Next
              </Button>
            </Col>
          </Row>
          <div style={{ height: '36px' }} />
        </div>
      </Form>
    </Spin>
  );
};

export default UploadDocumentsForm;
