import Cookie from 'js-cookie';

// save the token and user into the cookies and expired in 30 days
export const setUserSession = (rememberme, token, user) => {
  if (rememberme === true) {
    Cookie.set('token', JSON.stringify(token), { expires: 30 });
    Cookie.set('user', JSON.stringify(user), { expires: 30 });
  } else if (rememberme === false) {
    Cookie.set('token', JSON.stringify(token), { expires: 1 });
    Cookie.set('user', JSON.stringify(user), { expires: 1 });
  }
};

// return the user data from the cookies
export const getUser = () => {
  const userStr = Cookie.get('user');
  if (userStr) {
    return JSON.parse(userStr);
  }
  return null;
};

// return the token from the cookies
export const getToken = () => Cookie.getJSON('token') || null;

// remove the token and user from the cookies
export const removeUserSession = () => {
  Cookie.remove('token');
  Cookie.remove('user');
};

export const setToast = (isToast) => {
  Cookie.set('toast', JSON.stringify(isToast), { expires: 1 });
};

export const getToast = () => Cookie.getJSON('toast') || null;

export const removeToast = () => {
  Cookie.remove('toast');
};
